import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ResponseBody, FileResponse, FileResponseBody } from '../../model/service-response.interface';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';

@Component({
  selector: 'app-img-upload',
  templateUrl: './img-upload.component.html',
  styleUrls: ['./img-upload.component.sass']
})
export class ImgUploadComponent implements OnInit {

  response: FileResponseBody;
  img: any;
  logo: any;
  fileFrom: FormGroup;
  fileResponseBody: FileResponseBody;
  responseBody: ResponseBody;
  returnFileResponse: any;
  responsefiles: any;

  @Input() referenceType: string;
  @Input() fieldType: string;

  @Input() modelName: string;
  @Input() getFileData: any;
  @Input() recordId: string;
  @Input() accessuri: string;
  @Output() fileUploadEvent = new EventEmitter<any>();


  constructor(
     private spinnerService: Ng4LoadingSpinnerService,
     private dataService: DataService,
     private configuration: Configuration,
     private fb: FormBuilder ) { }

  ngOnInit() {
    // this.createForm();
    // this.spinnerService.show();
    // this.spinnerService.hide();
  }

  // tslint:disable-next-line:use-life-cycle-interface
  ngOnChanges(changes: SimpleChanges) {
      this.createForm();
    const name: SimpleChange = changes.getFileData;
    if (name != null && name !== undefined) {
      this.getFileData = name.currentValue;
      this.setStabilityFormArray(this.getFileData, this.modelName);
    }

  }


  createForm() {
    this.fileFrom = this.fb.group({
      Doc_Data: this.fb.array([])
    });
  }

  setStabilityFormArray(fileToSet: any[], fieldName: string) {
 
    if (fileToSet != null) {
       if (fileToSet.length > 0) {
        const splitfilesfilter = fileToSet.filter(file => file.Doc_field_type === fieldName || file.Doc_field_type === null);
        if (splitfilesfilter.length > 0) {
          const splitfiles = splitfilesfilter.map(file => file.Doc_list);
          const splitfileFormArray = this.fb.array(splitfiles[0]);
          this.fileFrom.setControl('Doc_Data', splitfileFormArray);
          this.img = this.fileFrom.value.Doc_Data[0].Url;
        }
      }
    }

  }

  onUpload(files: any[]) {
     // tslint:disable-next-line:prefer-const
    let id = this.recordId;
    this.spinnerService.show();
    const formData: FormData = new FormData();
      formData.append('fileDoc', files[0]);
    formData.append('referenceType', this.referenceType);

    this.dataService
      .addFile<FileResponse>(this.configuration.fileUploadServer, formData)
      .subscribe((data: FileResponse) => this.fileResponseBody = data.message,
        error => () => {
          this.spinnerService.hide();
        },
        () => {

          this.spinnerService.hide();
          if (this.fileResponseBody.status === true) {
            this.accessuri = this.fileResponseBody.accessUri;
            this.response = this.fileResponseBody;
            this.logo = this.response.result[0];
            this.img = this.logo.Url;
            this.returnFileResponse = { $modelName: this.modelName, $result: this.fileResponseBody.result, $isDelete: false };
            const steps = <FormArray>this.fileFrom.controls['Doc_Data'];
            if (this.fileResponseBody.result.length > 0) {
               this.responsefiles = this.fileResponseBody.result;
            }
            for (let i = 0; i < this.responsefiles.length; i++) {
              steps.push(this.fb.group(this.responsefiles[i]));
            }
            this.fileUploadEvent.emit(this.returnFileResponse);
          } else {
          }
        });

  }



}

