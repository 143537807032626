import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ToastBaseModel {
    public Create = 'Created Successfully';
    public Delete = 'Deleted Successfully';
    public Update = 'Updated Successfully';
    public Copy = 'Copied Successfully';
    public Wrong = 'Something Went Wrong';
    public Moved = 'Moved Successfully';
    public request = 'Already Requested For Move Please Review Activity';
    public AlreadyMoved = 'Already Moved';
    public Rejected = 'Rejected';
    public Mail = 'Secret Code Sent To E-mail Sucessfully';
    public EmailExist = 'Email already exist. Please enter different email.';
    public Notification = 'Notification';


    public Customer = 'Customer ';
    public Settings = 'Settings ';
    public Batch = 'Batch ';
    public Label = 'Label ';
    public Master_Formulation = 'Master Formulation ';
    public Aseptic_Process = 'Compounding Methodology ';
    public Training = 'Training ';
    public Inventory = 'Inventory ';
    public Component = 'Component ';
    public ChildInventory = 'Child Inventory';
    public Production = 'Production ';
    public Equipment = 'Equipment ';
    public Facility = 'Facility ';
    public Location = 'Location ';
    public Request_Maintenance = 'Request ';
    public Profile = 'Profile ';
    public Role = 'Role ';
    public User = 'User ';
    public Master_Formulation_Ndc_Check = 'SKU and NDC are in use. Generating new SKU and NDC. Please re-save.';
    public Order = 'Order ';
    public SelectStatus = 'Please Select any Item from List';
    public trainngvalidation = 'Enter Required fields';

    public Comment = 'Comment Added Successfully';
    public maxUserCount = 'Company license exceeded, Please Contact Admin';
    public BatchMinimize = 'Running in Background';
    public FinalProduct = 'Final Product';
    public EnvironmentalMonitoring = 'Environmental Monitoring';
    public PersonnelMonitoring = 'Personnel Monitoring';
    public addQuantity = 'Please select product from the list';

    public QuantityInvalid = 'Quantity should not exceed Allocated Quantity';
    public ReleaseManagement = 'Release Management';
    public UserActive = 'Please Activate User';
    public TaskManagement = 'Task Management';

    public Stripe = 'Stripe';
    public EasyPost = 'EasyPost';
    public Issue = 'Jira Issue';
    public userCreatedMessage = '. User\'s random password and password reset link sent to their email id';
    public Application = 'Application Settings';
    public Supplier = 'Supplier';
    public CantDeleteMessage = 'This document has associated with another. So, cant able to delete'

}
