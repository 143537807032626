import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// import "rxjs/add/operator/debounceTime";
import {FormBuilder,FormGroup} from "@angular/forms";

@Component({
  selector: 'app-release-criteria',
  templateUrl: './release-criteria.component.html',
  styleUrls: ['./release-criteria.component.sass']
})
export class ReleaseCriteriaComponent implements OnInit {

  @Input() option: any[] = [];  
  @Input() selectedCriteria:any[] = []; 
  @Input() ViewDetail: Boolean=false;
  releaseCriteriaArray : any[] = [];
  form: FormGroup;
  @Output() emitChildRecordEvent = new EventEmitter<string[]>();  
  viewDetail: Boolean;
  constructor(private fb: FormBuilder) { 

  }

  ngOnInit() {
       
    this.viewDetail = this.ViewDetail;
    this.form = this.fb.group({
      Release_criteria : this.fb.array([])
    });    

    if(this.selectedCriteria == null)
    {
      this.option.forEach(a => {      
            this.releaseCriteriaArray.push({
              Name: a.Value,
              Checked: false
            });       
      });
    }
    else{
      this.option.forEach(a => {
            if (this.selectedCriteria.indexOf(a.Value) == -1) {
                this.releaseCriteriaArray.push({
                  Name: a.Value,
                  Checked: false
                });
            } else {
                this.releaseCriteriaArray.push({
                  Name: a.Value,
                  Checked: true
                });
            }
          }
    );

    }
    
    this.setReleaseCriteriaFormArray(this.releaseCriteriaArray);
  }

  setReleaseCriteriaFormArray(selectedCriteria : any[])
  {    
    const releaseFGs = selectedCriteria.map(release =>this.fb.group(release));
    const releaseFormArray = this.fb.array(releaseFGs);
    this.form.setControl("Release_criteria", releaseFormArray);
  }

  onChange()
  {
    var formValue = [];
    var result= [];
    formValue = this.form.get('Release_criteria').value;

    formValue.forEach(element => {
        if(element.Checked)
        {
            result.push(element.Name);
        }
    });

    this.emitChildRecordEvent.emit(result);
  }

}
