import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// import "rxjs/add/operator/debounceTime";
import {FormBuilder,FormGroup} from "@angular/forms";


@Component({
  selector: 'app-equipment-classification-list',
  templateUrl: './equipment-classification-list.component.html',
  styleUrls: ['./equipment-classification-list.component.sass']
})
export class EquipmentClassificationListComponent implements OnInit {
  selectedItems: any[];
  show: boolean = false;
  @Input() option: any;  
  @Input() requestName: any;  

  @Input() Class: any;;  
  @Input() ViewDetail: Boolean=false;
  @Input() selectedEquipment:any[] = [];
  equipmentArray : any[] = []; 
  form: FormGroup;
  @Output() emitChildRecordEvent = new EventEmitter<string[]>();  
  viewDetail: Boolean;
  constructor(private fb: FormBuilder) { }

  ngOnInit() {  
       
    this.viewDetail = this.ViewDetail;
    if(this.selectedEquipment == null)
    {
      this.option.forEach(element => {
        this.equipmentArray.push({ Name: element.Value, Checked: false });
      });
    }
    else{
          this.option.forEach(element => {
            if (this.selectedEquipment.indexOf(element.Value) == -1) {
                this.equipmentArray.push({ Name: element.Value, Checked: false });
            } else {
              this.equipmentArray.push({ Name: element.Value, Checked: true });
            }
          });    
    }
    this.onChangeEquipment(); 
  }

  onChangeEquipment()
  {       
      var result= [];    
      this.equipmentArray.forEach(element => {
          if(element.Checked)
          {
              result.push(element.Name);
          }
      });      
    this.emitChildRecordEvent.emit(result);  
    this.selectedItems = result; 
  }
  showDialog(event){
    if(event == 'hide'){
      this.show = false;
    }
    else if(event == 'show'){
      this.show = true;
    }
    
    
  }
}
