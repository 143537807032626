import { Component, Input, OnInit,Output, EventEmitter } from '@angular/core';
import { RootObject, AddressForm } from '../../model/address-autocomplete.interface';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-google-address-autocomplete',
  templateUrl: './google-address-autocomplete.component.html',
  styleUrls: ['./google-address-autocomplete.component.sass']
})
export class GoogleAddressAutocompleteComponent implements OnInit {
  

  public userSettings: any = {
    showCurrentLocation: false,
    resOnSearchButtonClickOnly: false,
    showSearchButton: false,
    inputPlaceholderText: 'Enter Address',
    recentStorageName: 'componentData3'
};

public AddressDetail: RootObject;
public AddressForm: FormGroup;
public FormattedAddress: FormGroup;
@Input() ComponentFor: string;
@Output() AddressFormEvent = new EventEmitter<FormGroup>();

constructor(private fb: FormBuilder) {
    setTimeout(() => {
        this.userSettings['inputPlaceholderText'] = 'Enter Address';
        this.userSettings = Object.assign({}, this.userSettings);
    }, 5000);
}

ngOnInit() {

    // Initialyzing  the AddressForm

    this.AddressForm = this.fb.group(new AddressForm());
}



// method for autocomplete address 

autoCompleteAddress(data: any): any {
    this.AddressForm = this.fb.group({
        street_number: [''],
        route: [''],
        locality: [''],
        administrative_area_level_1: [''],
        country: [''],
        postal_code: [''],
        address1: [''],
        address2: [''],
        ComponentFor: ['']
    })

    this.FormattedAddress = this.fb.group({
        Address1: [''],
        Address2: [''],
        City: [''],
        State: [''],
        Country: [''],
        Zipcode: [''],
        ComponentFor: ['']
    })


    this.AddressDetail = data;

    // Form to fetch the address data from the google json

    var componentForm = {
        street_number: 'short_name',
        route: 'long_name',
        locality: 'long_name',
        administrative_area_level_1: 'short_name',
        country: 'long_name',
        postal_code: 'short_name',
        sublocality_level_3: 'long_name',
        sublocality_level_2: 'long_name',
        sublocality_level_1: 'long_name'
    };

    // Loop for seperating the json response as formatted address and binding to the AddressForm

    for (var i = 0; i < this.AddressDetail.data.address_components.length; i++) {

        var addressType = this.AddressDetail.data.address_components[i].types[0];

        if (componentForm[addressType]) {

            var val = this.AddressDetail.data.address_components[i][componentForm[addressType]];
            this.AddressForm[addressType] = val;

            switch (addressType) {
                case "postal_code": {
                    this.FormattedAddress['Zipcode'] = val;
                    break;
                }
                case "locality": {
                    this.FormattedAddress['City'] = val;
                    break;
                }
                case "administrative_area_level_1": {
                    this.FormattedAddress['State'] = val;
                    break;
                }
                case "country": {
                    this.FormattedAddress['Country'] = val;
                    break;
                }

            }

            //merging data for address line 1
            if (addressType == 'route' || addressType == 'street_number') {
                var no = this.AddressForm['street_number'] != undefined && this.AddressForm['street_number'] != '' ? this.AddressForm['street_number'] : '';
                var street = this.AddressForm['route'] != undefined && this.AddressForm['route'] != '' ? this.AddressForm['route'] : '';
                this.AddressForm['address1'] = no + (no != '' && street != '' ? '  ' : '') + street;
                this.FormattedAddress['Address1'] = no + (no != '' && street != '' ? ' ' : '') + street;
            }
            //merging data for address line 2    
            else if (addressType == 'sublocality_level_3' || addressType == 'sublocality_level_2' || addressType == 'sublocality_level_1') {
                var s1 = this.AddressForm['sublocality_level_3'] != undefined && this.AddressForm['sublocality_level_3'] != '' ? this.AddressForm['sublocality_level_3'] : '';
                var s2 = this.AddressForm['sublocality_level_2'] != undefined && this.AddressForm['sublocality_level_2'] != '' ? this.AddressForm['sublocality_level_2'] : '';
                var s3 = this.AddressForm['sublocality_level_1'] != undefined && this.AddressForm['sublocality_level_1'] != '' ? this.AddressForm['sublocality_level_1'] : '';

                this.AddressForm['address2'] = s1 + (s1 != '' && s2 != '' ? ' , ' : '') + s2 + (s2 != '' && s3 != '' ? ' , ' : '') + s3;
                this.FormattedAddress['Address2'] = s1 + (s1 != '' && s2 != '' ? ' , ' : '') + s2 + (s2 != '' && s3 != '' ? ' , ' : '') + s3;

            }

        }
    }

    // binding value of address if address1 is empty
    var address1 = this.AddressForm['address1']
    if (address1 == '' || address1 == undefined) {
        var address = this.AddressDetail.data.adr_address.split('<span')[0];
        if (address.indexOf('<span') === -1) {

            this.AddressForm['address1'] = address;
            this.FormattedAddress['Address1'] = address;
        }

    }
    this.AddressForm['ComponentFor'] = this.ComponentFor;
    this.FormattedAddress['ComponentFor'] = this.ComponentFor;


    // passing the addres value to addressapi to parent component
    this.AddressFormEvent.emit(this.FormattedAddress);


}




}
