import { Component, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { DataService } from "../../shared/service/data-service";
import { Configuration } from "../../app.configuration";
import { Router } from "@angular/router";
import {
  ResponseResult,
  LoginResponse,
  DeviceDetails,
} from "../model/user-login.interface";
import { Ng4LoadingSpinnerService } from "ng4-loading-spinner";
import { AuthService } from "../service/auth.service";
import { Ng2DeviceService } from "ng2-device-detector";
import { StorageConfiguration } from "../../shared/common/storage-setting/storage-configuration";
// import { HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from 'rxjs';
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from "environments/environment";


declare let ClientIP: any;

@Component({
  selector: "app-user-login",
  templateUrl: "./user-login.component.html",
  styleUrls: ["./user-login.component.sass"],
  providers: [Ng2DeviceService],
})
export class UserLoginComponent implements OnInit {
  encryptToken: any;
  deviceDetails: DeviceDetails;
  ipAddress: any;
  deviceInfo: any;
  loginForm: FormGroup;
  subdomain: any;
  errorMessage: string;
  domain: any;
  userInfo: any;
  companyName: string;
  Responsevalue: any;
  UserInfo = [];
  pharamcyType: any;
  appVersion : string = `v ${environment.appVersion}`;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private authService: AuthService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService,
    private deviceService: Ng2DeviceService,
    private storageConfiguration: StorageConfiguration
  ) { }

  ngOnInit() {
    this.createForm();
    const publicIp = require("public-ip");

    publicIp.v4().then((ip) => {
      this.ipAddress = ip;
      this.deviceSpec();
    });
  }

  deviceSpec() {
    // DATA SERVICE GET
    this.spinnerService.show();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    const devicedetails: DeviceDetails = {
      Ip_address: this.ipAddress,
      Browser: this.deviceInfo.browser,
      Browser_version: this.deviceInfo.browser_version,
      Device: this.deviceInfo.device,
      OS: this.deviceInfo.os,
      Os_version: this.deviceInfo.os_version,
    };
    this.deviceDetails = devicedetails;
    this.dataService.add(this.configuration.logInView, devicedetails).subscribe(
      (data: ResponseResult) => {
        if (data.status) {
          this.router.navigate(["login"]);
          this.companyName = data.result.Company_name;
          this.pharamcyType = data.result.Pharmacy_type;
          this.storageConfiguration.sessionSetItem(
            this.storageConfiguration.pharmacy_type,
            data.result.Pharmacy_type
          );
          this.spinnerService.hide();
        }
      },
      (err) => {
        this.spinnerService.hide();
        this.router.navigate(["/login"]);
      }
    );
  }

  // log in

  userlogin(loginForm) {
    this.spinnerService.show();
    // Device details
    loginForm.value.Device_details = this.deviceDetails;
    this.authService
      .isUserAuthenticated(this.configuration.logIn, loginForm.value)
      .subscribe(
        (response: LoginResponse) => {
          this.Responsevalue = response;
          this.storageConfiguration.sessionSetItem(
            this.storageConfiguration.token,
            this.Responsevalue.message.token
          );
          this.storageConfiguration.sessionSetItem(
            this.storageConfiguration.currentlogin,
            this.Responsevalue.message.currentlogindetail.Login_history_id
          );
          this.UserInfo.push({
            id: this.Responsevalue.message.id,
            E_mail: this.Responsevalue.message.username,
            name:
              this.Responsevalue.message.firstname +
              " " +
              this.Responsevalue.message.lastname,
            role: this.Responsevalue.message.role.rolename,
            token: this.Responsevalue.message.token,
            permissionSetting: this.Responsevalue.message.permissionsettings,
            ipAddress: this.ipAddress,
            pharmacy_type: this.pharamcyType
          });
          this.storageConfiguration.sessionSetItem(
            this.storageConfiguration.password,
            this.loginForm.value.Password
          );
          this.storageConfiguration.sessionSetItem(
            this.storageConfiguration.user_Info,
            JSON.stringify(this.UserInfo)
          );
          this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
          this.spinnerService.hide();

          // if (this.userInfo[0].pharmacy_type == 'A PHARMACY' && this.userInfo[0].role != 'Management') {
            if (this.userInfo[0].pharmacy_type == 'A PHARMACY') {
            this.router.navigate(["/dashboard/dashboard-production"]);
          } else
            this.router.navigate(["/dashboard/dashboard-sale"]);
        },
        (err: HttpErrorResponse) => {
          this.spinnerService.hide();
          if (err.error) {
            this.errorMessage = err.error.message.result;
            if (err.error.message.result === "Invalid password!!") {
              this.errorMessage = "Invalid Password!!";
            } else if (err.error.message.result === "Invalid username!!") {
              this.errorMessage = "Invalid Username!!";
            } else if (
              err.error.message.message ===
              "Your Password Expired. Please Update Password."
            ) {
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.password,
                loginForm.value.Password
              );
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.updatemessage,
                "Your password has expired. Need to change it"
              );
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.id,
                err.error.message.result.id
              );
              this.errorMessage = err.error.message.message;
              this.router.navigate(["/auth/change-password"]);
            } else if (err.error.message.result === "Another User Signed In") {
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.id,
                err.error.message.options.id
              );
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.User_name,
                loginForm.value.User_name
              );
              this.storageConfiguration.sessionSetItem(
                this.storageConfiguration.password,
                loginForm.value.Password
              );
              this.router.navigate(["./force-login"]);
            } else if (
              err.error.message.result === "User does not have Remote Access."
            ) {
              this.router.navigate(["./login"]);
            } else {
              this.errorMessage;
            }
          }
          return throwError(err);
        }
      );
  }

  changePassword() {
    this.router.navigate(["/auth/change-password"]);
  }
  createForm() {
    this.loginForm = this.fb.group({
      User_name: [""],
      Password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(8)]),
      ],
      Device_details: this.fb.group({
        Ip_address: [""],
        Browser: [""],
        Browser_version: [""],
        Device: [""],
        OS: [""],
        Os_version: [""],
      }),
    });
  }
}
