import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SelectItem } from 'primeng/primeng';
import { DataService } from '../../service/data-service';
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-phone-component',
  templateUrl: './phone-component.component.html',
  styleUrls: ['./phone-component.component.sass']
})
export class PhoneComponentComponent implements OnInit {

  Country_SelectItem: SelectItem[];
  // CountryCode: allCountries;

  @Output() DialCodeEmitter = new EventEmitter();
  @Input() InputDialNumber: any;
  @Input() InputPlaceHolder: any;
  @Input() isFor: any;
  Dropvalue: any;
  emitValue = { DialNumber: '', Valid: false };
  DialCodeFrom: FormGroup;
  constructor(
    private dataService: DataService,
    private fb: FormBuilder,
  ) {

    this.DialCodeFrom = this.fb.group({
      DialNumber: '',
      Valid: false,
      IsFor: ''
    });
  }

  ngOnInit(): void {
    this.inputBinder();
    this.dataService.getAll('../../../../assets/showcase/data/Dial-Code.json').subscribe(
      (data: any) => {

        this.Country_SelectItem = this.bindDropDown(data.dial_code_data, 'default');
      });

  }

  inputBinder() {
     
    let val = this.InputDialNumber;
    if (val != undefined && val != null && val != null) {
      let C_code = val.split(' ')[0];
      let C_phone = val.split(' ')[1];
      if (C_code != undefined) {
        this.Dropvalue = C_code;
        
      }
      if (C_phone != undefined) {
        this.InputDialNumber = C_phone;
      }
    }

  }

  bindDropDown(array: any[], ddType: string): any[] {
     var dropDownItem = [];
    array.forEach(a => {
       switch (ddType) {
        case 'default':
          // var length = a.dial_code.length; 
          // var needlength = 6 - length;
          // var codeSpace = '<span class="country">' + a.name + '</span><span class="code"> ' + a.dial_code + '</span><span class="space">';
          var codeSpace = '<span class="country">' + a.name + '</span>&nbsp;<span class="code">' + a.dial_code + '</span>';
          // if (needlength > 0) {

          //   for (let index = 0; index < needlength; index++) {
          //     codeSpace += '&nbsp;';

          //   }
          // codeSpace += '</span><span class="country_code">' + a.code + '</span>';
          // }

          dropDownItem.push({ label: codeSpace, value: a.dial_code });
          break;
      }
    });
    return dropDownItem;
  }

  dialdropChange(event) {
     let val = event.value;
    // let inputNumber = this.InputDialNumber;
    // if (inputNumber) {
    //   let dialnumber = inputNumber.split(' ')[1];
    //   this.InputDialNumber = dialnumber == undefined ? inputNumber : dialnumber;

    // }
    // else {
    //   this.InputDialNumber = val + ' ';
    // }
    this.Dropvalue = event.value;
    this.validCheck();

    // let inputNumber = this.InputDialNumber;
    // if (inputNumber) {
    //   let dialnumber = inputNumber.split(' ')[1];
    //   this.InputDialNumber = val + ' ' + dialnumber;
    // }
    // else {
    //   this.InputDialNumber = val + ' ';
    // }
    // this.Dropvalue = event.value;
    // this.validCheck();

  }

  restrictNumeric(e) {
     this.onchange();
    let input;
    if (e.metaKey || e.ctrlKey) {
      return true;
    }
    if (e.which === 32) {
      return false;
    }
    if (e.which === 0) {
      return true;
    }
    if (e.which < 33) {
      return true;
    }
    input = String.fromCharCode(e.which);
    return !!/[\d\s]/.test(input);
    

  }

  onchange() {
    var InputDialNumber = this.InputDialNumber;
    
    

    let array = InputDialNumber.split(' ');    
    InputDialNumber = array[array.length - 1];

    let length = InputDialNumber.length;

    if (length > 8 && this.Dropvalue != undefined && this.Dropvalue != '' && this.Dropvalue != null) {
      this.InputDialNumber = this.Dropvalue + ' ' + this.InputDialNumber
      this.validCheck();
     }
  }
  

  validCheck() {
     let valid = true;
    var InputDialNumber = this.InputDialNumber;
    if (InputDialNumber == undefined || InputDialNumber == '' || InputDialNumber.length < 10) {
      valid = false;
    }
    else{
      let array = InputDialNumber.split(' ')    
      InputDialNumber = array[array.length - 1];
    }
    if (this.Dropvalue == undefined || this.Dropvalue == '') {
      valid = false;
    }

    // this.emitValue.DialNumber = this.Dropvalue + ' ' + this.InputDialNumber;
    // this.emitValue.Valid = valid;
    this.InputDialNumber = InputDialNumber != null ? InputDialNumber : '';
    this.DialCodeFrom.patchValue({
      DialNumber: this.Dropvalue + ' ' + this.InputDialNumber,
      Valid: valid,
      IsFor: this.isFor
    })

    this.DialCodeEmitter.emit(this.DialCodeFrom.value);
    // if (valid) {
    //   this.DialCodeEmitter.emit(this.DialCodeFrom.value);
    // }

  }



}

/*emitter code*/
// DialCodeReturn(event) {
//   if(event.IsFor=='Mobile_number'){
//     this.userCreateFrom.patchValue({
//       Mobile_number : event.DialNumber
//     })
//   } 

// }