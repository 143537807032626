import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PickListModule } from 'primeng/picklist';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule, CalendarModule } from 'primeng/primeng';
import { DialogModule } from 'primeng/dialog';
import { CheckboxModule } from 'primeng/checkbox';
import { TableModule } from 'primeng/table';
import { RadioButtonModule } from 'primeng/radiobutton';
import { DataTableModule } from 'primeng/primeng';
import { CurrencyMaskModule } from "ng2-currency-mask";
import { KeyFilterModule } from 'primeng/keyfilter';
import { SplitButtonModule } from 'primeng/splitbutton';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { DataListModule } from 'primeng/datalist';
import { ChartModule } from 'primeng/chart';
import { TooltipModule } from 'primeng/tooltip';
import { StepsModule } from 'primeng/primeng';
import {  ScheduleModule} from 'primeng/primeng'; 
import { AutoCompleteModule} from 'primeng/primeng'; 
import { AccordionModule} from 'primeng/primeng'; 
import { OrderListModule } from 'primeng/primeng';
import { FileUploadModule } from 'primeng/primeng';
 
 @NgModule({
  declarations: [],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    DialogModule,
    FileUploadModule,
    DataTableModule,
    DropdownModule,
    CalendarModule,
    TableModule,
    ChartModule,
    CheckboxModule,
    ScheduleModule, AutoCompleteModule, AccordionModule, OrderListModule, StepsModule,
    CurrencyMaskModule,
    DataListModule,
    FormsModule,
    ReactiveFormsModule,
    KeyFilterModule,
    TooltipModule,
    RadioButtonModule,
    PickListModule,
    TabViewModule,
    MultiSelectModule,
    SplitButtonModule
  ],
  exports: [
    CommonModule,
    ConfirmDialogModule,
    CurrencyMaskModule,
    DialogModule,
    DataListModule,
    DataTableModule,
    TabViewModule,
    FileUploadModule,
    DropdownModule,
    CalendarModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    CheckboxModule,
    KeyFilterModule,
    ChartModule,
    RadioButtonModule,
    PickListModule,
    TooltipModule,
    ScheduleModule, AutoCompleteModule, AccordionModule, OrderListModule, StepsModule,
    TabViewModule,
    MultiSelectModule,
    SplitButtonModule
  ]
})
export class PrimengModule { }
