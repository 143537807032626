import { Component, Injectable } from '@angular/core';
import { TaskNotification } from "./task-notification";
import { StorageConfiguration } from '../storage-setting/storage-configuration';
import { ToastrService } from 'ngx-toastr';

@Component({
    providers: [TaskNotification],
    template: ''
})

@Injectable({ providedIn: 'root' })

export class NotificationHandler {
    is_labelnotify: boolean = false;
    notificationArray: any = [];
    constructor(private taskHelper: TaskNotification,
        private storageConfiguration: StorageConfiguration,
        private toastr: ToastrService) {
    }
    onBroadCastMessage(notificationInfo: any, payload: any) {

        const currentPageURL = window.location.href;

        const currentUserInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));

        switch (notificationInfo.Module_type) {
            case 'Task':
                this.taskHelper.handleTaskPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Facility':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Equipment':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Location':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Master Formulation':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Final Product':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Deviation':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
            case 'Modification':
                this.taskHelper.handleFacilityPushNotification(currentPageURL, currentUserInfo, notificationInfo, payload);
                break;
        }

    }


    LabelGenerationNotification(LabelNotification: any) {
        let labelInfo: any[];
        labelInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.labelStorage));
        //let clientIdArray: any[];        
        //clientIdArray = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.chemicalClient));
        //const clientId = labelInfo[0].Client_Id;

        let obj = {};
        let alreadyExist = [];
        if (this.notificationArray.length == 0) {
            obj = {
                id: labelInfo[0].Client_Id,
                message: labelInfo[0].Message,
                type: labelInfo[0].type,
                Pdf_url: '',
                Step: '0',
                request_Id: ''

            };
            this.notificationArray.push(obj);
        }
        else {
            alreadyExist = this.notificationArray.filter(x => x.id === parseInt(LabelNotification.client_Id));
            if (alreadyExist.length == 0) {
                obj = {
                    id: labelInfo[0].Client_Id,
                    message: labelInfo[0].Message,
                    type: labelInfo[0].type,
                    Pdf_url: '',
                    Step: '0',
                    request_Id: ''
                };
                this.notificationArray.push(obj);
            }
        }
        this.notificationArray.forEach(element => {
            // tslint:disable-next-line:radix
            if (element.id === parseInt(LabelNotification.client_Id)) {
                element.message = LabelNotification.message;
                element.type = LabelNotification.type;
                element.Pdf_url = LabelNotification.pdf_url;
                element.Step = LabelNotification.step;
                element.request_Id = LabelNotification.request_Id;
            }
        });
        console.log(this.notificationArray, "notificationArray")
        return this.notificationArray;
        // if (localStorage.Client_Id == LabelNotification.client_Id) {
        //     this.is_labelnotify = true;
        //     // this.toastr.success(LabelNotification.message);
        //     return true;
        // }
    }
}

