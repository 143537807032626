import { Component, OnInit, Input, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration';
import { Router } from '@angular/router';
import { CustomRoutingName } from '../../../app.custom-routing-name';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { SelectItem } from 'primeng/components/common/selectitem';
import { StorageConfiguration } from '../../../shared/common/storage-setting/storage-configuration';

@Component({
  selector: 'app-common-calendar',
  templateUrl: './common-calendar.component.html',
  styleUrls: ['./common-calendar.component.sass']
})
export class CommonCalendarComponent implements OnInit {
  training_id: any;
  training: any;

  dayEvent: any;
  eventcal: any;
  popupEvent: string;
  todayDate: string;
  someattribute: any;
  node: string;
  equipmentOptions: any;
  locationOptions: any = [];
  LocationTest: any = [];
  equipClassification: any;
  facilityOptions: any;
  facilityResponse: any;
  FacilitySelectItem: SelectItem[] = [];
  selectedFacilityItem: FacilityInfo[];
  selectedLocationItem: FacilityInfo[];
  selectedEquipmentItem: FacilityInfo[];
  LocationSelectItem: SelectItem[] = [];
  EquipmentSelectItem: SelectItem[] = [];
  EquipmentClsfnSelectItem: SelectItem[] = [];
  selectedFacility: string[] = [];
  locationItem = [];
  facilityItem = [];
  equipmentItem = [];
  locationId = [];
  equipId = [];
  facilityId = [];
  startDateFrmt: Date;
  endDateFrmt: Date;
  filterActive: Boolean;
  @Input() Kind: any;
  id: string;
  tooltipevent: boolean = false;
  calendarForm: FormGroup;
  events: any[];
  headerConfig: any;
  productionUrl: string = 'ProductionCalendar/';
  apiRoute: string = 'facility/';
  facilityUrl: string = 'FacilityCalendar/';
  complianceUrl: string = 'ComplianceCalendar/';
  facilityFilterUrl: string = '/filtering/';
  facilityLocationUrl: string = 'FacilityCalendar/getlocation/';
  constructor(
    private dataService: DataService,
    private configuration: Configuration,
    private fb: FormBuilder,
    private router: Router,
    private customRoutingName: CustomRoutingName,
    private spinnerService: Ng4LoadingSpinnerService,
    private storageConfiguration: StorageConfiguration
  ) { }

  ngOnInit() {    
    this.headerConfig = {
      left: 'prev,next today',
      center: 'title',
      right: 'month,agendaWeek,agendaDay'
    };
    this.getFacility();
    this.todayDate = new Date().toISOString();
  }  
  getFacility() {
    this.dataService.getSingle('NewRecord', this.configuration.facility + '/').subscribe(
      (data: any) => {
           
        this.equipClassification = data.options.CompanySettings.Equipment_classification.Value;
        this.facilityOptions = data.options.Facility;
        this.locationOptions = data.options.Location;
        this.equipmentOptions = data.options.Equipment;

        this.FacilitySelectItem = this.bindDropDown(data.options.Facility, 'Facility');
        this.LocationSelectItem = this.bindDropDown(data.options.Location, 'Location');
        this.EquipmentSelectItem = this.bindDropDown(data.options.Equipment, 'Equipment');
        this.EquipmentClsfnSelectItem = this.bindDropDown(this.equipClassification, 'EquipmentClsfn');
      });
  }

  // bind values for drop down ========================================  
  bindDropDown(array: any[], ddType: string): any[] {
    var dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case 'Facility':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'Location':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'Equipment':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'EquipmentClsfn':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
      }
    });
    return dropDownItem;
  }

  //facility
  selectDropFacility(event) {
       
    var test = this.selectedFacilityItem;
    this.facilityId = event.value;
    if (this.facilityId.length > 0) {
      this.facilityItem.push({ id: event.itemValue });
      const filter = this.facilityId.map(filterItem => this.fb.group({ id: filterItem }));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Facility', filterFormArray);
      var filterItem = [];
      var filterConcat = [];
      this.facilityId.forEach(a => {
        var selectedLocation = this.locationOptions.filter(
          x => x.Facility_id === a);
        filterItem.push(selectedLocation);
      });
      filterItem.forEach(obj => {
        obj.forEach(childObj => {
          filterConcat.push(childObj);
        });
      });
      this.LocationSelectItem = this.bindDropDown(filterConcat, 'Location');
    }
    else {
      var emptyItem: any = [];
      const filter = emptyItem.map(filterItem => this.fb.group(filterItem));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Facility', filterFormArray);
      this.LocationSelectItem = this.bindDropDown(this.locationOptions, 'Location');
    }
  }
  // location
  selectDropLocation(event) {
       
       
    this.locationId = event.value;
    if (this.locationId.length > 0) {
      this.locationItem.push({ id: event.itemValue });
      const filter = this.locationId.map(filterItem => this.fb.group({ id: filterItem }));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Location', filterFormArray);
    }
    else {
      var emptyItem: any = [];
      const filter = emptyItem.map(filterItem => this.fb.group(filterItem));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Location', filterFormArray);
    }
  }
  // equipment
  selectDropEquipment(event) {
       
    this.equipId = event.value;
    if (this.equipId.length > 0) {
      this.equipmentItem.push({ id: event.itemValue });
      const filter = this.equipId.map(filterItem => this.fb.group({ id: filterItem }));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Equipment', filterFormArray);
    } else {
      var emptyItem: any = [];
      const filter = emptyItem.map(filterItem => this.fb.group(filterItem));
      const filterFormArray = this.fb.array(filter);
      this.calendarForm.setControl('Equipment', filterFormArray);
    }

  }

  onSubmit() {
    this.spinnerService.show();
    let startdateString = this.storageConfiguration.sessionGetItem(this.storageConfiguration.startDate);
    let newstartDate = new Date(startdateString);
    let enddateString = this.storageConfiguration.sessionGetItem(this.storageConfiguration.endDate);
    let newendDate = new Date(enddateString);
    //let test = this.calendarForm.value.Equipment;
    if (this.calendarForm.value.Location === undefined) {
      this.calendarForm.value.Location = [];
    }
    if (this.calendarForm.value.Equipment === undefined) {
      this.calendarForm.value.Equipment = [];
    }
    if (this.calendarForm.value.Facility === undefined) {
      this.calendarForm.value.Facility = [];
    }
    this.calendarForm.value.Start_date = newstartDate;
    this.calendarForm.value.End_date = newendDate;
    //test = this.calendarForm.value;
    this.dataService.add(this.configuration.facilityCalendar + this.facilityFilterUrl, this.calendarForm.value).subscribe(
      (calendar: any) => {
        this.events = calendar.result,
          this.spinnerService.hide(),
          // tslint:disable-next-line:no-unused-expression
          error => () => {
          }, () => {
            // tslint:disable-next-line:no-    
               
            this.spinnerService.hide();
          };
      });
  }
  calendarCreate() {
    this.calendarForm = this.fb.group({
      Facility: [] = [],
      Location: [] = [],
      Equipment: [] = [],
      Start_date: '',
      End_date: ''
    });
  }

  loadEvents(event, fc) {
    // tslint:disable-next-line:no-    
    this.eventcal = event;
    this.spinnerService.show();
    var currentDate = fc.getDate();
    var date = currentDate.toDate().toISOString();
    this.todayDate = new Date().toISOString();
    var getDate = event;
    // localStorage.setItem('startDate', getDate.view.start._d);
    this.storageConfiguration.sessionSetItem(this.storageConfiguration.startDate, getDate.view.start._d);
    // localStorage.setItem('endDate', getDate.view.end._d);
    this.storageConfiguration.sessionSetItem(this.storageConfiguration.endDate, getDate.view.end._d);
    this.calendarForm = this.fb.group({
      id: [''],
      title: [''],
      start: getDate.view.start._d,
      end: getDate.view.end._d,
      kind: this.Kind
    });
    if ((this.Kind === 'batch_record') || (this.Kind === 'order')) {
      this.filterActive = false;
      this.dataService.add(this.configuration.productionCalendar + '/', this.calendarForm.value).subscribe(
        (calendar: any) => {
          this.events = calendar.result;
          this.spinnerService.hide();
        });
    }
    // tslint:disable-next-line:max-line-length
    if ((this.Kind === 'batch_release') || (this.Kind === 'facility') || (this.Kind === 'environmental_monitoring') || (this.Kind === 'personnel_monitoring')) {
      this.filterActive = false;
      this.dataService.add(this.configuration.complianceCalendar + '/', this.calendarForm.value).subscribe(
        (calendar: any) => {
          this.events = calendar.result;
          this.training = calendar.options;
          this.spinnerService.hide();
        });
    }
  }

  eventClick(event) {
      
    switch (event.calEvent.Kind) {
      case 'maintenance_request':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.request_detail + this.id]);
        break;

      case 'batch_record':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.batch_detail + this.id]);
        break;

      case 'order':
        this.id = event.calEvent.id;

        if (event.calEvent.orderType === 'External') {
          this.router.navigate([this.customRoutingName.order_external_detail + this.id]);
        } else {
          this.router.navigate([this.customRoutingName.in_House_detail + this.id]);
        }


        // this.router.navigate([this.customRoutingName.order_detail + this.id]);
        break;

      case 'equipment':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.equipment_detail + this.id]);
        break;

      case 'location':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.location_detail + this.id]);
        break;

      case 'training_details':
           
        // this.id = event.calEvent.id;
        let x = (event.calEvent.id).split(' ');
        this.id = x[0];
        this.training_id = x[1];
        var filterItem = [];
        var selectedTaining = this.training.filter(
          x => x.id === this.training_id);
        filterItem.push(selectedTaining);
        this.router.navigate([this.customRoutingName.training_result_detail + this.id, { type: selectedTaining[0].Training_classification.id, parent: selectedTaining[0].Name, Training_detail_Id: selectedTaining[0].Training_detail_id }]);
        // this.router.navigate([this.customRoutingName.training_detail+this.id]);
        break;

      case 'personnel_monitoring':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.personnel_monitoring_detail + this.id]);
        break;

      case 'environmental_monitoring':
        this.id = event.calEvent.id;
        this.router.navigate([this.customRoutingName.environmental_monitoring_detail + this.id]);
        break;
    }
  }
  calendarChange(event) {
    let test = event;
  }
  back(fc) {
    fc.prev();
  }
  next(fc) {
    fc.next();
  }
  backYear(fc) {
    fc.prevYear();
  }
  nextYear(fc) {
    fc.nextYear();
  }
  refreshCal(fc) {
       
    var events = this.eventcal;
    this.loadEvents(events, fc);
  }
  changeView(fc) {
       
    this.dayEvent = {
      changeView: 'agendaDay'
    };
  }

  clearFilter(fc) {
       
    this.selectedFacilityItem = [];
    this.selectedLocationItem = [];
    this.selectedEquipmentItem = [];

    this.FacilitySelectItem = this.bindDropDown(this.facilityOptions, 'Facility');
    this.LocationSelectItem = this.bindDropDown(this.locationOptions, 'Location');
    this.EquipmentClsfnSelectItem = this.bindDropDown(this.equipClassification, 'EquipmentClsfn');
    var events = this.eventcal;
    this.loadEvents(events, fc);
  }
  dayClick(event, fc) {
       
    var getDate = event;
    var date = getDate.date._d;
    fc.changeView('agendaDay', date);
    fc.gotoDate(date);
  }

  mouseEventHover(event) {
       

    this.popupEvent = 'test';
    this.tooltipevent = true;
    // $('#fcl').fullCalendar({
    //   eventRender: function(eventObj, $el) {
    //     $el.popover({
    //       title: "test",
    //       content: "testt",
    //       trigger: 'hover',
    //       placement: 'top',
    //       container: 'body'
    //     });
    //   },

    // });

  }
  printComponent(cmpName) {
       
    
   document.getElementById(cmpName).innerHTML;
    window.print();
}
}

export interface FacilityInfo {
  id: string;
  Name: string;
}
