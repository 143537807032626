import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';


@Injectable({providedIn: 'root'})


export class SettingHelper {
    constructor(
        private toastr: ToastrService,

    ) { }
    inActiveArray = [];
    inactiveName = [];
    getStorageCondition(optionArray: any[], isNewRecordType: boolean, currentOptionValue: string) {
        return this.bindDropDownOption(optionArray, isNewRecordType, currentOptionValue);
    }

    bindDropDownOption(optionArray: any[], isNewRecordType: boolean, currentOptionValue: string) {

        let result = {};
        let isActiveOptionArray = [];
        let inActiveOptionArray = [];
        isActiveOptionArray = optionArray.filter(a => a.Is_active === true);
        inActiveOptionArray = optionArray.filter(a => a.Is_active === false);
        if (isNewRecordType) {
            return this.bindOptionList(isActiveOptionArray);
        } else {
            this.inActiveArray = [];
            this.inActiveArray = inActiveOptionArray.filter(a => a.Value === currentOptionValue);
            this.inActiveArray.forEach(a => {
                isActiveOptionArray.push(a);
            });
            return this.bindOptionList(isActiveOptionArray);
        }
    }


    bindOptionList(optionArray) {
        var dropDownItem = [];
        optionArray.forEach(a => {
            dropDownItem.push({ label: a.Value, value: a.Value, Is_active: a.Is_active });
        });
        return dropDownItem;
    }


    getReleaseCriteria(optionArray: any[], isNewRecordType: boolean, currentOptionValue) {
        let isActiveOptionArray = [];
        let inActiveOptionArray = [];
        inActiveOptionArray = optionArray.filter(a => a.Is_active === false);
        isActiveOptionArray = optionArray.filter(a => a.Is_active === true);
        if (isNewRecordType) {
            return isActiveOptionArray;
        } else {
            this.inActiveArray = [];
            currentOptionValue.forEach(element => {
                this.inActiveArray = inActiveOptionArray.filter(a => a.Value === element);
                this.inActiveArray.forEach(a => {
                    isActiveOptionArray.push(a);
                });
            });
            return isActiveOptionArray;
        }
    }


    releaseCriteriaValidation(currentValue, optionArray) {
        var ValidationResult: any;
        var resultOptionArray: boolean;
        var inActiveValue = [];
        currentValue.forEach(element => {
            inActiveValue = optionArray.filter(a => a.Value === element && a.Is_active === true);
        });
        if (inActiveValue.length === 0 && currentValue.length !== 0) {
            this.toastr.warning(currentValue + ' is deactivated in company Settings, Change before saving');
            return resultOptionArray = false;
        } else {
            return resultOptionArray = true;
        }
    }

    validationOnSave(currentValue, optionArray) {
        var ValidationResult: any;
        var resultOptionArray: boolean;
        var inActiveValue = optionArray.filter(a => a.value === currentValue && a.Is_active === true);
        if (inActiveValue.length === 0 && currentValue !== '') {
            this.toastr.warning(currentValue + ' is deactivated in company Settings, Change before saving');
            return resultOptionArray = false;
        } else {
            return resultOptionArray = true;
        }
    }
}


