import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { SelectItem } from "primeng/primeng";
import { Message } from "primeng/components/common/message";
import { DialogModule } from "primeng/dialog";
// import "rxjs/add/operator/debounceTime";
import { TableModule } from "primeng/table";
import { CheckboxModule } from "primeng/checkbox";
import { OrderListModule } from "primeng/orderlist";
import { Routes, RouterModule, Router, ActivatedRoute } from "@angular/router";
import { DecimalPipe } from "@angular/common";
import {FormBuilder,FormGroup,Validators,FormControl,FormArray} from "@angular/forms";
import {BaseSettingsModel} from "../../model/base-model.interface"

@Component({
  selector: 'app-common-selectable-list',
  templateUrl: './common-selectable-list.component.html',
  styleUrls: ['./common-selectable-list.component.sass']
})
export class CommonSelectableListComponent implements OnInit {
  selectedItems: any[];
  show: boolean = false;
  facilityLists :boolean = false;
  EquipmentLists :boolean= false;
  LocationLists :boolean= false;
  requestUserlists : boolean = false;

  @Input() option: any;  
  @Input() requestName: string;  
  @Input() Class: any;
  @Input() selectedUser:any[] = [];
  dataArray : any[] = []; 
  @Output() emitChildRecordEvent = new EventEmitter<string[]>();  
  
  constructor() { }

  ngOnInit() {
   if(this.selectedUser == null)
    {
      this.option.forEach(element => {
        this.selectedUser.push({ Name: element.Name, Checked: true ,id:element.id});
      });
    }
    else{
          this.option.forEach(element => {
            var isAvailable = this.selectedUser.find(x => x.id == element.id)
            if (isAvailable == null) {
                this.dataArray.push({ Name: element.Name,Checked: false, id:element.id });
            } else {
              this.dataArray.push({ Name: element.Name, Checked: true  ,id:element.id });
            }
          });    
    }
    this.onChangeEquipment(); 
   // console.log("options",this.option)
  //   this.option.forEach(element => {
  //     this.dataArray.push({Name: element.Name, Checked:false, id:element.id});
  //     console.log("userarrray", this.dataArray)
  //   });
  }


  onChangeEquipment()
  {       
      var result= [];    
      var results= [];    

      this.dataArray.forEach(element => {
          if(element.Checked)
          {
            results.push(element.Name);

              result.push({Name: element.Name,id:element.id});
          }
      });      
    this.emitChildRecordEvent.emit(result);  
    this.selectedItems = results; 
  }

  showDialog(event){
    if(event == 'hide'){
      this.show = false;
    }
    else if(event == 'show'){
      this.show = true;
    }
    
    
  }

}
