import { Injectable } from '@angular/core';
import { StorageConfiguration } from './storage-setting/storage-configuration';

@Injectable({
  providedIn: 'root'
})
export class RolesPermissions {

  userInfo: any;
  MenuHide: boolean = false;
  constructor(
    private storageConfiguration: StorageConfiguration
  ) {

  }
  getPermission(module) {
    // var stringPermission = localStorage.getItem('permission_settings');
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    let settingPermission = this.userInfo[0].permissionSetting.Common_permission_settings;
    let permission = settingPermission;

    var permissionRole = [];
    permission.forEach((a) => {
      if (a.Module_name == module) {
        permissionRole.push(a);
      }
    });
    var permissionoObj = permissionRole[0];
    return permissionoObj;
  }


  getSidebarPermission(module) {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    let settingPermission = this.userInfo[0].permissionSetting.Common_permission_settings;
    let permission = settingPermission;

    var permissionRole = [];
    permission.forEach((a) => {
      if (a.Module_name == module) {
        permissionRole.push(a);
      }
    });
    var permissionoObj = permissionRole[0];
    if ((!permissionoObj.View) && (!permissionoObj.Create) && (!permissionoObj.Edit) && (!permissionoObj.Delete)) {
      this.MenuHide = true;
    }
    else {
      this.MenuHide = false;
    }

    // if (this.userInfo[0].role != 'Management' && this.userInfo[0].pharmacy_type == 'A PHARMACY' && (module == 'Customer' || module == 'Order')) {
      if (this.userInfo[0].pharmacy_type == 'A PHARMACY' && (module == 'Customer' || module == 'Order')) {
      this.MenuHide = true;
    } else {
      this.MenuHide = false;
    }

    return this.MenuHide;
  }
}



