// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.
export const environment = {
  production: false,
  appVersion: require('../../package.json').version,

  /** Local Site */
  // complianceApi: 'http://192.168.0.100:810/api/',
  // facilityApi: 'http://localhost:57846/api/',
  // orderApi: 'http://localhost:19189/api/',
  // productionApi: 'http://localhost:8163/api/',

  // complianceApi: 'https://compliance-api.cpguardian.com/api/',
  // orderApi: 'https://order-api.cpguardian.com/api/',
  // productionApi: 'https://production-api.cpguardian.com/api/',
  // facilityApi: 'https://facility-user-api.cpguardian.com/api/',
  // notificationHub:'https://cpg-notification-api.azurewebsites.net/api/',
  // notificationHubApi:'https://cpg-notification-api.azurewebsites.net/api/',

  // productionApi: 'http://192.168.0.100:850/api/',


  // complianceApi: 'https://staging-compliance-api.cpguardian.com/api/',
  // orderApi: 'https://staging-order-management-api.cpguardian.com/api/',
  // productionApi: 'https://staging-production-api.cpguardian.com/api/',
  // facilityApi: 'https://staging-facility-user-api.cpguardian.com/api/',
  // facilityApi: 'https://staging-facility-user-api.cpguardian.com/api/',
  // notificationHubApi: 'https://cpg-notification-api-staging.azurewebsites.net/',

  // Pre-Staging
  complianceApi: 'https://cpg-compliance-api-pre-staging.azurewebsites.net/api/',
  orderApi: 'https://cpg-order-management-api-pre-staging.azurewebsites.net/api/',
  productionApi: 'https://cpg-production-api-pre-staging.azurewebsites.net/api/',
  facilityApi:  'https://cpg-facility-user-api-pre-staging.azurewebsites.net/api/',
  notificationHubApi: 'https://cpg-notification-api.azurewebsites.net/'


  // complianceApi: 'http://localhost:22915/api/',
  //  facilityApi: 'http://localhost:57846/api/',
  // orderApi: 'http://192.168.0.100:840/api/',
  //  productionApi: 'http://localhost:8163/api/',
  // notificationHubApi: 'https://localhost:44392/',



};