import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CoreRoutingModule } from './core-routing.module';
import { LayoutComponent } from './layout/layout.component';
import { TopHeaderComponent } from './top-header/top-header.component';
import { SideBarComponent } from './side-bar/side-bar.component';
import { yearCalculate } from '../shared/common/common-helper';
import { DialogModule, DataTableModule } from 'primeng/primeng';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {CalendarModule} from 'primeng/calendar';
// import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { DropdownModule } from 'primeng/dropdown';
import { SharedModule } from '@app/shared/shared.module';

 
@NgModule({
  imports: [SharedModule,
    CommonModule,
    CoreRoutingModule,
    DialogModule,
    DataTableModule,
    CalendarModule,
    DropdownModule,
    ConfirmDialogModule,
    // Ng4LoadingSpinnerModule.forRoot(),


  ],
  declarations: [
    // AuthGuardService,
     ],
     providers: [yearCalculate]
})
export class CoreModule { }
