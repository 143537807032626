import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { AuthService } from '../../auth/service/auth.service';
import { DataService } from '../../shared/service/data-service';
import { Configuration } from '../../app.configuration';
import { LogoutResponse } from '../../auth/model/user-login.interface';
import { LoginInfo } from '../../auth/model/user-login.interface';
import { CustomRoutingName } from '../../app.custom-routing-name';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';
import { NotifyDataService } from '../../shared/service/notification-service';
import { yearCalculate } from '../../shared/common/common-helper';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DatePipe } from '@angular/common';
import { FormGroup, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'environments/environment';



@Component({
  selector: 'app-top-header',
  templateUrl: './top-header.component.html',
  styleUrls: ['./top-header.component.sass'],
  providers: [ConfirmationService,  Ng4LoadingSpinnerService, DatePipe]
})
export class TopHeaderComponent implements OnInit {


  LoginInfo: any;
  logoutDetail: string;
  message: string;
  name: string;
  role: string;
  logoUrl: string;
  displayTaskListDialog: boolean;
  displayNotifyListDialog: boolean;
  taskList: any;
  taskCount: number;
  notifyCount: number;
  notifyList: any[];
  noRec: boolean;
  userInfo: any;
  userId: any;
  todayDate: any;
  yrRange: string;
  ShowBarcode: boolean;
  headerSearchSize: boolean;
  @ViewChild('focusInputBarcode', { static: true }) focusInputBarcode: ElementRef;
  BarcodeForm: FormGroup;
  scanResult: any;
  appVersion : string = `v ${environment.appVersion}`;

  constructor(
    private authService: AuthService,
    private router: Router,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private configuration: Configuration,
    private customRoutingName: CustomRoutingName,
    private storageConfiguration: StorageConfiguration,
    private notifyDataService: NotifyDataService,
    private yearRange: yearCalculate,
    private datePipe: DatePipe,
    private fb: FormBuilder,
    private toastr: ToastrService,
  ) {

    this.BarcodeForm = this.fb.group({
      Barcode: ''
    });
  }

  

  ngOnInit() {
     this.todayDate = new Date();
    this.yrRange = this.yearRange.yrRange;
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.name = this.userInfo[0].name;
    this.role = this.userInfo[0].role;
    this.notifyDataService.notifycast.subscribe(getCount => this.notifyCount = getCount);
    this.notifyDataService.taskCast.subscribe(getCount => this.taskCount = getCount);
    // this.taskDetail();
    this.taskListCount();
    this.notificationCount();
    // this.notificationDetail();
  }

  logout() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Logout?',
      header: 'Log Out',
      icon: 'fa fa-sign-out',
      accept: () => {
        this.logOutAccept();
      },
      reject: () => {
      }
    });
  }


  logOutAccept() {
    const loginInfo: LoginInfo = {
      User_id: this.userInfo[0].id,
      Login_id: this.storageConfiguration.sessionGetItem(this.storageConfiguration.currentlogin)
    };
    this.dataService.add(this.configuration.logOut, loginInfo).subscribe(
      (response: LogoutResponse) => {
        this.message = response.message;
      }, err => {
        this.message = err.error.message;
      });
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.token);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.password);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.user_Info);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.currentlogin);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.id);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.startDate);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.endDate);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.isloggedIn);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.masterFormStatus);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.masterFormStatusFacility);
    this.authService.logoutUser();
    this.router.navigate([this.authService.getLoginUrl()]);
  }

  /**OPEN TASK LIST DIALOG */
  taskDetailDialog() {
    this.taskDetail();
    this.displayTaskListDialog = true;
  }

  taskDetail() {
    // tslint:disable-next-line:prefer-const
    this.userId = this.userInfo[0].id;
    this.dataService.getSingle(this.userId, this.configuration.taskManagement + '/GetTaskListByAssigneeId/').subscribe(
      (data: any) => {
        if (data.status) {
          this.noRec = false;
          this.taskList = data.result;
          // this.taskCount = data.result.length;
        } else {
          this.noRec = true;
          // this.taskCount = 0;
        }
      }, err => {
      });

  }

  taskListCount() {
    this.userId = this.userInfo[0].id;
    this.dataService.getSingle(this.userId, this.configuration.taskManagement + '/GetTaskCountByAssigneeId/').subscribe(
      (data: any) => {
        if (data.status) {
          this.taskCount = data.result;
        } else {
          this.taskCount = 0;
        }
      }, err => {
      });
  }

  /** Get notification List and Count*/
  NotificationDialog() {
    this.notificationDetail();
    // this.displayNotifyListDialog = true;
    this.router.navigate([this.customRoutingName.notification_list]);
  }

  notificationDetail() {
    this.dataService.add(this.configuration.notificationApi + '/GetNotificationAsync/' +
      this.dataService.getTenentId() + ',' + this.userInfo[0].id, '').subscribe(
        (data: any) => {
          if (data.status) {
            this.noRec = false;
            this.notifyList = data.result;
            this.notifyCount = data.result.length;
          } else {
            this.noRec = true;
            this.notifyCount = 0;
          }
        });
  }

  notificationCount() {
    this.dataService.add(this.configuration.notificationApi + '/GetNotificationCountAsync/' +
      this.dataService.getTenentId() + ',' + this.userInfo[0].id, '').subscribe(
        (data: any) => {
          if (data.status) {
            this.notifyCount = data.result;
          } else {
            this.notifyCount = 0;
          }
        });
  }

  editTask(id) {
    this.displayTaskListDialog = false;
    this.router.navigate([this.customRoutingName.task_detail + id]);
  }

  taskDetailEdit(id, modelName) {
    this.displayNotifyListDialog = false;
    if (modelName !== 'Others') {
      this.pageRouting(id, modelName);
    } else {
      const getNotifyRecord = this.notifyList.find(a => a.Notification_details.Supporting_id === id);
       this.router.navigate([this.customRoutingName.other_notification + getNotifyRecord.id]);
      this.dataService.update('',
        this.configuration.notificationApi + '/' + this.dataService.getTenentId() + ',' + getNotifyRecord.id, getNotifyRecord).subscribe(
          (data: any) => {
           });
    }
  }

  pageRouting(id, type) {
    switch (type) {
      case 'Facility':
        this.router.navigate([this.customRoutingName.facility_detail + id]);
        break;
      case 'Location':
        this.router.navigate([this.customRoutingName.location_detail + id]);
        break;
      case 'EM':
        this.router.navigate([this.customRoutingName.environmental_monitoring_detail + id]);
        break;
      case 'PM':
        this.router.navigate([this.customRoutingName.personnel_monitoring_detail + id]);
        break;
      case 'Master Formulation':
        this.router.navigate([this.customRoutingName.master_formulation_detail + id]);
        break;
      case 'NewProduct':
        this.router.navigate([this.customRoutingName.in_House_detail + id]);
        break;
      case 'NewProducts':
        this.router.navigate([this.customRoutingName.in_House_detail + id]);
        break;
      case 'Modification':
        this.router.navigate([this.customRoutingName.batch_detail + id]);
        break;
      case 'Deviation':
        this.router.navigate([this.customRoutingName.batch_detail + id]);
        break;
    }

  }

  filterDate(event) {
    const date = this.transformDate(event);
    this.notifyList = this.notifyList.filter(a => this.transformDate(a.Notification_details.Created_date) == date);
  }

  clearDateRangePicker(event) {
    this.notificationDetail();
  }

  transformDate(date) {
    return this.datePipe.transform(date, 'yyyy-MM-dd');
  }
  BarcodeScanner() {
    this.headerSearchSize = false;
    this.ShowBarcode = !this.ShowBarcode;

    if (this.ShowBarcode) {
      setTimeout(() => this.focusInputBarcode.nativeElement.focus(), 0);
      if (screen.width <= 1335) {
        this.headerSearchSize = true;
      }
    }



  }

  keyDownFunction(event) {
    const input = this.BarcodeForm.get('Barcode').value;
    // const input = '1-110231';
    if (event.keyCode === 13 && input !== '') {

      const i = input.split('-');
      switch (i[0]) {
        case '1':
          // Batch
          this.dataService.getSingle(input, this.configuration.labelBarcodeGeneration + '/ScanBarcode/').subscribe(
            (data: any) => {
              if (data.status) {
                this.scanResult = data.result;
                this.router.navigate([this.customRoutingName.batch_detail + this.scanResult.Master_id]);
              } else {
                this.toastr.warning('Record Not Found');
              }
            }, err => {
            });
          break;
        case '2':
          // chemical
          this.dataService.getSingle(input, this.configuration.labelBarcodeGeneration + '/ScanBarcode/').subscribe(
            (data: any) => {
              if (data.status) {
                this.scanResult = data.result;
                this.router.navigate([this.customRoutingName.chemical_child_list + this.scanResult.id]);
              } else {
                this.toastr.warning('Record Not Found');
              }
            }, err => {
            });
          break;
        case '3':
          // component
          this.dataService.getSingle(input, this.configuration.labelBarcodeGeneration + '/ScanBarcode/').subscribe(
            (data: any) => {
              if (data.status) {
                this.scanResult = data.result;
                this.router.navigate([this.customRoutingName.component_master_child + this.scanResult.id]);
              } else {
                this.toastr.warning('Record Not Found');
              }
            }, err => {
            });

          break;

        default:
          // equipment
          this.dataService.getSingle(input, this.configuration.labelBarcodeGeneration + '/ScanBarcode/').subscribe(
            (data: any) => {
              if (data.status) {
                this.scanResult = data.result;
                this.router.navigate([this.customRoutingName.equipment_detail + this.scanResult.Master_id]);
              } else {
                this.toastr.warning('Record Not Found');
              }
            }, err => {
            });
          break;
      }
      this.BarcodeForm.reset();
    }
  }



}
