import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../shared/service/data-service';
import { Configuration } from '../../app.configuration';
import {  Router } from '@angular/router';
import {  ForgotPwdResponse } from '../model/user-login.interface';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.sass']
})
export class ForgotPasswordComponent implements OnInit {
  
  forgotPwdForm: FormGroup;
  user: any;
  message: any;
  errorMessage: any;
  constructor(
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService,
    private toastr: ToastrService,
    private location: Location
    ) { }

  ngOnInit(): void {
    this.createForm();
   }
  createForm() {
    this.forgotPwdForm = this.fb.group({
      username: ['']
    });
  }
  forgotPassword(forgotPwdForm) {
    this.spinnerService.show();
    this.user = forgotPwdForm.value.username;
     this.dataService.add(this.configuration.forgotPassword + this.user, this.user).subscribe(
      (response: ForgotPwdResponse) => {
         this.spinnerService.hide();
        this.message = response.message;
        if (this.message === 'Mail sent successfully!!') {
          this.toastr.success('Reset password mail sent successfully!!');
          this.router.navigate(['login']);
        } else {
          this.message = 'Invalid Email!';
          this.toastr.error('Entered invalid email!');
        }
      }, err => {
        this.spinnerService.hide();
         this.message = err.error.message;
      });
  }
  backClicked(){
    this.location.back();
  }

}
