import { Injectable } from '@angular/core';
@Injectable({
    providedIn: 'root'
})

export class FilePatchComponent{
    setuploadfiles:any;
public genericFilePatch($event,form) {

    var newFileModel;
    var modelName=$event.$modelName;
    var arrayList = $event.$result;
     if(!$event.$isDelete)
    {            
            this.setuploadfiles = form.controls['File_data'].value;
if(this.setuploadfiles!=null)
{
            if(this.setuploadfiles.length>0){
                    this.setuploadfiles.forEach(element => {
                        if(element.Doc_field_type === modelName)
                        {
                            for(var i=0;i<$event.$result.length;i++)
                            {
                            element.Doc_list.push($event.$result[i]);
                            }
                        }
                        else
                        {                        
                            newFileModel= {
                            Doc_field_type : modelName,
                            Doc_list : arrayList
                            };
                            this.setuploadfiles.push(newFileModel);
                        }
                    });
                    return this.setuploadfiles;
                    //form.patchValue({ File_data : this.setuploadfiles});
        }else
        {
            newFileModel = [{
                Doc_field_type : modelName,
                Doc_list : arrayList
            }];
            return newFileModel;
            //form.patchValue({ File_data : newFileModel});
        }
    }else
    {
        newFileModel = [{
            Doc_field_type : modelName,
            Doc_list : arrayList
        }];
        return newFileModel;
    }
    }
    else
        {
             this.setuploadfiles = form.controls['File_data'].value;                        
            this.setuploadfiles.forEach(element => {
                if(element.Doc_field_type === modelName)
                {
                    element.Doc_list.splice($event.$index, 1);
                }
            });
            return this.setuploadfiles;
                        //form.patchValue({ File_data : this.setuploadfiles});
        }
            
    }
}