import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthModule } from './auth/auth.module';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CustomInterceptor } from './shared/service/data-service';
import { DatePipe } from '@angular/common';
import { LayoutComponent } from './core/layout/layout.component';
import { TopHeaderComponent } from './core/top-header/top-header.component';
import { SideBarComponent } from './core/side-bar/side-bar.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { yearCalculate } from './shared/common/common-helper';
import { CKEditorModule } from 'ng2-ckeditor';
import { ToastrModule } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { PrimengModule } from './primeng.module';
import { SharedModule } from './shared/shared.module';
import { CoreModule } from './core/core.module';
import { NgxUiLoaderManualService } from "./shared/service/ngx-ui-loader.service";
// For Router Properties and Parameters
import { NgxUiLoaderModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION } from 'ngx-ui-loader';
// For Router Loading
import { NgxUiLoaderRouterModule } from 'ngx-ui-loader';
// For http request Loading
import { NgxUiLoaderHttpModule } from 'ngx-ui-loader';
const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  
};

@NgModule({
  declarations: [
    AppComponent,
  
   ],

  imports: [
    BrowserModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    AppRoutingModule,
    HttpClientModule, // import HttpClientModule
    // import NgxUiLoaderModule
    NgxUiLoaderRouterModule, // import NgxUiLoaderRouterModule. By default, it will show foreground loader.
    // If you need to show background spinner, do as follow:
    // NgxUiLoaderRouterModule.forRoot({ showForeground: false })
    NgxUiLoaderHttpModule, // import NgxUiLoaderHttpModule. By default, it will show background loader.
    // If you need to show foreground spinner, do as follow:
    // NgxUiLoaderHttpModule.forRoot({ showForeground: true })
    CKEditorModule,
    CoreModule,
    CurrencyMaskModule,
    SharedModule,
    AuthModule,
    BrowserAnimationsModule,
    
    
    PrimengModule,
    ShowHidePasswordModule.forRoot(),
    Ng4GeoautocompleteModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot(),
    ToastrModule.forRoot({
    closeButton: true,
    enableHtml: true,
    positionClass: 'toast-top-center',
    preventDuplicates: true
    }),
  ],
  providers: [{ provide: HTTP_INTERCEPTORS, useClass: CustomInterceptor, multi: true }, ConfirmationService, yearCalculate, DatePipe,NgxUiLoaderManualService],
  bootstrap: [AppComponent]
})
export class AppModule { }
