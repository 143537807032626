import { Injectable } from '@angular/core';
import { environment } from '../environments/environment';
@Injectable({
    providedIn: 'root'
})
export class Configuration {
    public ApiUrl = 'api/v1/';
    public DefaultServer = 'http://localhost:5000/';
    public OrderStripeKeys = 'pk_test_nP34ZcSZeeMgsVHb630YrSTl';
    public OrderAccontChangeId = 'NewRecord' + '?customerId' + '=';
    // Common URL
    public fileUploadServer = environment.productionApi + 'FileUpload/';
    // Login Api URLs
    public logInView = environment.facilityApi + 'LoginView/GetLoginView';
    public logIn = environment.facilityApi + 'LoginFacility';
    public resetPassword = environment.facilityApi + 'LoginFacility/ResetPassword';
    public forgotPassword = environment.facilityApi + 'LoginFacility/ForgetPassword?username=';
    public changePassword = environment.facilityApi + 'LoginFacility/ChangePassword';
    public forceLogIn = environment.facilityApi + 'LoginFacility/ForceLogin';
    public logOut = environment.facilityApi + 'LoginFacility/Logout';
    // Dashboard Api URLs
    public dashboardMonitor = environment.facilityApi + 'DashboardMonitor';
    public dashboardProduction = environment.facilityApi + 'DashboardProduction';
    public dashboardSales = environment.facilityApi + 'DashboardSales';
    // Custormer Api URLS
    public customer = environment.orderApi + 'Customer';
    // Order Api URLs
    public order = environment.orderApi + 'Order';
    public audit = environment.orderApi + 'AuditTrail';
    // Inventory Api URLs
    public chemicalInventory = environment.productionApi + 'ChemicalInventory';
    public chemicalChildInventory = environment.productionApi + 'ChemicalChildInventory';
    public componentInventory = environment.productionApi + 'ComponentInventory';
    public componentChildInventory = environment.productionApi + 'ComponentChildInventory';
    public finalProductInventory = environment.productionApi + 'FinalProduct';
    public finalProductChildInventory = environment.productionApi + 'FinalProductChild';
    // Production Api URLs
    public productionCalendar = environment.productionApi + 'ProductionCalendar';
    public batchRecord = environment.productionApi + 'BatchRecord';
    public batchCoverSheet = environment.productionApi + 'BatchCoverSheet';
    public masterFormulation = environment.productionApi + 'MasterFormulationRecord';
    public productionScheduler = environment.productionApi + 'ProductionScheduler';
    public chemicalLotAdd = environment.productionApi + 'BatchRecord/ChangeChemicalLot';
    public componentLotAdd = environment.productionApi + 'BatchRecord/ChangeComponentLot';
    public MeasureOperator = environment.productionApi + 'ScaleMonitor/VerifyCode/';
    public getFinalProduct = environment.productionApi + 'BatchRecord/GetMFR';
    public getChildBatch = environment.productionApi + 'BatchRecord/GetChildBatches';
    public getMasterMFR = environment.productionApi + 'BatchRecord/GetMFRForMasterBatch/';
    public editInventory = environment.productionApi + 'BatchRecord/EditInventoryQuantity/';

    // Facility Api URLs
    public equipment = environment.complianceApi + 'Equipment';
    public facility = environment.complianceApi + 'Facility';
    public location = environment.complianceApi + 'Location';
    public maintenanceRequest = environment.complianceApi + 'MaintenanceRequest';
    // Compliance Api URLs
    public compoundingMethodology = environment.complianceApi + 'v1/AsepticProcess';
    public complianceCalendar = environment.complianceApi + 'ComplianceCalendar';
    public environmentalMonitoring = environment.complianceApi + 'EnvironmentalMonitoring';
    public facilityCalendar = environment.complianceApi + 'FacilityCalendar';
    public personnelMonitoring = environment.complianceApi + 'PersonnelMonitoring';
    public releaseManagement = environment.complianceApi + 'ReleaseManagement';
    public training = environment.complianceApi + 'Training';
    public taskManagement = environment.complianceApi + 'v1/TaskManagement';
    public notificationApi = environment.complianceApi + 'Notification';
    public notification = environment.notificationHubApi + 'api/Notification';
    // Users Api URLs
    public manageUser = environment.facilityApi + 'ManageUser';
    public manageProfile = environment.facilityApi + 'UserProfile';
    public manageRole = environment.facilityApi + 'UserRole';
    // Settings Api URLs
    public settingsApi = environment.facilityApi + 'CompanySettings/';
    public labelSize = environment.facilityApi + 'LabelSize';
    public application = environment.facilityApi + 'ApplicationSettings';
    public createJiraIssue = environment.facilityApi + 'CreateJiraIssue';
    public labelBarcodeGeneration = environment.facilityApi + 'GenerateLabelBarcodeScan';
    public getClinetDetails = environment.facilityApi + 'ApplicationSettings/GetClientDetails';

    // Report Api URLs
    public report = environment.facilityApi + 'Report';
    public Excell = environment.orderApi;
    // Notification URls
    public taskNotification = environment.notificationHubApi + 'api/Notification';
    public taskNotification2 = environment.notificationHubApi;



}
