import { Injectable } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from './data-service';
import { Configuration } from '../../app.configuration';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
@Injectable({providedIn: 'root'})

export class GenerateLabelService {

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private router: Router,
    private toastr: ToastrService,
    public confirmationService: ConfirmationService
  ) {

  }
  responseBody: any;

  public showDialog_GenerateLabel(type, form) {
  
       
    this.spinnerService.show();
    const DetailFormValue = form;
    const obj = {
      form: form,
      Kind: ''
    };
    let allowGenerate = false;
    let labelCount = 0;

    switch (type) {
      case 'Chemical':
        obj.Kind = 'new_chemical_inventory';
        labelCount = DetailFormValue.Quantity_per_container.Value;
        allowGenerate = labelCount !== 0 ? true : false;
        break;
      case 'Component':
        obj.Kind = 'new_component_inventory';
        labelCount = DetailFormValue.Quantity_per_container;
        allowGenerate = labelCount !== 0 ? true : false;
        break;
      case 'Equipment':
        obj.Kind = 'equipment';
        labelCount = 1;
        allowGenerate = labelCount !== 0 ? true : false;
        break;
    }

    if (allowGenerate) {

      const Labeltext = 'Generating ' + labelCount + ' Labels<br>In Progress';
      const Lotnumber = DetailFormValue.Lot_number;
      const toastVariable = this.toastr.success(Labeltext, 'Lot Number : ' + Lotnumber,
        {
          disableTimeOut: true, closeButton: true,
          positionClass: 'toast-top-right', tapToDismiss: false,
          enableHtml: true, titleClass: 'labelGenerateToast'
          // progressBar: true, progressAnimation: 'increasing'
        });
      sessionStorage.setItem('LblLot', Lotnumber);
      const labelData = this.dataService.add<any>(this.configuration.labelBarcodeGeneration + '/GenerateLabel', obj).toPromise();

      labelData.then(data => {
        this.responseBody = data;
        const LabelLot = sessionStorage.getItem('LblLot');
        this.toastr.success('Label Generated Successfully', 'Lot Number : ' + LabelLot, {
          disableTimeOut: true, positionClass: 'toast-top-right', titleClass: 'labelGenerateToast'
        });
        sessionStorage.removeItem('LblLot');
        toastVariable.onTap.subscribe((e) => {
        });
        const currentUrl = this.router.url;
        // currentUrl.('manage-batch-detail');
        // this.pdfview = this.responseBody.result;
        // this.display_GenerateLabel = true;
        return currentUrl;
      });
    } else {
      this.toastr.warning('Quantity Per Container is Empty');
    }
  }

}
