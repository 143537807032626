import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router} from '@angular/router';
import { DataService } from '../../../../service/data-service';
import { Configuration } from '../../../../../app.configuration';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '../../../../model/toast-data-model';
import { ValidationCheck } from '../../../common-validation';
import { ConfirmationService } from 'primeng/api';
import { CustomRoutingName } from '../../../../../app.custom-routing-name';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Response } from '../../../../../module/inventory/model/component-inventory.interface';
import { AuditFiles } from '../../../audit-File';
import { StorageConfiguration } from '../../../storage-setting/storage-configuration';
import { NameCapitalize } from '../../../common-helper';

@Component({
  selector: 'app-component-add-master',
  templateUrl: './component-add-master.component.html',
  styleUrls: ['./component-add-master.component.sass'],
  providers: [ConfirmationService,  NameCapitalize]
})
export class ComponentAddMasterComponent implements OnInit {

  addChildResult: { childPopup: boolean; id: string; };
  @Output() CancelDialog = new EventEmitter<any>();
  @Output() addChild = new EventEmitter<any>();
  @Input() mode: any;
  @Input() copyInventory;

  childPopup: boolean;
  resetForm: void;
  dialogMessage: string;
  newcomponent: boolean;
  display: boolean;
  result: string;
  id: string;
  date: string;
  Match: boolean;
  addComp: FormGroup;
  keyCode: number;
  apiRoot: string = 'ComponentInventory';
  blockSpecial: RegExp = /^[^<>*!'"]+$/;
  userInfo: any;
  editNotesIndex: any;
  editNotesPopup: boolean;
  editedNotes: any;
  ipAddress: any;

  constructor(
    private router: Router,
    private dataService: DataService,
    private configuration: Configuration,
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private customRoutingName: CustomRoutingName,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private validationCheck: ValidationCheck,
    private auditFiles: AuditFiles,
    private storageConfiguration: StorageConfiguration,
    private nameCapitalize: NameCapitalize

  ) { }

  ngOnInit() {
    this.createForm();
    const publicIp = require('public-ip');
    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    if (this.copyInventory != undefined) {
      this.addComp.patchValue({
        Name: this.copyInventory.Name + ' - Copy',
        id: '',
        Is_active: this.copyInventory.Is_active,
        Compliance_component: this.copyInventory.Compliance_component,
        Date: this.copyInventory.Date
      });
    }

  }

  createForm() {
    this.addComp = this.fb.group({
      Name: ['', Validators.required],
      Compliance_component: false,
      Child_inventory: this.fb.array([]),
      Activity_log: this.fb.array([]),
      id: [''],
      Allocated_quantity: 0,
      In_process_quantity: 0,
      Quantity_onhand: 0,
      Used_quantity: 0,
      Kind: [''],
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Notes: [],

      Is_active: true,
      Is_deleted: false,
      Is_name_changed: false,
      Is_name_disable: false,
      Master_barcode: ['']
    });
  }

  submit(data) {
       
    const invalid = [];
    const controls = this.addComp.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    var validatorArrays;
    validatorArrays = this.validationCheck.getValidation(invalid);
       
    if (invalid.length == 0) {
      //    
      // data = data.value;
      if (this.keyCode != 13) {
        if (this.addComp.invalid) {
          this.Match = true;
        } else {
          this.spinnerService.show();
          data = data.value;
          this.date = new Date().toISOString();
          data.Created_info.Date = this.date;
          data.Created_info.By = this.userInfo[0].id;
          data.Created_info.Name = this.userInfo[0].name;
          data.Created_info.Date = this.date;
          data.Kind = 'component';
          let activeModel = [];
          activeModel = this.auditFiles.newCreateActivity('Name', data.Name);
          data.Activity_log = activeModel;
          this.dataService.add(this.configuration.componentInventory, data).subscribe(
            (data: Response) => {
              this.spinnerService.hide();
              this.id = data.message.id;
              this.result = data.message.result;
              if (this.result === 'Document created successfully!!') {
                this.newcomponent = false;
                this.display = true;
                this.dialogMessage = this.result;
                if (this.mode === 'MasterFormulation') {
                  this.childPopup = true;
                  this.addChildResult = { childPopup: this.childPopup, id: this.id };
                  this.addComp.reset();
                  this.addChild.emit(this.addChildResult);
                } else {
                  this.router.navigate([this.customRoutingName.component_master_detail + this.id]);
                }
                setTimeout(() => {
                  this.display = false;
                }, 1000);
              }
              this.toastr.success(this.toastMessage.Inventory + this.toastMessage.Create);
            },
            err => {
              if (err.error.message.result === 'Document Already Exist') {
                this.toastr.warning('Component Inventory Already Exist');
              }
            }
          );
        }
      }



    } else {
      this.toastr.warning( validatorArrays);


    }
  }

  cancel() {
       
    this.resetForm = this.addComp.reset();
    this.CancelDialog.emit(this.resetForm);
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.keyCode = 13;
    }
  }

  nameCapitalChange(event) {
    let name = this.nameCapitalize.nameCapitalChange(event);
    this.addComp.patchValue({
      Name: name
    });
  }

  emitNotesResult(event){
    this.addComp.patchValue({
      Notes: event
    });

  }
  editNotesDialog(i) {
       
    const notes = this.addComp.get('Notes').value;
    this.editNotesIndex = i;
    this.editNotesPopup = true;
    this.editedNotes = notes[i].Note;


  }
  deleteNotesDialog(i) {
       
    // this.confirmationService.confirm({
    //   message: 'Delete Confirmation',
    //   header: 'Are you sure want to Delete?',
    //   icon: 'fa fa-exclamation-triangle',
    //   accept: () => {
        this.addComp.getRawValue().Notes.splice(i, 1);
    //   },
    //   reject: () => { }
    // });


  }
  closeNotesEditDialog() {
       
    this.editNotesPopup = false;
    this.editedNotes = '';
    this.editNotesIndex = 0;

  }
  onSubmitNotesEdit() {
       
    if (this.editedNotes === undefined || this.editedNotes === null || this.editedNotes === '') {
      this.toastr.warning('Please Enter Notes');
    } else {
      let notesObject = {};
      const notes = this.addComp.get('Notes').value;
      const i = this.editNotesIndex;
      notesObject = {
        Note: this.editedNotes,
        Ip_address: this.ipAddress,
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date().toISOString()
      };
      notes[i] = notesObject;
      this.addComp.patchValue({
        Notes: notes
      });
      // this.onSubmit(this.chemicalFrom);
      this.editedNotes = '';
      this.editNotesIndex = 0;
      this.editNotesPopup = false;
    }
  }
}
