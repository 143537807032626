// File upload
export class Filedata {
    Id: string;
    File_id: string;
    Name: string;
    Url: string;
    Size: string;
    Is_deleted: boolean;
  }

// File upload
export class  Filedatamodel {
    Doc_Field_Type: string;
    Doc_List: Filedata[];
  }
