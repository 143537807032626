import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '../../../../../app.configuration';
import { SelectItem } from 'primeng/primeng';
import { CustomRoutingName } from '../../../../../app.custom-routing-name';
import { ValidationCheck } from '@app/shared/common/common-validation';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Response } from '../../../../../module/inventory/model/component-inventory.interface';
import { Filedatamodel } from '../../../../model/file-process.interface';
import { FilePatchComponent } from '../../../file-upload/file-upload.patch.component';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { yearCalculate } from '@app/shared/common/common-helper';
import { AuditFiles } from '../../../audit-File';
import { StorageConfiguration } from '../../../storage-setting/storage-configuration';
import { SettingHelper } from '../../../company-setting-helper';

// StorageConfiguration

@Component({
  selector: 'app-component-add-child',
  templateUrl: './component-add-child.component.html',
  styleUrls: ['./component-add-child.component.sass'],
  providers: [ConfirmationService, ValidationCheck, AuditFiles, SettingHelper, Filedatamodel, CustomRoutingName, FilePatchComponent, ToastBaseModel, StorageConfiguration]

})
export class ComponentAddChildComponent implements OnInit {


  ndcReq: boolean;
  todayDate: any;
  resetForm: any;
  childClose: void;
  ndcappend: any;
  component: any;
  activity: any;
  closePopup: boolean;
  minExpDate: any;
  @Input() compid: any;
  @Input() masterId: any;
  @Input() mode: any;
  @Input() copyInventoryChild;
  @Output() closeChild = new EventEmitter<any>();
  companySettings: any;
  result: string;
  id: string = this.compid;
  date: string;


  inventroyStatusSelectItem: SelectItem[]
  suppilerSelectItems: SelectItem[]
  currency: any;
  currencySymbol: string;
  currencyDigits: number;
  costperRound: any;
  costPerUnit: number;
  expireDate: any;
  yrRange: string;
  keyCode: number;
  ndcs = ['', '', ''];
  addInventory: FormGroup;
  accessuri: string;
  c_of_a = 'c_of_a';
  newRecord = 'NewRecord';
  referenceTypeName: 'componentadd';
  blockSpecial: RegExp = /^[^<>*!'"]+$/;
  showAddSupplier: boolean;
  userInfo: any;
  editNotesIndex: any;
  editNotesPopup: boolean;
  editedNotes: any;
  ipAddress: any;



  constructor(
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private dataService: DataService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService,
    private filePatch: FilePatchComponent,
    private customRoutingName: CustomRoutingName,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private yearRange: yearCalculate,
    private validationCheck: ValidationCheck,
    private auditFiles: AuditFiles,
    private storageConfiguration: StorageConfiguration,
    private SettingHelper: SettingHelper,
  ) {
    this.inventroyStatusSelectItem = [
      { label: 'Hold', value: 'Hold' },
      { label: 'Released', value: 'Released' },
      { label: 'Quarantine', value: 'Quarantine' }
    ];
  }

  ngOnInit() {

    this.spinnerService.show();
    const publicIp = require('public-ip');
    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.createForm();
    this.initialLoad();
    this.todayDate = new Date();
    if (this.copyInventoryChild !== undefined) {
      this.addInventory.patchValue({
        Child_name: this.copyInventoryChild.Child_name + ' - Copy',
        Sku: this.copyInventoryChild.Sku,
        Barcode: this.copyInventoryChild.Barcode,
        Suppliers: this.copyInventoryChild.Suppliers,
        Ndc: this.copyInventoryChild.Ndc,
        id: ''
      });
    }
    this.addInventory.patchValue({
      Status: 'Hold'
    });

    this.yrRange = this.yearRange.yrRange;
  }

  initialLoad() {
    this.dataService.getSingle(this.compid, this.configuration.componentInventory + '/').subscribe(
      (data: any) => {
        this.spinnerService.hide();
        this.companySettings = data.options.CompanySettings;
        this.currency = this.companySettings.Currency;
        this.currencySymbol = this.currency.Symbol + ' ';
        this.currencyDigits = this.currency.Decimal_digits;
        // this.suppilerSelectItems = this.companySettings.Suppliers;
        this.minExpDate = this.companySettings.MinExpDate_component;
        let minCompExpDate = this.companySettings.MinExpDate_component;
        if (this.companySettings.MinExpDate_component != 0) {
          this.minExpDate = true;

          this.addInventory.patchValue({ Minimum_expiration_date: minCompExpDate });
          this.expireDate = new Date();
          this.expireDate.setDate(this.expireDate.getDate() + minCompExpDate);
          let year = this.expireDate.getFullYear();
          this.expireDate.getFullYear(year);
          this.addInventory.patchValue({
            Expire_date: this.expireDate,
            Child_name: data.result.Name,
            Master_id: data.result.id,
            Master_name: data.result.Name,
          });
          this.addInventory.get('Minimum_expiration_date').setValidators([Validators.required]);
          this.addInventory.get('Minimum_expiration_date').updateValueAndValidity();
        } else {
          this.addInventory.get('Minimum_expiration_date').setValidators([Validators.nullValidator]);
          this.addInventory.get('Minimum_expiration_date').updateValueAndValidity();
          this.expireDate = new Date();
          this.minExpDate = false;

        }
        this.addInventory.patchValue({
          Minimum_expiration_date: minCompExpDate,
          Child_name: data.result.Name
        });
        this.expCalc();
        this.bindsupportingOptions(this.companySettings);
        this.ndcChange();
        // this.activity = component.result.Activity_log;
        this.component = data.childResult;
        // appending last ndc  in add
        if (this.component.length > 0) {
          this.ndcappend = this.component[this.component.length - 1];
          this.ndcs[0] = this.ndcappend.Ndc.split('-')[0] === undefined ? '' : this.ndcappend.Ndc.split('-')[0];
          this.ndcs[1] = this.ndcappend.Ndc.split('-')[1] === undefined ? '' : this.ndcappend.Ndc.split('-')[1];
          this.ndcs[2] = this.ndcappend.Ndc.split('-')[2] === undefined ? '' : this.ndcappend.Ndc.split('-')[2];
          var ndcValue = this.ndcs[0] + '-' + this.ndcs[1] + '-' + this.ndcs[2];
          this.addInventory.patchValue({ Ndc: ndcValue });
        }

      })
  }

  bindsupportingOptions(component: any) {
    this.suppilerSelectItems = this.SettingHelper.getStorageCondition(component.Suppliers.Value, true, '');

  }
  bindDropDown(array: any[], ddType: string): any[] {
    var dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {

        case 'suppliers':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
      }
    });
    return dropDownItem;
  }




  createForm() {
    this.addInventory = this.fb.group({
      Master_id: [this.masterId],
      Master_name: [''],
      // Child_id: [''],
      Sku: [''],
      Child_name: ['', Validators.required],
      Expire_date: ['', Validators.required],
      Lot_number: [0, Validators.required],
      How_to_supply: [0,],
      Bulk_container: [0, Validators.required],
      Initial_quantity: [0, Validators.required],
      Quantity_onhand: [0],
      Allocated_quantity: [0],
      In_process_quantity: [0],
      Used_quantity: [0],
      Ndc: [''],
      Status: ['', Validators.required],
      Cost_per_container: [, Validators.required],
      Cost_per_unit: [0, Validators.required],
      Received_date: ['', Validators.required],
      File_data: Filedatamodel,
      Suppliers: ['', Validators.required],
      Barcode: [''],
      Activity_log: this.fb.array([]),
      Quantity_per_container: ['', Validators.required],
      Containers_purchased: ['', Validators.required],
      Notes: [],
      Minimum_expiration_date: [''],
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Is_name_changed: true,
      Is_name_disable: true,
      id: [''],
      Kind: [''],
      Is_active: true,
      Is_deleted: false,
      Is_ndc: false,
      Quantity_changes: [],
      Is_expired: false,
      Child_barcode: [''],
      Label_url: []
    });

  }

  NdcChange(type, event) {
    var value = event.target['value'].toString();

    switch (type) {
      case 'company':
        this.ndcs[0] = ('000000' + value).slice(-5);
        break;
      case 'product':
        this.ndcs[1] = ('000000' + value).slice(-4);
        break;
      case 'code':
        this.ndcs[2] = ('000000' + value).slice(-2);
        break;
    }
    var ndcValue = this.ndcs[0] + '-' + this.ndcs[1] + '-' + this.ndcs[2];
    this.addInventory.patchValue({ Ndc: ndcValue });
  }
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.keyCode = 13;
    }
  }

  submitChild(data) {
    const invalid = [];
    const controls = this.addInventory.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    let validatorArrays;
    let suppliersArray;

    validatorArrays = this.validationCheck.getValidation(invalid);


    if (invalid.length == 0) {


      // if (suppliersArray) {
      //   
      this.spinnerService.show();
      data.value.Is_active = true;
      this.date = new Date().toISOString();
      data.value.Created_info.Date = this.date;
      data.value.Created_info.By = this.userInfo[0].id
      data.value.Created_info.Name = this.userInfo[0].name

      data.value.Initial_quantity = +data.value.Initial_quantity;
      data.value.Quantity_onhand = data.value.Initial_quantity;
      data.value.Cost_per_container = data.value.Cost_per_container;
      data.value.Quantity_changes = [];
      data.value.Kind = 'component_child'
      let activeModel = [];
      activeModel = this.auditFiles.newCreateActivity('Name', data.value.Child_name);
      data.value.Activity_log = activeModel;

      this.dataService.add(this.configuration.componentChildInventory, data.value).subscribe(
        (data: Response) => {
          this.spinnerService.hide();
          this.result = data.message.result;
          if (this.result === 'Document created successfully!!') {
            if (this.mode === 'MasterFormulation') {
              this.closePopup = false;
              this.closeChild.emit(this.closePopup);
              this.addInventory.reset();
            } else {
              //this.router.navigate([this.customRoutingName.component_master_child + this.compid], { queryParams: { childId: data.message.childId } });
              this.router.navigate([this.customRoutingName.component_master_child + data.message.id]);
            }
            this.toastr.success(this.toastMessage.ChildInventory + ' ' + this.toastMessage.Create);
          } else {
            this.toastr.warning(this.result);
          }

        });
    } else {
      this.toastr.warning(validatorArrays);
    }
  }
  expCalc() {


    let Minimum_expiration_date = this.addInventory.get('Minimum_expiration_date').value;
    let minexpDate = Minimum_expiration_date === '' || Minimum_expiration_date === null || Minimum_expiration_date === undefined ? 0 : Minimum_expiration_date;
    // this.chemicalChildForm.patchValue({ Minimum_expiration_date:minexpDate });
    this.expireDate = new Date();
    const expirationDate = parseInt(this.expireDate.getDate()) + parseInt(minexpDate);
    this.expireDate.setDate(expirationDate);
    // this.expireDate.setDate(this.expireDate.getDate() + minexpDate);
    let year = this.expireDate.getFullYear();
    this.expireDate.getFullYear(year);
    this.addInventory.patchValue({ Expire_date: this.expireDate });

  }
  calculateCostperunit(addInventory) {
    //   
    this.costPerUnit = 0;
    var initialQty = (addInventory.get('Containers_purchased').value * addInventory.get('Quantity_per_container').value);
    var CostPerContainter = addInventory.get('Cost_per_container').value;
    var quantity = addInventory.get('Quantity_per_container').value
    if (CostPerContainter > 0) {
      this.costPerUnit = CostPerContainter / quantity;
      this.costPerUnit = this.costPerUnit != Infinity && this.costPerUnit != NaN && this.costPerUnit != null ? this.costPerUnit : 0;
      this.costperRound = this.precisionRound(this.costPerUnit, this.currencyDigits);

    }
    this.addInventory.patchValue({
      Cost_per_unit: this.costperRound,
      Initial_quantity: initialQty
    });

  }
  precisionRound(number, precision) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;

  }

  receiveFileUploadResponse($event) {

    var getfileresult = this.filePatch.genericFilePatch($event, this.addInventory);
    this.addInventory.patchValue({ File_data: getfileresult });
  }
  cancel() {
    this.resetForm = this.addInventory.reset();
    this.closeChild.emit(this.resetForm)
  }

  ndcChange() {
    if (!this.addInventory.get('Is_ndc').value) {
      this.ndcReq = true;
      this.addInventory.get('Barcode').clearValidators();
      this.addInventory.get('Barcode').updateValueAndValidity();
      this.addInventory.get('Ndc').setValidators([Validators.required]);
      this.addInventory.get('Ndc').updateValueAndValidity();
    } else {
      this.ndcReq = false;
      this.addInventory.get('Barcode').setValidators([Validators.required]);
      this.addInventory.get('Barcode').updateValueAndValidity();
      this.addInventory.get('Ndc').clearValidators();
      this.addInventory.get('Ndc').updateValueAndValidity();
      this.addInventory.patchValue({
        Ndc: ''
      });
    }
  }

  /**ADD SUPPLIERS */
  showAddSuppliers() {
    this.showAddSupplier = true;
  }

  // http://192.168.0.100:820/api/CompanySettings/FieldValues?value=sdfs&fieldName=Suppliers
  addSupliers(data, fieldName) {
    this.spinnerService.show();
    this.dataService.update('', this.configuration.settingsApi + 'FieldValues?value=' + data.value + '&fieldName=' + fieldName, '')
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.initialLoad();
        this.showAddSupplier = false;
      });
  }

  cancelSuppliers() {
    this.showAddSupplier = false;
  }

  emitNotesResult(event) {
    this.addInventory.patchValue({
      Notes: event
    });

  }
  editNotesDialog(i) {

    const notes = this.addInventory.get('Notes').value;
    this.editNotesIndex = i;
    this.editNotesPopup = true;
    this.editedNotes = notes[i].Note;


  }
  deleteNotesDialog(i) {

    this.confirmationService.confirm({
      message: 'Delete Confirmation',
      header: 'Are you sure want to Delete?',
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.addInventory.getRawValue().Notes.splice(i, 1);
      },
      reject: () => { }
    });


  }
  closeNotesEditDialog() {

    this.editNotesPopup = false;
    this.editedNotes = '';
    this.editNotesIndex = 0;

  }
  onSubmitNotesEdit() {

    if (this.editedNotes === undefined || this.editedNotes === null || this.editedNotes === '') {
      this.toastr.warning('Please Enter Notes');
    } else {
      let notesObject = {};
      const notes = this.addInventory.get('Notes').value;
      const i = this.editNotesIndex;
      notesObject = {
        Note: this.editedNotes,
        Ip_address: this.ipAddress,
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date().toISOString()
      };
      notes[i] = notesObject;
      this.addInventory.patchValue({
        Notes: notes
      });
      // this.onSubmit(this.chemicalFrom);
      this.editedNotes = '';
      this.editNotesIndex = 0;
      this.editNotesPopup = false;
    }
  }

}
