import { Component, OnInit } from '@angular/core';
import { DataService } from '../../shared/service/data-service';
import { Configuration } from '../../app.configuration';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ForceLogInResponse, ForceLoginInfo, DeviceDetails } from '../model/user-login.interface';
import { Ng2DeviceService } from 'ng2-device-detector';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';




@Component({
  selector: 'app-force-login',
  templateUrl: './force-login.component.html',
  styleUrls: ['./force-login.component.sass']
})
export class ForceLoginComponent implements OnInit {

  deviceInfo: any;
  ipAddress: any;
  userId: any;
  Responsevalue: any;
  UserInfo = [];
  userInfo: any;
  Year: number = new Date().getFullYear();

  constructor(
    private router: Router,
    private dataService: DataService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService,
    private deviceService: Ng2DeviceService,
    private storageConfiguration: StorageConfiguration
   ) { }

  ngOnInit() {
    const publicIp = require('public-ip');

    publicIp.v4().then(ip => {
       this.ipAddress = ip;
     });
  } 

  forcelogin() {
    this.spinnerService.show();
    // this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    let password = this.storageConfiguration.sessionGetItem(this.storageConfiguration.password);

     this.deviceInfo = this.deviceService.getDeviceInfo();
    const devicedetails: DeviceDetails = {
      Ip_address: this.ipAddress,
      Browser: this.deviceInfo.browser,
      Browser_version: this.deviceInfo.browser_version,
      Device: this.deviceInfo.device,
      OS: this.deviceInfo.os,
      Os_version: this.deviceInfo.os_version,
    };
    const forcelogininfo: ForceLoginInfo = {
      User_id: this.storageConfiguration.sessionGetItem(this.storageConfiguration.id),
      User_name: this.storageConfiguration.sessionGetItem(this.storageConfiguration.User_name),
      // Password: localStorage.getItem('Password'),
      Password: password,
      Device_details: devicedetails
    };
    

    this.dataService.add(this.configuration.forceLogIn, forcelogininfo).subscribe(
      (response: ForceLogInResponse) => {
        
        //this.router.navigate(['/dashboard/dashboard-sale']);

         this.Responsevalue = response;

        this.UserInfo.push({
          id: this.Responsevalue.message.id,
          E_mail: this.Responsevalue.message.username,
          name: this.Responsevalue.message.firstname + ' ' + this.Responsevalue.message.lastname,
          role: this.Responsevalue.message.role.rolename,
          token: this.Responsevalue.message.token,
          permissionSetting: this.Responsevalue.message.permissionsettings,
          pharmacy_type: this.storageConfiguration.sessionGetItem(this.storageConfiguration.pharmacy_type)
        });
        if (this.UserInfo[0].pharmacy_type == 'A PHARMACY') {
          this.router.navigate(["/dashboard/dashboard-production"]);
        } else
          this.router.navigate(["/dashboard/dashboard-sale"]);
        this.storageConfiguration.sessionSetItem(this.storageConfiguration.token, this.Responsevalue.message.token);
        this.storageConfiguration.sessionSetItem(this.storageConfiguration.user_Info, JSON.stringify(this.UserInfo));
        this.storageConfiguration.sessionSetItem(this.storageConfiguration.currentlogin, this.Responsevalue.message.currentlogindetail.Login_history_id);
        this.spinnerService.hide();


 
      }, err => {
       });
  }

}
