import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-report-data-table',
  templateUrl: './report-data-table.component.html',
  styleUrls: ['./report-data-table.component.sass']
})
export class ReportDataTableComponent implements OnInit {

  constructor() { }

  @Input() labelMessage: boolean;
  @Input() dataTableResults: any;
  @Input() resultTable: any;
  @Input() label: any;

  ngOnInit() {
  }

}
