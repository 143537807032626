// import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';
import { EncryptDecrypt } from '../../../shared/common/storage-setting/encryptdecrypt';

@Injectable({
    providedIn: 'root'
})

export class StorageConfiguration {

    constructor( private encryptDecrypt: EncryptDecrypt) {}

    public token = 'token';
    public id = 'id';
    public name = 'name';
    public role = 'role';
    public currentlogin = 'currentlogin';
    public password = 'password';
    public user_Info = 'user_Info';
    public updatemessage = 'updatemessage';
    public User_name = 'User_name';
    public isloggedIn = 'isloggedIn';
    public startDate = 'startDate';
    public endDate = 'endDate';
    public checkChemicalLabelGeneration = 'checkChemicalLabelGeneration';
    public checkComponentLabelGeneration = 'checkComponentLabelGeneration';
    public checkEquipmentLabelGeneration = 'checkEquipmentLabelGeneration';
    public checkLocationLabelGeneration = 'checkLocationLabelGeneration';
    public checkBatchLabelGeneration = 'checkBatchLabelGeneration';
    public labelStorage = 'labelStorage';
    public labelClient = 'labelClient';
    public masterFormStatus = 'masterFormStatus';
    public masterFormStatusFacility = 'masterFormStatusFacility';
    public pharmacy_type = 'pharmacy_type'


    sessionSetItem(key, value) {
        localStorage.setItem(this.encryptDecrypt.encrypt(key), this.encryptDecrypt.encrypt(value));
    }

    sessionGetItem(key) {
        let decryptedValue = localStorage.getItem(this.encryptDecrypt.encrypt(key));
        return decryptedValue != null ? this.encryptDecrypt.decrypt(decryptedValue) : null;
    }

    sessionRemoveItem(key) {
        localStorage.removeItem(this.encryptDecrypt.encrypt(key));
    }


}
