
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import {SimpleTimer} from 'ng2-simple-timer';
import { TimerDataService } from "../../service/timer-service";


@Component({
  selector: 'app-timer',
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.sass']
})
export class TimerComponent implements OnInit {

  //ElapsedCounter
  Ecounter = 0;
  
  //Next Process Conter
  Ncounter = 0;

	timerId: string;
  timerbutton = 'Subscribe';

  @Input() resumeTimeadd : number = 0;
  @Input() timerType: string;  
  @Input() staticTime: number;  
  @Output() getCounterValue = new EventEmitter<any>();

  

   minutesDisplay: number = 0;
   hoursDisplay: number = 0;
   secondsDisplay: number = 0;

  constructor(private st: SimpleTimer,  private data: TimerDataService) { 

    this.data.currentMessage.subscribe(message => this.Ncounter = message)
  }

  ngOnInit(): void {

       
    
    this.st.newTimer(this.timerType ,1);

    if(this.timerType == 'timeelapsed')
    {
      this.ESubscribeTimer();
    }
    if(this.timerType == 'nexProcess')
    {
      this.nextSubscribeTimer();
    }
    if(this.timerType == 'staticTime'){
      this.StaticTimer();
    }
   
  }


  nextSubscribeTimer() {	
      // Subscribe if timer Id is undefined
      this.Ncounter = this.resumeTimeadd+this.Ncounter
			this.timerId = this.st.subscribe(this.timerType, () => {        
         this.Ncounter++;
         this.secondsDisplay = this.getSeconds(this.Ncounter);         
         this.minutesDisplay = this.getMinutes(this.Ncounter);
         this.hoursDisplay = this.getHours(this.Ncounter);

         this.getCounterValue.emit({
          timerType : this.timerType,
          counter : this.Ncounter
         })
      });					
  }


  ESubscribeTimer() {	
    // Subscribe if timer Id is undefined
    this.Ecounter = this.resumeTimeadd+this.Ecounter
    this.timerId = this.st.subscribe(this.timerType, () => {        
       this.Ecounter++;
       this.secondsDisplay = this.getSeconds(this.Ecounter);         
       this.minutesDisplay = this.getMinutes(this.Ecounter);
       this.hoursDisplay = this.getHours(this.Ecounter);

       this.getCounterValue.emit({
        timerType : this.timerType,
        counter : this.Ecounter
       })
    });					
}

StaticTimer(){
  
  this.secondsDisplay = this.getSeconds(this.staticTime);         
  this.minutesDisplay = this.getMinutes(this.staticTime);
  this.hoursDisplay = this.getHours(this.staticTime);
  
}


private getSeconds(ticks: number) {
    return this.pad(ticks % 60);
}

private getMinutes(ticks: number) {
     return this.pad((Math.floor(ticks / 60)) % 60);
}

private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
}

private pad(digit: any) { 
    return digit <= 9 ? '0' + digit : digit;
}
}