import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
// import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { HttpClient } from '@angular/common/http';


import { AuthRoutingModule } from './auth-routing.module';
import { UserLoginComponent } from './user-login/user-login.component';
import { FileUploadModule } from 'primeng/fileupload';
import { ShowHidePasswordModule } from 'ngx-show-hide-password';
import { Ng2DeviceService } from 'ng2-device-detector';
import { ForceLoginComponent } from './force-login/force-login.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { LoginResetComponent } from './user-reset/user-reset.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    AuthRoutingModule,
    FileUploadModule,
    ShowHidePasswordModule.forRoot(),
    Ng4LoadingSpinnerModule.forRoot()


  ],
  declarations: [UserLoginComponent, ForceLoginComponent, ForgotPasswordComponent, LoginResetComponent, ChangePasswordComponent],
  providers: [HttpClient, Ng2DeviceService]
})
export class AuthModule { }
