import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { yearCalculate } from '../../../shared/common/common-helper';
import { Configuration } from '../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../../../shared/service/data-service';

@Component({
  selector: 'app-common-report-generate',
  templateUrl: './common-report-generate.component.html',
  styleUrls: ['./common-report-generate.component.sass']
})

export class CommonReportGenerateComponent implements OnInit {
  constructor(
    private toastr: ToastrService,
    private yearRange: yearCalculate,
    private dataService: DataService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService,

  ) { }


  @Input() label: any;
  @Input() reportGenerate: boolean;
  @Input() selectItems: any;
  @Output() emitChildRecordEvent = new EventEmitter<any>();
  @Output() CancelDialog = new EventEmitter<any>();

  yrRange: any;
  toDateExcel: any;
  toDate: any;
  fromDateExcel: any;
  fieldValueArray = [];
  resultReportData: any;

  ngOnInit() {
    this.toDateExcel = '';
    this.fromDateExcel = '';
    this.yrRange = this.yearRange.yrRange;
  }


  cancelReport() {
    this.CancelDialog.emit(false);
  }

  fromDate() {
    this.toDate = new Date(this.fromDateExcel);
  }

  submitReport() {
       
    let dateFilter = {};
    const toDate = this.toDateExcel === '' ? '' : this.toDateExcel.toISOString().slice(0, 10);
    const fromDate = this.fromDateExcel === '' ? '' : this.fromDateExcel.toISOString().slice(0, 10);
    let dateChecker = true;
    if (new Date(this.fromDateExcel).getTime() > new Date(this.toDateExcel).getTime()) {
      dateChecker = false;
    }
    if ((this.fromDateExcel !== '' && this.toDateExcel !== '' ||
      this.fromDateExcel === '' && this.toDateExcel === '' || this.fromDateExcel !== '' && this.toDateExcel === '') && dateChecker) {
      dateFilter = {
        from_date: fromDate,
        to_date: toDate,
      };
      if (this.fieldValueArray.length === 0) {
        this.toastr.warning('Select Filter Values');
        this.reportGenerate = true;
      } else {
        if (this.label === 'EQUIPMENT CLASSIFICATION') {
          this.reportService(dateFilter, 'Equipment');
        } else if (this.label === 'EM REPORT') {
          this.reportService(dateFilter, 'EMReport');
        } else if (this.label === 'FACILITY') {
          this.reportService(dateFilter, 'Facility');
        } else if (this.label === 'USER') {
          this.reportService(dateFilter, 'UserReport');

        }
      }
    } else {
      this.toastr.warning('Check From Date');
    }
  }

  filterChange(event, key) {
    const fieldValue = this.selectItems.filter(a => a.value === event.value);
    this.filterArrayCollection(key, fieldValue[0].value);
  }


  filterArrayCollection(Name, Value) {
    this.fieldValueArray = [];
    let obj = {};
    obj = {
      'Field_name': Name,
      'Field_value': Value,
      'Field_type': 'string'
    };
    this.fieldValueArray.push(obj);
  }

  reportService(date, key) {
    const model = this.postModelConstruct(date);

       
    this.spinnerService.show();
    this.dataService.add(this.configuration.report + '/' + key, model).subscribe(
      (data: any) => {
        this.spinnerService.hide();

        this.resultReportData = data.result.result;
        this.emitChildRecordEvent.emit(this.resultReportData);
      });
  }
  postModelConstruct(value) {
    let model = {};
    const dateRange = value.from_date === '' ? '' : 'Created_info.Date';
    model = {
      Kind: '',
      Daterange_with: dateRange,
      Start_date: value.from_date,
      End_date: value.to_date,
      Range: '',
      Fields: this.fieldValueArray,
      Sort_with: '',
      Sort_by: '',
    };
    return model;

  }
   


}
