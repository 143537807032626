import { Component,Input, Output,EventEmitter,OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { yearCalculate } from '../../../shared/common/common-helper';

@Component({
  selector: 'app-excel-generate',
  templateUrl: './excel-generate.component.html',
  styleUrls: ['./excel-generate.component.sass']
})
export class ExcelGenerateComponent implements OnInit {

  
  constructor(

    private toastr: ToastrService,
    private yearRange: yearCalculate,


  ) { }
  @Input() reportExcel: any;
  @Output() emitChildRecordEvent = new EventEmitter<any>();  
  @Output() CancelDialog = new EventEmitter<any>();  
  yrRange: any;


  toDateExcel :any;
  toDate :any;

  fromDateExcel :any;
  ngOnInit() {
    this.toDateExcel =  "";
    this.fromDateExcel = "";
    this.yrRange = this.yearRange.yrRange;

  }

  cancelReport() {
    this.CancelDialog.emit(false);

  }

  fromDate() {
    this.toDate = new Date(this.fromDateExcel);
  }

  exportCSV(){
    var dateFilter ={}
      var toDate = this.toDateExcel === "" ? "" : this.toDateExcel.toISOString().slice(0, 10);
   var fromDate = this.fromDateExcel === "" ? "" : this.fromDateExcel.toISOString().slice(0, 10);
   var dateChecker = true;
   if (new Date(this.fromDateExcel).getTime() > new Date(this.toDateExcel).getTime()) {
     dateChecker = false;
   }

   if ((this.fromDateExcel != "" && this.toDateExcel != "" || this.fromDateExcel === "" && this.toDateExcel === "" || this.fromDateExcel != "" && this.toDateExcel === "") && dateChecker) {
        dateFilter={
      from_date:fromDate,
      to_date:toDate,
    }
    this.emitChildRecordEvent.emit(dateFilter);  
    this.reportExcel = false;




  } else {
    this.toastr.warning("Check From Date");
  }

 
  }

}