import { Injectable } from '@angular/core';
import { NgxUiLoaderConfig, NgxUiLoaderService } from "ngx-ui-loader";

@Injectable({
  providedIn: 'root'
})
export class NgxUiLoaderManualService {

  config: NgxUiLoaderConfig;
  
  /**
   * Constructor
   * @param ngxUiLoaderService
   */

  constructor(private ngxUiLoaderService: NgxUiLoaderService) {
    this.config = {
      "bgsColor": "transparent",
      "bgsOpacity": 0.5,
      "bgsPosition": "center-center",
      "bgsSize": 60,
      "bgsType": "rectangle-bounce",
      "blur": 5,
      "fgsColor": "#666ffc",
      "fgsPosition": "center-center",
      "fgsSize": 60,
      "fgsType": "rectangle-bounce",
      // "fgsType": "square-loader",
      "gap": 24,
      "logoPosition": "center-center",
      "logoSize": 160,
      "logoUrl": "",
      "masterLoaderId": "master",
      "overlayBorderRadius": "0",
      "overlayColor": "white",
      "pbColor": "#666ffc",
      "pbDirection": "ltr",
      "pbThickness": 3,
      "hasProgressBar": true,
      "text": "Please Wait",
      "textColor": "black",
      "textPosition": "center-center",
      "threshold": 500
    };
  }
}
