import { Component, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/primeng';


@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.sass']
})
export class LayoutComponent implements OnInit {


  constructor() { }
  title = 'app';
  menuActive: boolean;
  createdBy: string;
  modifiedby: SelectItem[];
  Name = [];
  id: any;
  Items = [];
  Year: number = new Date().getFullYear();
  onMenuButtonClick(event: Event) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }
  onActivate(event) {
    window.scroll(0, 0);
  }
  ngOnInit() {
    
  }

}
