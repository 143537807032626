import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { SelectItem } from 'primeng/primeng';
import { DataService } from '@app/shared/service/data-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ChemicalMasterResponse, ChildInventory } from '../../../../../module/inventory/model/chemical-inventory.interface';
import { Filedatamodel } from '../../../../model/file-process.interface';
import { CustomRoutingName } from '../../../../../app.custom-routing-name'
import { sortingArray, yearCalculate } from '@app/shared/common/common-helper'
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { ValidationCheck } from '@app/shared/common/common-validation';
import { AuditFiles } from '../../../audit-File';
import { StorageConfiguration } from '../../../storage-setting/storage-configuration';
import { SettingHelper } from '../../../company-setting-helper';
import { FilePatchComponent } from '@app/shared/common/file-upload/file-upload.patch.component';
import { Configuration } from '@app/app.configuration';

@Component({
  selector: 'app-chemical-add-child',
  templateUrl: './chemical-add-child.component.html',
  styleUrls: ['./chemical-add-child.component.sass'],
  providers: [ConfirmationService,
    sortingArray]

})
export class ChemicalAddChildComponent implements OnInit {

  todayDate: any;
  resetForm: any;
  closePopup: boolean;
  result: any;
  responseBody: any;
  date: string;
  potencyRequired: boolean;
  @Input() masterName: string;
  @Input() chemid: any;
  @Input() mode: any;
  @Input() copyInventoryChild;
  @Output() closeChild = new EventEmitter<any>();
  ndcs = ['', '', ''];
  costperRound: any;
  costPerUnit: number;
  releaseCriteriaList: any;
  ndcVal: string;
  ndcappend: ChildInventory;
  child_inventory: ChildInventory[] = [];
  methodName: string;
  id: string;
  accessuri: any;
  expireDate: any;
  minExpDate: boolean;
  releaseCriteriaResult: any;
  companySettings: any;
  HandlinginstructionArray: any;
  currencyDigits: any;
  currencySymbol: string;
  currency: any;
  yrRange: string;
  keyCode: number;
  masterUnit: string;
  chemicalChildForm: FormGroup;
  inventroyStatusSelectItem: SelectItem[];
  ChemicalMasterInventory: any;
  activity: any = [];
  storageSelectItem: SelectItem[];
  typeSelectItem: SelectItem[];
  StrengthUnitSelectItem: SelectItem[];
  supplierSelectItem: SelectItem[];
  controlSubstanceSelectItem: SelectItem[];
  HandleClassificationResult: string[] = [];
  newRecord = 'NewRecord';
  referenceTypeName = 'chemicalchild';
  sds = 'sds';
  c_of_a = 'c_of_a';
  showAddSupplier: boolean;
  ndcReq: boolean;
  blockSpecial: RegExp = /^[^<>*!'"]+$/;
  userInfo: any;
  editNotesIndex: any;
  editNotesPopup: boolean;
  editedNotes: string;
  ipAddress: any;


  constructor(
    public confirmationService: ConfirmationService,
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private configuration: Configuration,
    private filePatch: FilePatchComponent,
    private customRoutingName: CustomRoutingName,
    private sortingArray: sortingArray,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private yearRange: yearCalculate,
    private validationCheck: ValidationCheck,
    private auditFiles: AuditFiles,
    private storageConfiguration: StorageConfiguration,
    private settingHelper: SettingHelper,

  ) {
    this.inventroyStatusSelectItem = [
      { label: 'Hold', value: 'Hold' },
      { label: 'Released', value: 'Released' },
      { label: 'Quarantine', value: 'Quarantine' }
    ];
  }

  /**INITIALIZE THE DOCUMENT */
  ngOnInit() {
    const publicIp = require('public-ip');
    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });
    this.spinnerService.show();
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.createForm();
    this.getRecordById();
    this.yrRange = this.yearRange.yrRange;
    this.todayDate = new Date();
    if (this.copyInventoryChild !== undefined) {
      this.ndcs[0] = this.copyInventoryChild.Ndc.split('-')[0];
      this.ndcs[1] = this.copyInventoryChild.Ndc.split('-')[1];
      this.ndcs[2] = this.copyInventoryChild.Ndc.split('-')[2];
      var ndcValue = this.ndcs[0] + '-' + this.ndcs[1] + '-' + this.ndcs[2];
      this.chemicalChildForm.patchValue({
        Child_name: this.copyInventoryChild.Child_name + '- Copy',
        Sku: this.copyInventoryChild.Sku,
        Barcode: this.copyInventoryChild.Barcode,
        Suppliers: this.copyInventoryChild.Suppliers,
        Ndc: ndcValue,
        Container_type: this.copyInventoryChild.Container_type,
        id: '',
        Minimum_expiration_date: this.copyInventoryChild.MinExpDate_chemical,
        Expire_date: new Date(this.copyInventoryChild.Expire_date),
        Cost_per_container: 0,
        Cost_per_unit: 0
      });
    }

  }

  /** INITIALIZE THE FORM */
  createForm() {
    this.chemicalChildForm = this.fb.group({
      Master_id: this.chemid,
      Master_name: this.masterName,
      // Child_id: [''],
      Sku: [''],
      Child_name: ['', Validators.required],
      Is_ndc: false,
      Expire_date: ['', Validators.required],
      Lot_number: [0, Validators.required],
      File_data: Filedatamodel,
      Container_type: ['', Validators.required],
      Quantity_onhand: this.fb.group({
        Units: [0],
        Type: ['']
      }),
      Initial_quantity: this.fb.group({
        Units: [0],
        Type: ['']
      }),
      Allocated_quantity: 0,
      In_process_quantity: 0,
      Used_quantity: 0,
      Minimum_expiration_date: [''],

      Handling_instructions: [''],
      Api: true,
      Potency: 100,
      Ndc: ['', Validators.required],
      Status: ['', Validators.required],
      Suppliers: ['', Validators.required],
      Cost_per_container: [0, Validators.required],
      Cost_per_unit: [0],
      Received_date: ['', Validators.required],
      Barcode: [''],
      Activity_log: this.fb.array([]),
      Quantity_per_container: this.fb.group({
        Units: ['', Validators.required],
        Type: [this.masterUnit = this.masterUnit === null ? '' : this.masterUnit]
      }),

      // Quantity_per_container: this.fb.group({
      //   Type: [''],
      //   Units: ['', Validators.required]
      // }),

      Containers_purchased: [0, Validators.required],
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      id: [''],
      Kind: ['chemical_child'],
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Is_active: true,
      Is_deleted: false,
      Quantity_changes: [],
      Is_name_changed: true,
      Is_name_disable: true,
      Notes: [],
      Is_expired: false,
      Child_barcode: [''],
      Label_url: []
    });
  }


  /**GET RECORD BY ID */
  getRecordById() {
    this.dataService.getSingle<ChemicalMasterResponse>(this.chemid, this.configuration.chemicalInventory + '/').subscribe(
      (data: any) => (this.ChemicalMasterInventory = data),
      error => () => {

        this.spinnerService.hide();
      },
      () => {
        this.spinnerService.hide();
        this.currency = this.ChemicalMasterInventory.options.CompanySettings.Currency;
        this.currencySymbol = this.currency.Symbol + ' ';
        this.currencyDigits = this.currency.Decimal_digits;
        this.companySettings = this.ChemicalMasterInventory.options.CompanySettings;
        this.releaseCriteriaResult = this.ChemicalMasterInventory.result.Release_criteria;

        this.ndcChange();
        if (this.companySettings.MinExpDate_chemical != 0) {
          this.minExpDate = true;
          let minexpDate = this.companySettings.MinExpDate_chemical;
          this.chemicalChildForm.patchValue({ Minimum_expiration_date: minexpDate });
          this.expireDate = new Date();
          this.expireDate.setDate(this.expireDate.getDate() + minexpDate);
          let year = this.expireDate.getFullYear();
          this.expireDate.getFullYear(year);
          this.chemicalChildForm.patchValue({
            Expire_date: this.expireDate,
            Child_name: this.ChemicalMasterInventory.result.Name,
            Api: this.ChemicalMasterInventory.result.Api
          });
          this.chemicalChildForm.get('Minimum_expiration_date').setValidators([Validators.required]);
          this.chemicalChildForm.get('Minimum_expiration_date').updateValueAndValidity();
        } else {
          this.chemicalChildForm.get('Minimum_expiration_date').setValidators([Validators.nullValidator]);
          this.chemicalChildForm.get('Minimum_expiration_date').updateValueAndValidity();
          this.expireDate = new Date();
          this.minExpDate = false;

        }
        this.accessuri = this.ChemicalMasterInventory.accessuri;
        this.ChemicalMasterInventory.result.Activity_log.forEach(element => {
          this.activity.push(element);
        });
        this.ndcChange();
        this.methodName = '';
        if (this.id != 'NewRecord') {

          this.bindsupportingOptions(this.ChemicalMasterInventory);
          this.methodName = this.ChemicalMasterInventory.result.Name;
          this.masterUnit = this.ChemicalMasterInventory.result.Unit;
          // this.child_inventory = this.ChemicalMasterInventory.result.Child_inventory;
          this.chemicalChildForm.patchValue({
            Child_name: this.methodName
          });
          if (this.child_inventory.length > 0) {
            this.ndcappend = this.child_inventory[this.child_inventory.length - 1];
            this.ndcs[0] = this.ndcappend.Ndc.split('-')[0] === undefined ? '' : this.ndcappend.Ndc.split('-')[0];
            this.ndcs[1] = this.ndcappend.Ndc.split('-')[1] === undefined ? '' : this.ndcappend.Ndc.split('-')[1];
            this.ndcs[2] = this.ndcappend.Ndc.split('-')[2] === undefined ? '' : this.ndcappend.Ndc.split('-')[2];
            var ndcValue =
              this.ndcs[0] + '-' + this.ndcs[1] + '-' + this.ndcs[2];
            this.chemicalChildForm.patchValue({
              Ndc: ndcValue,
            });
            this.ndcVal = ndcValue;

          }
        }



        this.spinnerService.hide();
      });
  }

  /**BINIDING OPTIONS */
  bindsupportingOptions(ChemicalMasterInventory: any) {
    //    

    this.HandlinginstructionArray = this.settingHelper.getReleaseCriteria(this.ChemicalMasterInventory.options.CompanySettings.Handling_instructions.Value, true, '')
    this.typeSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.ContainerType_label_size_settings.Value, true, '');
    this.supplierSelectItem = this.sortingArray.sorting(this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Suppliers.Value, true, ''));
    this.StrengthUnitSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Strength.Value, true, '');
    // this.StrengthUnitSelectItem = this.bindDropDown(ChemicalMasterInventory.options.CompanySettings.Strength.Value, 'strength');
    this.controlSubstanceSelectItem = this.bindDropDown(ChemicalMasterInventory.options.CompanySettings.Controlled_substance_type.Value, 'controlledSubstance');
    var releseList = [];
    var selectedReleaseC = [];
    this.releaseCriteriaList = releseList;
    this.addReleaseItem();
  }

  bindDropDown(array: any[], ddType: string): any[] {
    var dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case 'default':
          dropDownItem.push({
            label: a.Name,
            value: a.id
          });
          break;
        case 'storage':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'container':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'strength':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'supplier':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'controlledSubstance':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;

        case 'type':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
      }
    });
    return dropDownItem;
  }


  /**HELPER FUNCTION */
  addReleaseItem(): void {
    const releaseFGs = this.releaseCriteriaList.map(release =>
      this.fb.group(release)
    );
    const releaseFormArray = this.fb.array(releaseFGs);
  }

  apiPotentcy(event) {
    var a = event.target['value'];
    if (a)
      this.chemicalChildForm.controls['Potency'].setValidators(
        Validators.required
      );
    else
      this.chemicalChildForm.controls['Potency'].setValidators(
        Validators.nullValidator
      );
  }

  HandleClassificationEvent($event) {
    this.HandleClassificationResult = $event;
  }

  /**CALCULATE COST PER UNIT */
  calculateCostperunit(chemicalChildForm) {
    this.costPerUnit = 0;
    var initialQty = chemicalChildForm.get('Containers_purchased').value * chemicalChildForm.get('Quantity_per_container.Units').value;
    var CostPerContainter = chemicalChildForm.get('Cost_per_container').value;
    var quantity = chemicalChildForm.get('Quantity_per_container.Units').value;

    if (CostPerContainter > 0) {
      this.costPerUnit = CostPerContainter / quantity;
      this.costPerUnit = this.costPerUnit != Infinity && this.costPerUnit != NaN && this.costPerUnit != null ? this.costPerUnit : 0;
      var cost = this.costPerUnit;
      this.costperRound = this.precisionRound(cost, this.currencyDigits);
    }

    this.chemicalChildForm.patchValue({
      Cost_per_unit: this.costperRound
    });

    this.chemicalChildForm.controls['Initial_quantity'].patchValue({
      Value: initialQty
    });

    if (cost == undefined) {
      this.chemicalChildForm.patchValue({
        Cost_per_unit: 0
      });
    }
  }

  /**COST ROUND FUNCTION */
  precisionRound(number, precision) {
    var factor = Math.pow(10, precision);
    return Math.round(number * factor) / factor;
  }
  /**FILE UPLOAD FUNCTION */
  childReceiveFileUploadResponse($event) {
    //     
    let getfileresult = this.filePatch.genericFilePatch($event, this.chemicalChildForm);
    this.chemicalChildForm.patchValue({ File_data: getfileresult });
  }

  /**ENTER KEY DISABLE FOR SUBMIT FORM */
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.keyCode = 13;
    }
  }

  /**NDC CHANGE FUNCTION */
  NdcChange(type, event) {
    let value = event.target['value'].toString();

    switch (type) {
      case 'company':
        this.ndcs[0] = ('' + value).slice(-5);
        break;
      case 'product':
        this.ndcs[1] = ('' + value).slice(-4);
        break;
      case 'code':
        this.ndcs[2] = ('' + value).slice(-2);
        break;
    }

    let ndcValue = this.ndcs[0] + '-' + this.ndcs[1] + '-' + this.ndcs[2];
    this.chemicalChildForm.patchValue({
      Ndc: ndcValue
    });
  }



  /**EXPIRE DATE CALCULATION */
  expCalc() {


    let Minimum_expiration_date = this.chemicalChildForm.get('Minimum_expiration_date').value;
    const minexpDate = Minimum_expiration_date === '' || Minimum_expiration_date === null || Minimum_expiration_date === undefined ? 0 : Minimum_expiration_date;
    this.expireDate = new Date();
    const expirationDate = parseInt(this.expireDate.getDate()) + parseInt(minexpDate);
    this.expireDate.setDate(expirationDate);
    const year = this.expireDate.getFullYear();
    this.expireDate.getFullYear(year);
    this.chemicalChildForm.patchValue({ Expire_date: this.expireDate });
  }

  /**SUBMIT FORM */
  onchildSubmit(chemicalChildForm) {
    const invalid = [];
    const controls = this.chemicalChildForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
         invalid.push(name);
      }
    }

    let validatorArrays;
    validatorArrays = this.validationCheck.getValidation(invalid);
    if (invalid.length == 0) {
      if (this.keyCode != 13) {
        chemicalChildForm = chemicalChildForm.value;

        if (chemicalChildForm.Api && chemicalChildForm.Potency <= 0) {
          this.potencyRequired = true;
          this.spinnerService.hide();
          this.toastr.warning('Please Enter the potency value greater than 0');
          return;
        }
        if( this.chemicalChildForm.value.Is_ndc == false){
          if (this.ndcs[0] == '' || this.ndcs[1] == '' || this.ndcs[2] == '') {
            this.toastr.warning('Please Enter NDC values');
            return
          }
          if (this.ndcs[0].length < 5 || this.ndcs[1].length < 4 || this.ndcs[2].length < 2) {
            this.toastr.warning('Please Enter Valid NDC values');
            return
          }
        }
        this.date = new Date().toISOString();
        chemicalChildForm.Initial_quantity.Units = +chemicalChildForm.Initial_quantity.Units;
        chemicalChildForm.Quantity_onhand.Units = chemicalChildForm.Initial_quantity.Units;
        chemicalChildForm.Cost_per_container = +chemicalChildForm.Cost_per_container;
        chemicalChildForm.Created_info.Date = this.date;
        chemicalChildForm.Is_deleted = false;
        chemicalChildForm.Created_info.By = this.userInfo[0].id;
        chemicalChildForm.Created_info.Name = this.userInfo[0].name;
        chemicalChildForm.Handling_instructions = this.HandleClassificationResult;
        chemicalChildForm.Quantity_per_container.Type = this.masterUnit;
        chemicalChildForm.Quantity_onhand.Type = this.masterUnit;
        chemicalChildForm.Initial_quantity.Type = this.masterUnit;
        chemicalChildForm.Quantity_changes = [];
        chemicalChildForm.Label_url = chemicalChildForm.Label_url == null ? [] : chemicalChildForm.Label_url;
        let activeModel = [];
        activeModel = this.auditFiles.newCreateActivity('Child_name', chemicalChildForm.Child_name);
        chemicalChildForm.Activity_log = activeModel;

        this.dataService.add(this.configuration.chemicalChildInventory, chemicalChildForm).subscribe(
          (data: any) => (this.responseBody = data),
          error => {
            this.spinnerService.hide();
            // console.log("this.toastMessage.Wrong",this.toastMessage.Wrong);
            // console.log("this.responseBody.data.message",this.responseBody.data.message);

            // this.toastr.warning(this.responseBody.data.message);
            this.toastr.warning(error.error.message.result);

            // this.toastr.warning(this.toastMessage.Wrong);
          },

          () => {
            this.spinnerService.hide();
            this.result = this.responseBody.message.result;

            if (this.result === 'Document created successfully!!') {
              if (this.mode === 'MasterFormulation') {
                this.closePopup = false;
                this.closeChild.emit(this.closePopup);
                this.chemicalChildForm.reset();
              } else {
                //this.router.navigate([this.customRoutingName.chemical_child_list + this.chemid], { queryParams: { childId: this.responseBody.message.childId } });
                this.router.navigate([this.customRoutingName.chemical_child_list + this.responseBody.message.id]);
              }
              this.toastr.success(this.toastMessage.ChildInventory + ' ' + this.toastMessage.Create);
            } else {
              this.toastr.warning(this.result);
            }
          }
        );
      }
    } else {
      this.toastr.warning(validatorArrays);
    }
  }

  /**CLOSE POPUP */
  cancel() {
    this.resetForm = this.chemicalChildForm.reset();
    this.closeChild.emit(false);
    this.closePopup = false;
  }

  /**ADD SUPPLIERS */
  showAddSuppliers() {
    this.showAddSupplier = true;
  }

  // http://192.168.0.100:820/api/CompanySettings/FieldValues?value=sdfs&fieldName=Suppliers
  addSupliers(data, fieldName) {
    this.spinnerService.show();
    if (data.value == '') {
      this.spinnerService.hide();
      this.toastr.warning('Please enter the ' + fieldName);
      return false;
    }
    this.dataService.update('', this.configuration.settingsApi + 'FieldValues?value=' + data.value + '&fieldName=' + fieldName, '')
      .subscribe((response: any) => {
        this.spinnerService.hide();
        this.getRecordById();
        this.showAddSupplier = false;
      });
  }

  cancelSuppliers() {
    this.showAddSupplier = false;
  }

  /**NDC not available for this item */
  ndcChange() {
    if (!this.chemicalChildForm.get('Is_ndc').value) {
      this.ndcReq = true;
      this.chemicalChildForm.get('Barcode').clearValidators();
      this.chemicalChildForm.get('Barcode').updateValueAndValidity();
      this.chemicalChildForm.get('Ndc').setValidators([Validators.required]);
      this.chemicalChildForm.get('Ndc').updateValueAndValidity();
    } else {
      this.ndcReq = false;
      this.chemicalChildForm.get('Barcode').setValidators([Validators.required]);
      this.chemicalChildForm.get('Barcode').updateValueAndValidity();
      this.chemicalChildForm.get('Ndc').clearValidators();
      this.chemicalChildForm.get('Ndc').updateValueAndValidity();
      this.chemicalChildForm.patchValue({
        Ndc: ''
      });
    }
  }
  emitNotesResult(event) {
    this.chemicalChildForm.patchValue({
      Notes: event
    });

  }
  editNotesDialog(i) {

    const notes = this.chemicalChildForm.get('Notes').value;
    this.editNotesIndex = i;
    this.editNotesPopup = true;
    this.editedNotes = notes[i].Note;


  }
  deleteNotesDialog(i) {

    this.confirmationService.confirm({
      message: 'Delete Confirmation',
      header: 'Are you sure want to Delete?',
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.chemicalChildForm.getRawValue().Notes.splice(i, 1);
      },
      reject: () => { }
    });


  }
  closeNotesEditDialog() {

    this.editNotesPopup = false;
    this.editedNotes = '';
    this.editNotesIndex = 0;

  }
  onSubmitNotesEdit() {

    if (this.editedNotes === undefined || this.editedNotes === null || this.editedNotes === '') {
      this.toastr.warning('Please Enter Notes');
    } else {
      let notesObject = {};
      const notes = this.chemicalChildForm.get('Notes').value;
      const i = this.editNotesIndex;
      notesObject = {
        Note: this.editedNotes,
        Ip_address: this.ipAddress,
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date().toISOString()
      };
      notes[i] = notesObject;
      this.chemicalChildForm.patchValue({
        Notes: notes
      });
      // this.onSubmit(this.chemicalFrom);
      this.editedNotes = '';
      this.editNotesIndex = 0;
      this.editNotesPopup = false;
    }
  }

  calculateqty(qtyPerContainer, containerPurchased) {
    var qty = qtyPerContainer * containerPurchased;
    this.chemicalChildForm.patchValue({
      Initial_quantity: {
        Units: qty,
        Type: this.masterUnit
      },
      Quantity_onhand: {
        Units: qty,
        Type: this.masterUnit
      }
    });
  }

}
