import { Component } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { SelectItem } from 'primeng/primeng';
import { Router, NavigationEnd } from '@angular/router';
import { NotificationHandler } from '../app/shared/common/notification/notification-handler';
import { Configuration } from './app.configuration';
import { SessionRemove } from './shared/common/storage-setting/session-remove';
import { } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxUiLoaderManualService } from "./shared/service/ngx-ui-loader.service";
import { Subscription, interval } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DataService } from './shared/service/data-service';
import { environment } from 'environments/environment';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  providers: [NotificationHandler, SessionRemove],
  animations: [



    trigger('overlayState', [
      state('hidden', style({
        opacity: 0
      })),
      state('visible', style({
        opacity: 1
      })),
      transition('visible => hidden', animate('400ms ease-in')),
      transition('hidden => visible', animate('400ms ease-out'))
    ]),

    trigger('notificationTopbar', [
      state('hidden', style({
        height: '0',
        opacity: 0
      })),
      state('visible', style({
        height: '*',
        opacity: 1
      })),
      transition('visible => hidden', animate('400ms ease-in')),
      transition('hidden => visible', animate('400ms ease-out'))
    ])
  ],
})
export class AppComponent {
  title = 'app';
  menuActive: boolean;
  createdBy: string;
  modifiedby: SelectItem[];
  Name = [];
  id: any;
  Items = [];
  notificationArray: any = [];
  /**
 * Constructor
 * @param loaderService;
 */
  appVersion: string = environment.appVersion;
  private subscription: Subscription;

  constructor(private router: Router,
    private configuration: Configuration,
    private notificationHandler: NotificationHandler,
    private sessionRemove: SessionRemove,
    private ngxService: NgxUiLoaderService,
    public loaderService: NgxUiLoaderManualService,
    private dataService: DataService
  ) { }


  onMenuButtonClick(event: Event) {
    this.menuActive = !this.menuActive;
    event.preventDefault();
  }
  onActivate(event) {
    window.scroll(0, 0);
  }

  ngOnInit() {
    this.CheckLatestVersion();
    this.sessionRemove.clearLabelSession();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    const signalR = require('@microsoft/signalr');
    const val = this.configuration.taskNotification2 + 'notify';

    const connection = new signalR.HubConnectionBuilder().configureLogging(signalR.LogLevel.Trace).withUrl(val, {
      skipNegotiation: true,
      transport: signalR.HttpTransportType.WebSockets,
    })
    .withAutomaticReconnect()
    .build();

    connection.on('BroadcastMessage', (Notification: any) => {
      console.log("BroadcastMessage--------->", Notification)
      this.notificationHandler.onBroadCastMessage(Notification.notificationInfo, Notification.payload);
    });

    connection.on('BroadcastLabelMessage', (Notification: any) => {
      console.log("BroadcastLabelMessage -----> ", Notification);

      this.notificationArray = this.notificationHandler.LabelGenerationNotification(Notification);
      console.log("notificationArray", this.notificationArray)
      this.notificationArray.forEach(element => {     
        if (element.message == 'PDF generated successfully') {
          window.location.reload();
        }
      });
    });

    connection.start().then(() => {
    }).catch(err =>
      console.log("err--------->", err.toString())
    );

    connection.onreconnecting(error => {
      console.log("Connection lost due to", error, ", Reconnecting");
    });

    connection.onreconnected(connectionId => {
      console.log("Connection reestablished. Connected with connectionId", connectionId);     
  });

  
  }

  toastAction(e, type) {
    const targetValue = this.notificationArray[e];
    switch (type) {
      case 'view':
        if (targetValue.Pdf_url.length > 0) {
          targetValue.Pdf_url.forEach(element => {
            window.open(element, '_blank');
          });
        }
        this.notificationArray.splice(e, 1);
        break;
      case 'refresh':
        window.location.reload();
        break;

      default:
        this.notificationArray.splice(e, 1);
        break;
    }
  }

  CheckLatestVersion() {
    console.log("----------- Version Cron Start -----------")
    // Call the API every 20 minutes (1200000 milliseconds)
    this.subscription = interval(1200000).pipe(
      switchMap(() => this.dataService.GetLatestVersion())).subscribe((data) => {
        console.log("appVersion", this.appVersion);
        console.log("data", data);
        if (this.appVersion != data) { 
          const userConfirmed = confirm('Please refresh the page to access the latest updates.');
          if (userConfirmed) { window.location.reload(); }
        }
      },
        (error) => {
          console.error('Version API error:', error);
        }
      );
  }

}

