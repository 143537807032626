import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class KindBaseModel {
    public master_formula = 'master_formula';
    public component_inventory ="component_inventory";
    public chemical_inventory ="chemical_inventory";
    public chemical_child_inventroy ="chemical_child_inventory";
    public finished_inventory ="finished_inventory";
    public training ="training";
    public training_detail ="training_details";
    public batch_record ="batch_record";

    
} 
