import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DataService } from '../service/data-service';
import { Configuration } from '../../app.configuration';
import { StorageConfiguration } from '../common/storage-setting/storage-configuration';

@Injectable({
  providedIn: 'root'
})
export class NotifyDataService {

  private NotifyCount = new BehaviorSubject<number>(0);
  notifycast = this.NotifyCount.asObservable();
  //currentCount: number = 0;

  private TaskCount = new BehaviorSubject<number>(0);
  taskCast = this.TaskCount.asObservable();

  constructor(private configuration: Configuration,
    private dataService: DataService,
    private storageConfiguration: StorageConfiguration) { }

  // editCount(newCount: number){
  //   this.currentCount += newCount; 
  //   this.NotifyCount.next(this.currentCount);
  // }

  getNotificationCount()
  {
    var userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    var userId = userInfo[0].id;
    this.dataService.add(this.configuration.notificationApi + '/GetNotificationCountAsync/' +
    this.dataService.getTenentId() + ',' + userInfo[0].id, '').subscribe(
      (data: any) => {
        if (data.status) {
            this.NotifyCount.next(data.result);
        } else {
            this.NotifyCount.next(0);
        }
      });
  }

  getTaskCount() {

    var userInfo=JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    var userId = userInfo[0].id;
    this.dataService.getSingle(userId, this.configuration.taskManagement + '/GetTaskCountByAssigneeId/').subscribe(
      (data: any) => {
        if (data.status) {
          this.TaskCount.next(data.result);
        } else {
            this.TaskCount.next(0);
        }
      }, err => {
      });

  }

}