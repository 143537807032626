
export interface AddressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}

export interface Location {
    lat: number;
    lng: number;
}

export interface Viewport {
    south: number;
    west: number;
    north: number;
    east: number;
}

export interface Geometry {
    location: Location;
    viewport: Viewport;
}

export interface Close {
    day: number;
    time: string;
    hours: number;
    minutes: number;
    nextDate: any;
}

export interface Open {
    day: number;
    time: string;
    hours: number;
    minutes: number;
    nextDate: any;
}

export interface Period {
    close: Close;
    open: Open;
}

export interface OpeningHours {
    open_now: boolean;
    periods: Period[];
    weekday_text: string[];
}

export interface Photo {
    height: number;
    html_attributions: string[];
    width: number;
}

export interface Review {
    author_name: string;
    author_url: string;
    language: string;
    profile_photo_url: string;
    rating: number;
    relative_time_description: string;
    text: string;
    time: number;
}

export interface Data {
    address_components: AddressComponent[];
    adr_address: string;
    formatted_address: string;
    formatted_phone_number: string;
    geometry: Geometry;
    icon: string;
    id: string;
    international_phone_number: string;
    name: string;
    opening_hours: OpeningHours;
    photos: Photo[];
    place_id: string;
    rating: number;
    reference: string;
    reviews: Review[];
    scope: string;
    types: string[];
    url: string;
    utc_offset: number;
    vicinity: string;
    website: string;
    html_attributions: any[];
    description: string;
    active: boolean;
}

export interface RootObject {
    response: boolean;
    data: Data;
}

export class AddressForm {
    street_number:string = '';
    route:string = '';
    locality:string = '';
    administrative_area_level_1:string = '';
    country:string = '';
    postal_code:string = '';
    address1:string = '';
    address2:string = '';
    ComponentFor:string = '';
}
