import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GoogleAddressAutocompleteComponent } from './common/google-address-autocomplete/google-address-autocomplete.component';
import { Ng4GeoautocompleteModule } from 'ng4-geoautocomplete';
import { PhoneNumberComponent } from './common/phone-number/phone-number.component';
import { InternationalPhoneModule } from 'ng4-intl-phone';
import { OnlyNumberDirective } from './common/phone-number/only-number.directive';
import { CountryPipe } from './common/phone-number/country.pipe';
import { CountryService } from './common/phone-number/country.service';
import { PhoneComponentComponent } from './common/phone-component/phone-component.component';
import { FileUploadComponent } from './common/file-upload/file-upload.component';
import { ImgUploadComponent } from './common/img-upload/img-upload.component';
import { ExcelGenerateComponent } from './common/excel-generate/excel-generate.component';
import { ActivityComponent } from './common/activity/activity.component';
import { CommonStatusReportComponent } from './common/common-status-report/common-status-report.component';
import { CommonReportGenerateComponent } from './common/common-report-generate/common-report-generate.component';
import { ReportDataTableComponent } from './common/report-data-table/report-data-table.component';
import { ReportGenerateComponent } from './common/report-generate/report-generate.component';
import { ChemicalAddChildComponent } from './common/forms/chemical-inventory-add-forms/chemical-add-child/chemical-add-child.component';
import { ChemicalAddMasterComponent } from './common/forms/chemical-inventory-add-forms/chemical-add-master/chemical-add-master.component';
import { ComponentAddChildComponent } from './common/forms/component-inventory-add-forms/component-add-child/component-add-child.component';
import { ComponentAddMasterComponent } from './common/forms/component-inventory-add-forms/component-add-master/component-add-master.component';
import { EnvironmentMonitoringAddComponent } from './common/forms/environment-monitoring-add/environment-monitoring-add.component';
import { PersonalMonitoringAddComponent } from './common/forms/personal-monitoring-add/personal-monitoring-add.component';
import { EquipmentClassificationListComponent } from './common/equipment-classification-list/equipment-classification-list.component';
import { CommonNotesComponent } from './common/common-notes/common-notes.component';
import { ReleaseCriteriaComponent } from './common/release-criteria/release-criteria.component';
import { CommonCalendarComponent } from './common/common-calendar/common-calendar.component';
import { CommonDatatableEditableComponent } from './common/common-datatable-editable/common-datatable-editable.component';
import { BatchCommentsComponent } from './common/batch-comments/batch-comments.component';
import { BatchCreateComponent } from './common/batch-create/batch-create.component';
import { TimerComponent } from './common/timer/timer.component';
import { TimerInputComponent } from './common/timer-input/timer-input.component';
import { WebcamComponent } from './common/webcam/webcam.component';
import { WebcamModule } from 'ngx-webcam';
import { CameraComponent } from './common/camera/camera.component';
import { CommonSelectableListComponent } from './common/common-selectable-list/common-selectable-list.component';
import { ErrorOopsComponent } from './common/error-oops/error-oops.component';
import { ErrorSessionExpiredComponent } from './common/error-session-expired/error-session-expired.component';
import { ErrorSiteBlockedComponent } from './common/error-site-blocked/error-site-blocked.component';
import { Error401Component } from './common/error401/error401.component';
import { Error404Component } from './common/error404/error404.component';
import { Error500Component } from './common/error500/error500.component';
import { PackingComponent } from './common/packing/packing.component';
import { OtherNotificationComponent } from './common/notification/other-notification/other-notification.component';
import { TaskNotification } from './common/notification/task-notification';
import { Ng4LoadingSpinnerModule } from 'ng4-loading-spinner';
import { NotificationHandler } from './common/notification/notification-handler';
import { SafePipe } from '@app/shared/pipes/SafePipe';
import { PrimengModule } from '@app/primeng.module';
import { Error400Component } from './common/error400/error400.component';
import { RouterModule } from '@angular/router';
import { LayoutComponent } from '../core/layout/layout.component';
import { TopHeaderComponent } from '../core/top-header/top-header.component';
import { SideBarComponent } from '../core/side-bar/side-bar.component';
// import { ComplianceComponent } from '@app/module/compliance/compliance.component';
// import { FacilityComponent } from '@app/module/facility/facility.component';
// import { FacilityCalendarComponent } from '@app/module/facility/facility-calendar/facility-calendar.component';
// import { OrderComponent } from '@app/module/order/order.component';
import { BlockCopyPasteDirective } from '@app/shared/common/directive/block-copy-paste.directive';
// import { ProductionComponent } from '@app/module/production/production.component';
import { AutofocusDirective } from '@app/shared/common/directive/autofocus.directive';
import { DateTransactionComponent } from './common/date-transaction/date-transaction.component';

const routes = [

  { path: 'unauthorized', component: Error401Component },
  { path: 'internal-server-error', component: Error500Component },
  { path: 'page-not-found', component: Error404Component },
  { path: 'bad-request', component: Error400Component },
  { path: 'session-expired', component: ErrorSessionExpiredComponent },
  { path: 'oops-error', component: ErrorOopsComponent },
  { path: 'site-blocked', component: ErrorSiteBlockedComponent },



];

@NgModule({
  declarations: [GoogleAddressAutocompleteComponent, NotificationHandler, //ComplianceComponent, FacilityComponent, FacilityCalendarComponent,
    // OrderComponent,
    BlockCopyPasteDirective,
    // ProductionComponent,
    AutofocusDirective,
    LayoutComponent,
    TaskNotification,
    TopHeaderComponent,
    SideBarComponent,
    OnlyNumberDirective,
    CountryPipe,
    PhoneNumberComponent,
    SafePipe,
    PhoneComponentComponent,
    FileUploadComponent,
    AutofocusDirective,
    ImgUploadComponent,
    ExcelGenerateComponent,
    ActivityComponent,
    ChemicalAddChildComponent,
    ChemicalAddMasterComponent,
    ComponentAddChildComponent,
    ReportDataTableComponent,
    ComponentAddMasterComponent,
    EnvironmentMonitoringAddComponent,
    PersonalMonitoringAddComponent,
    EquipmentClassificationListComponent,
    CommonNotesComponent,
    ReleaseCriteriaComponent,
    CommonCalendarComponent,
    CommonDatatableEditableComponent,
    BatchCommentsComponent,
    CommonReportGenerateComponent,
    CommonStatusReportComponent,
    ReportGenerateComponent,
    BatchCreateComponent,
    TimerComponent,
    TimerInputComponent,
    WebcamComponent,
    CameraComponent,
    CommonSelectableListComponent,
    ErrorOopsComponent,
    ErrorSessionExpiredComponent,
    ErrorSiteBlockedComponent,
    Error400Component,
    Error401Component,
    Error404Component,
    Error500Component,
    PackingComponent,
    OtherNotificationComponent,
    DateTransactionComponent
  ],
  imports: [
    CommonModule,
    Ng4LoadingSpinnerModule.forRoot(),
    Ng4GeoautocompleteModule.forRoot(),
    InternationalPhoneModule,
    WebcamModule,
    PrimengModule,
    RouterModule.forChild(routes)
  ],

  exports: [
    GoogleAddressAutocompleteComponent,
    PhoneNumberComponent,
    CommonNotesComponent,
    CommonCalendarComponent,
    ReportDataTableComponent,
    FileUploadComponent,
    FileUploadComponent,
    CommonStatusReportComponent,
    ImgUploadComponent,
    CommonReportGenerateComponent,
    ExcelGenerateComponent,
    PackingComponent,
    ActivityComponent,
    TimerInputComponent,
    SafePipe,
    BatchCommentsComponent,
    ReportGenerateComponent,
    CommonDatatableEditableComponent,
    BatchCreateComponent,
    CommonReportGenerateComponent,
    TimerComponent,
    ChemicalAddChildComponent,
    CommonStatusReportComponent,
    WebcamComponent,
    ChemicalAddMasterComponent,
    ReleaseCriteriaComponent,
    ComponentAddChildComponent,
    ComponentAddMasterComponent,
    EnvironmentMonitoringAddComponent,
    PersonalMonitoringAddComponent,
    EquipmentClassificationListComponent,
    DateTransactionComponent


  ],
  providers: [CountryService,
    TaskNotification,
    NotificationHandler,
    AutofocusDirective,
    BlockCopyPasteDirective,

  ]
})
export class SharedModule { }
