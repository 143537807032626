import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { InputTextModule } from 'primeng/primeng';
import { DataService } from '../../shared/service/data-service';
import { Configuration } from '../../app.configuration';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';
import { ResponseResult, LoginResponse } from '../model/user-login.interface';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-login-reset',
  templateUrl: './user-reset.component.html',
  styleUrls: ['./user-reset.component.sass']
})
export class LoginResetComponent implements OnInit {

  errormessage: string;
  body: { id: any; New_password: any; Password_update_date: any };
  password: any;
  id: any;
  resetForm: FormGroup;
  constructor(private fb: FormBuilder,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dataService: DataService,
    private configuration: Configuration,
    private spinnerService: Ng4LoadingSpinnerService) {
    this.createForm();
  }
  reset(data) {
    this.spinnerService.show();
    if (data.value.password === data.value.confirmpassword) {
      this.password = data.value.password;
      this.body = { id: this.id, New_password: this.password, Password_update_date: '' };
      this.dataService.add(this.configuration.resetPassword, this.body).subscribe(
        (response: any) => {
          this.spinnerService.hide();
          this.router.navigate(['']);
        }, err => {
          this.spinnerService.hide();
          if (err.error.message.result == 'Link has been expired!!') {
            this.errormessage = 'Link has been expired. You need to reset it again.';
          } else {
            this.errormessage = err.error.message.result;
          }

        });
    } else {
      this.spinnerService.hide();
      this.errormessage = 'Please enter same password';
    }
  }


  ngOnInit(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.id = params['params'].id;
    });

  }

  createForm() {
    this.resetForm = this.fb.group({
      password: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmpassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])]
    });
  }


}
