import { Component, OnInit, ViewChild, ElementRef, Output, Input, EventEmitter } from '@angular/core';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration';
import { ConfirmationService } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import {  } from 'rxjs/Subject';
import { Observable,Subject } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.sass'],
  providers: [ConfirmationService]

})
export class WebcamComponent implements OnInit {
  // toggle webcam on/off
  public showWebcam = true;
  public hideCam = false;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {
    // width: {ideal: 1024},
    // height: {ideal: 576}
  };
  public errors: WebcamInitError[] = [];
  public mirrorImage = 'always';

  // latest snapshot
  public webcamImage: WebcamImage = null;

  // webcam snapshot trigger
  private trigger: Subject<void> = new Subject<void>();
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();


  display_CameraPopup = false;
  @Output() BatchSnapEmitter = new EventEmitter();
  @Input() savedImgUrl: any;



  public captures: Array<any>;
  currentIndex: any;
  selectedValues: string[] = [];
  snapImage: FormGroup;
  snapImageData: any;
  ErrorArray = [];
  oldUrl: any[];


  public constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private fb: FormBuilder,
    private toastr: ToastrService
  ) {

    this.captures = [];
  }

  public ngOnInit(): void {
    this.spinnerService.show();
    WebcamUtil.getAvailableVideoInputs()
      .then((mediaDevices: MediaDeviceInfo[]) => {
        this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1;
      });
    if (this.errors.length == 0) {

    }
    this.display_CameraPopup = true;
    this.captures = this.savedImgUrl;
    this.spinnerService.hide();

  }

  public triggerSnapshot(): void {
    this.trigger.next();
  }

  public toggleWebcam(): void {
    // this.showWebcam = !this.showWebcam;
    this.hideCam = !this.hideCam;
  }

  public handleInitError(error: WebcamInitError): void {


    this.errors.push(error);
    // this.ErrorArray.push(error.message);

    // let li =+ '<li>'+error.message+'</li>';
    if (this.errors.length > 0) {
      let errorMessage = error.message == null || 'The object can not be found here.' ? 'Requested Device not found' : error.message;
      this.toastr.warning(error.message);
      //this.BatchSnapEmitter.emit(false);
      this.display_CameraPopup = true;
    }
    else {
      this.display_CameraPopup = true;
    }
    this.spinnerService.hide();


  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    // true => move forward through devices
    // false => move backwards through devices
    // string => move to device with given deviceId
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {


    console.info('received webcam image', webcamImage);
    let val = webcamImage.imageAsDataUrl;
    // this.capturedBase64.push();
    this.captures.push(val);
    this.selectedValues = [];
    this.captures.forEach((element, index) => {
      this.selectedValues.push('' + index);
    });
  }

  public cameraWasSwitched(deviceId: string): void {

    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }


  showPreview(i) {

    this.currentIndex = i;
    let src = this.captures[i];
    var modal = document.getElementById('myModal');
    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    modal.style.display = "block";
    modalImg.setAttribute('src', src);
    captionText.innerHTML = 'Image' + (i + 1);
    var span = document.getElementsByClassName("close")[0];
  }

  plusSlides(movingIndex) {

    this.currentIndex = movingIndex;
    let totalLength = this.captures.length;
    if (movingIndex < 0) {
      movingIndex = totalLength - 1;
      this.currentIndex = movingIndex;
    }
    else if (movingIndex == totalLength) {
      movingIndex = 0;
      this.currentIndex = movingIndex;
    }

    let src = this.captures[movingIndex];

    var modalImg = document.getElementById("img01");
    var captionText = document.getElementById("caption");
    modalImg.setAttribute('src', src);
    captionText.innerHTML = 'Image' + (movingIndex + 1);
  }

  CloseshowPreview() {
    var modal = document.getElementById('myModal');
    modal.style.display = "none";

  }
  deleteImsage() {


    this.selectedValues.forEach((element, index) => {
      let val = parseInt(element);

      this.captures.splice(val, 1);
    });

    let captureArray = [];
    this.captures.forEach(element => {
      captureArray.push(element);
    });
    this.selectedValues = [];
    this.captures = captureArray;

  }

  deleteImage() {
    this.oldUrl = [];
    let captureArray = [];
    this.selectedValues.forEach((element, index) => {
      let val = parseInt(element);
      let currentValue = this.captures[val];
      if (currentValue.includes('.png')) {
        this.oldUrl.push(currentValue);
      } else {
        // captureArray.push(currentValue.replace(/^data:image\/(png|jpg);base64,/, ''));
        captureArray.push(currentValue.replace(/^data:image\/[a-z]+;base64,/, ''));
      }

    });

    this.snapImage = this.fb.group({
      snapImage: []
    });
    this.snapImage.patchValue({
      snapImage: captureArray
    });

    this.spinnerService.show();
    this.dataService.add<any>(this.configuration.batchRecord + '/GetSnapUrlList', this.snapImage.value).subscribe(
      // this.dataService.add<any>("http://192.168.0.100:850/api/BatchRecord/GetSnapUrlList", this.snapImage.value).subscribe(
      (data: any) => (this.snapImageData = data),
      error => () => {



      },
      () => {


        if (this.snapImageData.status) {
          const data = this.oldUrl.concat(this.snapImageData.result);
          this.BatchSnapEmitter.emit(data);
        }
        this.spinnerService.hide();

      }
    );




  }

  CloseSnapshot() {
    this.BatchSnapEmitter.emit(false);
  }

  FlipWebcam() {

    if (this.mirrorImage === 'Always') {
      this.mirrorImage = 'never';
    } else {
      this.mirrorImage = 'Always';
    }
  }





}