import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from './auth.service';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private authService: AuthService, private router: Router, private toastr: ToastrService, private storageConfiguration: StorageConfiguration) {
  }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    let url: string = state.url;
    let isloggedIn = this.storageConfiguration.sessionGetItem(this.storageConfiguration.isloggedIn);
    let getToken = this.storageConfiguration.sessionGetItem(this.storageConfiguration.token);
    let deCodedToken = this.decodeToken(getToken);
    var displayDate = Math.floor((new Date()).getTime() / 1000);//new Date().toUTCString();
    let tokenExptime = parseFloat(deCodedToken.exp);
    var d = new Date(0);
    let tokenExptimenew = new Date(d.setUTCSeconds(tokenExptime)).toUTCString();
    if (tokenExptime >= displayDate) {
      if (isloggedIn == 'true') {
        return true;
      }
      this.authService.setRedirectUrl(url);
      this.router.navigate([this.authService.getLoginUrl()]);
      return false;
    } else {
      this.router.navigate([this.authService.getLoginUrl()]);
    }
  }

  decodeToken(token) {
    if (token === null || token === '') { return { 'upn': '' }; }
    const parts = token.split('.');
    if (parts.length !== 3) {
      this.toastr.warning('JWT Token must have 3 parts');
      // throw new Error('JWT must have 3 parts');
    }
    const decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      this.toastr.warning('Can not decode the JWT Token');
      // throw new Error('Cannot decode the token');
    }
    return JSON.parse(decoded);
  }
  urlBase64Decode(str: string) {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0:
        break;
      case 2:
        output += '==';
        break;
      case 3:
        output += '=';
        break;
      default:
        // tslint:disable-next-line:no-string-throw
        throw 'Illegal base64url string!';
    }
    return decodeURIComponent((<any>window).escape(window.atob(output)));
  }

}