import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '@app/app.configuration';
import { Router } from '@angular/router';
import { CustomRoutingName } from '@app/app.custom-routing-name';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { ValidationCheck } from '@app/shared/common/common-validation';
import { yearCalculate } from '@app/shared/common/common-helper';
import { AuditFiles } from '@app/shared/common/audit-File';
import { Filedatamodel } from '@app/shared/model/file-process.interface';
import { FilePatchComponent } from '@app/shared/common/file-upload/file-upload.patch.component';
import { StorageConfiguration } from '../../storage-setting/storage-configuration';
import { SettingHelper } from '@app/shared/common/company-setting-helper';
import { NotificationModel } from '@app/shared/model/notification-model.interface';

@Component({
  selector: 'app-personal-monitoring-add',
  templateUrl: './personal-monitoring-add.component.html',
  styleUrls: ['./personal-monitoring-add.component.sass'],
  providers: [ConfirmationService,
  Filedatamodel]

})
export class PersonalMonitoringAddComponent implements OnInit {

   /**Local Variables */
   isEditt = false;
   batchStart = false;
   sampleDate: any;
   editOperator: any;
   currentDate: string;
   SDAArray = [];
   TSAArray = [];
   TsaForm: FormGroup;
   SdaForm: FormGroup;
   TSA: any[];
   SDA: any[];
   operatorList: any;
   cancelPm: void;
   intervalDay: number;
   intervalDate: Date;
   date: Date;
   yrRange: string;
   operator: any;
   operators: any;
   @Input() batchId;
   @Input() pmResult;
   @Input() mode;
   @Input() pmEdit;
   @Output() closeDialog = new EventEmitter<any>();
   closePopup: void;
   keyCode: number;
   pmForm: FormGroup;
   statusSelectItem: SelectItem[];
   operatorSelectItem: SelectItem[];
   pmResultSelectItem: SelectItem[];
   activity = [];
   selctStatus = 'In-Process';
   isEdit = false;
   Attachment = 'Attachment';
   newRecord = 'NewRecord';
   referenceTypeName = 'PM';
   accessuri: string;
   userInfo: any;
   disColl: boolean;
   notificationModel: NotificationModel;
   notificationForm: FormGroup;
   blockSpecial: RegExp = /^[^<>*!]+$/;
 
   constructor(
     private spinnerService: Ng4LoadingSpinnerService,
     private dataService: DataService,
     private configuration: Configuration,
     private router: Router,
     private fb: FormBuilder,
     private customRoutingName: CustomRoutingName,
     private toastMessage: ToastBaseModel,
     private validationCheck: ValidationCheck,
     private auditFiles: AuditFiles,
     private toastr: ToastrService,
     private yearRange: yearCalculate,
     private filePatch: FilePatchComponent,
     private settingHelper: SettingHelper,
     private storageConfiguration: StorageConfiguration,
   ) { }
 
   ngOnInit() {
     this.spinnerService.show();
     this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
 
     this.createForm();
     this.statusSelectItem = [
       { label: 'In-Process', value: 'In-Process' },
       { label: 'Collected', value: 'Collected' }
     ];
     this.pmResultSelectItem = [
       { label: 'Pass', value: 'Pass' },
       { label: 'Fail', value: 'Fail' }
     ];
     this.pmForm.patchValue({
       Status: 'In-Process'
     });
     if (this.mode === 'batch') {
       if (this.pmEdit !== undefined) {
         this.editPatchValue();
         this.isEditt = true;
       } else {
         this.batchIdGet();
       }
     } else if (this.mode === 'batchStart') {
       this.batchStart = true;
       this.batchIdGet();
     } else {
       this.getOperator();
       this.spinnerService.show();
     }
     this.yrRange = this.yearRange.yrRange;
   }
 
   /*
   ** ==========================================================
   ** Edit PM
   ** ==========================================================
   */
   editPatchValue() {
     this.TSAArray = this.pmEdit.TSA_location;
     this.SDAArray = this.pmEdit.SDA_location;
     this.editOperator = this.pmEdit.Operator_name;
     this.pmForm.patchValue({
       Requested_date: new Date(this.pmEdit.Requested_date),
       Operator_name: this.pmEdit.Operator_name,
       Batch: {
         Batch_id: this.pmEdit.Batch.Batch_id,
         Batch_name: this.pmEdit.Batch.Batch_name
       },
       Lot_number: this.pmEdit.Lot_number,
       Sample_collection_date: new Date(this.pmEdit.Sample_collection_date),
       TSA_location: this.TSAArray,
       SDA_location: this.SDAArray,
       Personnel_monitoring_result: this.pmEdit.Personnel_monitoring_result,
       Status: this.pmEdit.Status,
       Notes: this.pmEdit.Notes,
       id: this.pmEdit.id,
       Kind: this.pmEdit.Kind,
       Created_info: {
         Name: this.pmEdit.Created_info.Name,
         By: this.pmEdit.Created_info.By,
         Date: this.pmEdit.Created_info.Date
       },
     });
     this.currentDate = new Date().toISOString();
     this.sampleDate = this.pmEdit.Sample_collection_date;
     if (this.currentDate >= this.sampleDate) {
       this.isEdit = true;
     } else {
       this.isEdit = false;
     }
     this.disableField();
     this.spinnerService.hide();
     this.disableField();
   }
   /*
   ** ==========================================================
   ** Form Initialialization
   ** ==========================================================
   */
   createForm() {
     this.pmForm = this.fb.group({
       Requested_date: ['', Validators.required],
       Operator_name: [],
       Batch: this.fb.group({
         Batch_id: [''],
         Batch_name: ['']
       }),
       Lot_number: [''],
       Sample_collection_date: ['', Validators.required],
       Personnel_monitoring_result: [''],
       TSA_location: [],
       SDA_location: [],
       Status: [''],
       Notes: [''],
       File_data: Filedatamodel,
       Activity_log: this.fb.array([]),
       id: [''],
       Kind: [''],
       Created_info: this.fb.group({
         Name: [''],
         By: [''],
         Date: ['']
       }),
       Updated_info: this.fb.group({
         Name: [''],
         By: [''],
         Date: ['']
       }),
       Deleted_info: this.fb.group({
         Name: [''],
         By: [''],
         Date: ['']
       }),
       Is_active: false,
       Is_deleted: false
     });
 
     this.TsaForm = this.fb.group({
       Value: '',
       Sample_collection: true,
       Result: ''
     });
     this.SdaForm = this.fb.group({
       Value: '',
       Sample_collection: true,
       Result: ''
     });
 
     this.notificationForm = this.fb.group({
       NotificationInfo: this.fb.group({
         Severity: [''],
         Module_type: [''],
         Tenant_id: [''],
         Message: [''],
         Supporting_fields: [''],
         Action: [''],
         Current_user: this.fb.group({
           id: [''],
           Name: ['']
         })
       }),
       Payload: ['']
     });
   }
   /*
   ** ==========================================================
   ** Get OPerator List
   ** ==========================================================
   */
   getOperator() {
     this.dataService.getSingle('NewRecord', this.configuration.personnelMonitoring + '/').subscribe(
       (data: any) => {
         this.operators = data.options.Operators;
         this.SDA = data.options.CompanySettings.TSA_location.Value;
         this.TSA = data.options.CompanySettings.SDA_location.Value;
         var TsaArray = this.settingHelper.getReleaseCriteria(data.options.CompanySettings.TSA_location.Value, true, '');
         var SdaArray = this.settingHelper.getReleaseCriteria(data.options.CompanySettings.SDA_location.Value, true, '');
         this.TsaBinder(TsaArray);
         this.SdaBinder(SdaArray);
         this.operatorSelectItem = this.bindDropDown(data.options.Operators, 'multiselect');
         this.intervalDay = data.options.CompanySettings.Interval_day;
         this.date = new Date();
         this.intervalDate = new Date();
         this.intervalDate.setDate(this.date.getDate() + this.intervalDay);
         this.pmForm.patchValue({
           Requested_date: this.date,
           Sample_collection_date: new Date(this.intervalDate)
         });
         this.spinnerService.hide();
       });
   }
   /*
   ** ==========================================================
   ** TSA Binder Helper
   ** ==========================================================
   */
   TsaBinder(response) {
     this.TSAArray = [];
     let tsaArray = response;
     tsaArray.forEach(element => {
       const obj = { Value: element.Value, Sample_collection: false, Result: '0' };
       this.TSAArray.push(obj);
     });
   }
   /*
   ** ==========================================================
   ** SDA Binder Helper
   ** ==========================================================
   */
   SdaBinder(response) {
     this.SDAArray = [];
     let sdaArray = response;
     sdaArray.forEach(element => {
       const obj = { Value: element.Value, Sample_collection: false, Result: '0' };
       this.SDAArray.push(obj);
     });
   }
   tsaSdaValuePatch() {
     this.pmForm.patchValue({
       TSA_location: this.TSAArray,
       SDA_location: this.SDAArray
     });
   }
   /*
   ** ==========================================================
   ** Bind Dropdown Options
   ** ==========================================================
   */
   bindDropDown(array: any[], ddType: string): any[] {
     const dropDownItem = [];
     array.forEach(a => {
       switch (ddType) {
         case 'Operators':
           dropDownItem.push({ label: a.Name, value: a.id });
           break;
         case 'multiselect':
           dropDownItem.push({ label: a.Name, value: { id: a.id, Name: a.Name } });
           break;
       }
     });
     return dropDownItem;
   }
   /*
   ** ==========================================================
   ** Get PM By Batch Id
   ** ==========================================================
   */
   batchIdGet() {
     this.dataService.getSingle(this.batchId, this.configuration.personnelMonitoring + '/BatchById/').subscribe(
       (data: any) => {
         this.spinnerService.hide();
         this.SDA = data.options.SDA_location.Value;
         this.TSA = data.options.TSA_location.Value;
         var TsaArray = this.settingHelper.getReleaseCriteria(data.options.TSA_location.Value, true, '');
         var SdaArray = this.settingHelper.getReleaseCriteria(data.options.SDA_location.Value, true, '');
         this.TsaBinder(TsaArray);
         this.SdaBinder(SdaArray);
         this.intervalDay = data.options.Interval_day;
         this.date = new Date();
         this.intervalDate = new Date();
         this.intervalDate.setDate(this.date.getDate() + this.intervalDay);
         this.pmForm.patchValue({
           Requested_date: this.date,
           Sample_collection_date: new Date(this.intervalDate),
           Batch: {
             Batch_id: data.result.id,
             Batch_name: data.result.Batch_name
           },
           Lot_number: data.result.Lot_number
         });
         if (this.pmResult !== undefined) {
           this.operatorList = this.pmResult.map(item => item.Operator_name);
           let operatorArray = [];
           this.pmResult.forEach(element => {
             element.Operator_name.forEach(childElement => {
               operatorArray.push(childElement);
             });
           });
           operatorArray.forEach(element => {
             let index = data.result.Operator.findIndex(a => a.id === element.id);
             data.result.Operator.splice(index, 1);
           });
           this.operatorSelectItem = this.bindDropDown(data.result.Operator, 'multiselect');
         } else {
           this.operatorSelectItem = this.bindDropDown(data.result.Operator, 'multiselect');
         }
 
       });
   }
   /*
   ** ==========================================================
   ** Operator OnChange
   ** ==========================================================
   */
   operatorOnChange(event) {
     this.operator = event.value;
     var operatorname = '';
     event.value.forEach(element => {
       operatorname += element.Name + ',';
     });
 
     this.activity = this.auditFiles.newCreateActivity('Operator Name', operatorname);
   }
   /*
   ** ==========================================================
   ** PM Patch Value
   ** ==========================================================
   */
   pmFormPatchValue(data) {
     this.pmForm.patchValue({
       Requested_date: new Date(data.Requested_date),
       Sample_collection_date: new Date(data.Sample_collection_date),
       Personnel_monitoring_result: data.Personnel_monitoring_result,
       Status: data.Status,
       Notes: data.Notes
     });
   }
   /*
   ** ==========================================================
   ** Save PM
   ** ==========================================================
   */
   onSubmit(data) {
     this.tsaSdaValuePatch();
     const invalid = [];
     const controls = this.pmForm.controls;
     for (const name in controls) {
       if (controls[name].invalid) {
         invalid.push(name);
       }
     }
     let validatorArrays;
     validatorArrays = this.validationCheck.getValidation(invalid);
     if (invalid.length === 0) {
       if (data.value.Operator_name === null || data.value.Operator_name.length === 0) {
         this.toastr.warning('Please select operator');
         return;
       }
       this.spinnerService.show();
       data.value.Created_info.Name = this.userInfo[0].name;
       data.value.Created_info.By = this.userInfo[0].id;
       data.value.Created_info.Date = new Date().toISOString();
       data.value.id = '';
       data.value.Kind = 'personnel_monitoring';
       data.value.Is_deleted = false;
       data.value.Is_active = true;
       data.value.Activity_log = this.activity;
       this.dataService.add(this.configuration.personnelMonitoring + '/', data.value).subscribe(
         (result: any) => {
           this.spinnerService.hide();
           if (result.message.status) {
             if (this.mode === 'Normal') {
               this.router.navigate([this.customRoutingName.personnel_monitoring_detail + result.message.id]);
             } else if (this.mode === 'batchStart') {
               this.closeDialog.emit('saveNext');
             } else {
               this.cancelPm = this.pmForm.reset();
               this.closeDialog.emit(this.cancelPm);
             }
             this.toastr.success(this.toastMessage.PersonnelMonitoring + ' ' + this.toastMessage.Create);
 
             if (data.value.Personnel_monitoring_result == 'Fail') {
               data.value.id = result.message.id;
               this.notificationForm.patchValue({
                 Payload: data.value
               });
               var operatorList = [];
               data.value.Operator_name.forEach(element => {
                 operatorList.push(element.Name);
               });
               this.notificationForm.patchValue({
                 NotificationInfo: {
                   Severity: 'High',
                   Module_type: 'PM',
                   Tenant_id: this.dataService.getTenentId(),
                   Message: operatorList.toString() + ' failed in personnel monitoring',
                   Supporting_fields: '',
                   Action: 'Update',
                   Current_user: {
                     id: this.userInfo[0].id,
                     Name: this.userInfo[0].name
                   }
                 }
               });
 
               this.dataService.add(this.configuration.taskNotification, this.notificationForm.value).subscribe(
                 () => {
                 });
             }
           }
           else {
             this.toastr.success(this.toastMessage.PersonnelMonitoring + ' ' + result.message.result);
           }
         });
     } else {
       this.toastr.warning(validatorArrays);
     }
   }
   cancel() {
     this.closePopup = this.pmForm.reset();
     this.closeDialog.emit(this.closePopup);
   }
   keyDownFunction(event) {
     if (event.keyCode === 13) {
       this.keyCode = 13;
     }
   }
   /*
   ** ==========================================================
   ** Update PM
   ** ==========================================================
   */
   updatePM(data) {
     this.tsaSdaValuePatch();
     const invalid = [];
     const controls = this.pmForm.controls;
     for (const name in controls) {
       if (controls[name].invalid) {
         invalid.push(name);
       }
     }
     let validatorArrays;
     validatorArrays = this.validationCheck.getValidation(invalid);
     if (invalid.length === 0) {
       if (this.keyCode !== 13) {
         data.value.Updated_info.Name = this.userInfo[0].id;
         data.value.Updated_info.By = this.userInfo[0].name;
         data.value.Updated_info.Date = new Date().toISOString();
         data.value.id = this.pmEdit.id;
         this.dataService.update(data.value.id, this.configuration.personnelMonitoring + '/', data.value).subscribe(
           (result: any) => {
             this.spinnerService.hide();
             if (this.mode !== 'batch') {
               this.router.navigate([this.customRoutingName.personnel_monitoring_detail + result.message.data.value.id]);
             } else {
               this.cancelPm = this.pmForm.reset();
               this.closeDialog.emit(this.cancelPm);
             }
             this.toastr.success(this.toastMessage.PersonnelMonitoring + ' ' + this.toastMessage.Update);
           });
       }
     } else {
       this.toastr.warning(validatorArrays);
     }
   }
   /*
   ** ==========================================================
   ** TSA OnChange
   ** ==========================================================
   */
   tsaOnChange(event, index) {
     let TSAArray = this.TSAArray;
     if (!event) {
       TSAArray[index].Result = '0';
     } else {
       TSAArray[index].Result = '';
     }
   }
   /*
   ** ==========================================================
   ** SDA OnChange
   ** ==========================================================
   */
   sdaOnChange(event, index) {
     let SDAArray = this.SDAArray;
     if (!event) {
       SDAArray[index].Result = '0';
     } else {
       SDAArray[index].Result = '';
     }
   }
   saveNext(data) {
     this.onSubmit(data);
   }
   /*
   ** ==========================================================
   ** Uploaded File Patch
   ** ==========================================================
   */
   receiveFileUploadResponse($event) {
     const getfileresult = this.filePatch.genericFilePatch($event, this.pmForm);
     this.pmForm.patchValue({ File_data: getfileresult });
   }
   /*
   ** ==========================================================
   ** Disable the fileds by Sample collection Date
   ** ==========================================================
   */
   disableField() {
     this.currentDate = new Date().toISOString();
     let collectDate = new Date(this.pmForm.get('Sample_collection_date').value).toISOString();
     if (this.currentDate > collectDate) {
       this.disColl = true;
     } else {
       this.disColl = false;
     }
   }
   /*
   ** ==========================================================
   ** Select All SDA
   ** ==========================================================
   */
   selectAllSDA(event) {
     if (event) {
       this.SDAArray.forEach(element => {
         element.Sample_collection = event;
         element.Result = '0';
       });
     } else {
       this.SDAArray.forEach(element => {
         element.Sample_collection = event;
         element.Result = '0';
       });
     }
   }
   /*
   ** ==========================================================
   ** Select All TSA
   ** ==========================================================
   */
   selectAllTSA(event) {
     if (event) {
       this.TSAArray.forEach(element => {
         element.Sample_collection = event;
         element.Result = '0';
       });
     } else {
       this.TSAArray.forEach(element => {
         element.Sample_collection = event;
         element.Result = '0';
       });
     }
 
   }
 
 }
 
 
 