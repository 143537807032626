export class DetailResponse<T> {
    status: boolean;
    result: T ;
}

export class ResponseBody {
    status: boolean;
    result: string;
    id: string;
    Ndc: Ndc;

}

export class Ndc {
    Ndc: string;
    Sku: string;
}

export class Response {
    message: ResponseBody;
}

export class Message {
    message: string;
}

export class FileResponseBody {
    status: boolean;
    result: string;
    accessUri: string;

}

export class FileResponse {
    message: FileResponseBody;
}
