import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-error-oops',
  templateUrl: './error-oops.component.html',
  styleUrls: ['./error-oops.component.sass']
})
export class ErrorOopsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
