import {
    HttpClient, HttpHandler,
    HttpInterceptor,
    HttpRequest, HttpSentEvent, HttpHeaderResponse, HttpProgressEvent,
    HttpResponse, HttpUserEvent, HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../../app.configuration';
import { Router } from '@angular/router';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { StorageConfiguration } from '../common/storage-setting/storage-configuration';
import { BehaviorSubject, observable, throwError } from 'rxjs';
import { Observable } from 'rxjs';
// import 'rxjs/add/observable/throw';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { catchError } from 'rxjs/operators';


@Injectable({
    providedIn: 'root'
})

export class DataService {
    private actionUrl: string;
    private storageConfiguration: StorageConfiguration;

    constructor(private http: HttpClient, private configuration: Configuration) {
    }
    // Dynamic data service
    public getAll<T>(apiUrl: string): Observable<T> {
        return this.http.get<T>(apiUrl);
    }

    public getSingle<T>(id: string, apiUrl: string): Observable<T> {
        return this.http.get<T>(apiUrl + id);
    }

    public add<T>(apiUrl: string, itemName: any): Observable<T> {
        const toAdd = JSON.stringify(itemName);
        return this.http.post<T>(apiUrl, toAdd);
    }

    public update<T>(id: string, apiUrl: string, itemToUpdate: any): Observable<T> {
        return this.http.put<T>(apiUrl + id, JSON.stringify(itemToUpdate));
    }

    public delete<T>(id: string, apiUrl: string): Observable<T> {
        return this.http.delete<T>(apiUrl + id);
    }

    //For file process
    public addFile<T>(apiUrl: string, itemName: FormData): Observable<T> {

        return this.http.post<T>(apiUrl, itemName);
    }

    public deleteFile<T>(apiUrl: string): Observable<T> {
        return this.http.delete<T>(apiUrl);
    }



    public getTenentId() {

        var url = window.location.href;
        var subDomain = url.split(':');
        if (subDomain[1] == '//localhost' || subDomain[1] == '//cpg-prod-client-cpg-client-pre-staging-v8.azurewebsites.net/#/login') {
            // return 'primerx';
            return 'demo';
        } else {
            var url = window.location.href;
            var domain = url.split('.');
            if (domain[0] == 'https://sdi' || domain[0] == 'https://cpg-facility' || domain[0] == 'http://192' || domain[0] == 'https://cpg-prod-client-cpg-client-pre-staging-v8') {
                // return 'primerx';
                return 'demo';
            } else {
                return domain[0].split('//')[1];
            }
        }
    }


    GetLatestVersion(): Observable<any> {
        var url = window.location.href;
        var subDomain = url.split(':');
        if (subDomain[1] == '//503btests') {
            return this.http.get('https://cpgprodstorage.blob.core.windows.net/version/503btests-version.txt', {
                responseType: 'text', headers: { 'X-Skip-Interceptor': 'true' } // This custom header signals to skip the interceptor    
            })
        } else {
            return this.http.get('https://cpgprodstorage.blob.core.windows.net/version/version.txt', {
                responseType: 'text', headers: { 'X-Skip-Interceptor': 'true' } // This custom header signals to skip the interceptor    
            })
        }
    }
}



//Interceptor
@Injectable({
    providedIn: 'root'
})
export class CustomInterceptor implements HttpInterceptor {
    isRefreshingToken: boolean = false;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    constructor(private router: Router,
        private spinnerService: Ng4LoadingSpinnerService,
        private storageConfiguration: StorageConfiguration,
        private ngxService: NgxUiLoaderService) { }

    addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
        return req.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
    }

    intercept(req: HttpRequest<any>, next: HttpHandler):
        Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
        var tenentId;
        let userInfo: any[];
        userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
        userInfo = userInfo === null ? [] : userInfo;

        var url = window.location.href;
        var subDomain = url.split(':');

        if (req.headers.has('X-Skip-Interceptor')) {
            const headers = req.headers.delete('X-Skip-Interceptor');
            const clonedRequest = req.clone({ headers });
            return next.handle(clonedRequest);
        }

        if (subDomain[1] == '//localhost' || subDomain[1] == '//cpg-prod-client-cpg-client-pre-staging-v8.azurewebsites.net/#/login') {
            // tenentId = 'database';
            // tenentId = 'bartell';
            // tenentId = 'hro';
            // tenentId = 'qasdi';
            // tenentId = 'primerx';
            tenentId = 'sales';
        } else {
            tenentId = this.getTenentId();
        }
        //Set header with JWT Token
        req = req.clone({
            setHeaders: {
                Authorization: `Bearer ${this.getAuthToken()}`,
                'CollectionId': tenentId,
            }


        });
        //Set header with User Detail
        if (userInfo.length > 0) {

            if (userInfo[0].id != null && userInfo[0].name != null) {

                req = req.clone({
                    setHeaders: {
                        'UserId': userInfo[0].id,
                        'UserName': userInfo[0].name,
                        // 'ipAddress': userInfo[0].ipAddress,
                        'CurrentDate': new Date().toISOString()
                    }
                });
            } else {
                this.router.navigate(['./login']);
            }
        }


        if (req.url.search('FileUpload') == -1) {
            req = req.clone({
                headers: req.headers.set('Content-Type', 'application/json')
            });
        }
        req = req.clone({
            headers: req.headers.set('Accept', 'application/json')

        });
        // Http Error Response
        return <any>next.handle(req).pipe(
            catchError((error, caught) => {
                var statusNotacceptable = (<HttpErrorResponse>error).error;
                if (statusNotacceptable == '406') {
                    return this.router.navigate(['/login']);
                }

                if (error instanceof HttpErrorResponse) {
                    switch ((<HttpErrorResponse>error).status) {
                        case 0:
                            // this.spinnerService.hide();
                            this.ngxService.stopAll();
                            return this.router.navigate(['/error/internal-server-error']);
                        case 400:
                            // this.spinnerService.hide();
                            this.ngxService.stopAll();
                            // return Observable.throw(error);
                            return throwError(error);

                        // return this.handle400Error(error);
                        // return this.router.navigate(['/bad-request']);
                        case 401:
                            // this.spinnerService.hide();
                            this.ngxService.stopAll();
                            return this.router.navigate(['/login']);
                        case 500:
                            // this.spinnerService.hide();
                            this.ngxService.stopAll();
                            return this.router.navigate(['/error/internal-server-error']);
                        case 404:
                            // this.spinnerService.hide();
                            this.ngxService.stopAll();
                            return this.router.navigate(['/error/page-not-found']);
                    }
                } else {
                    // return Observable.throw(error);
                    return throwError(error);
                }
            }));


    }

    getTenentId() {
        var url = window.location.href;
        var domain = url.split('.');
        if (domain[0] == 'https://sdi' || domain[0] == 'https://cpg-facility' || domain[0] == 'http://192' || domain[0] == 'https://cpg-prod-client-cpg-client-pre-staging-v8') {
            // return 'primerx';
            return 'demo';
        } else {
            return domain[0].split('//')[1];
        }
    }

    getAuthToken() {
        return this.storageConfiguration.sessionGetItem(this.storageConfiguration.token);
        // return localStorage.getItem('token');
    }
}
