import { Component, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '../../model/toast-data-model';
import { DataService } from '../../service/data-service';
import { NotifyDataService } from '../../service/notification-service';

@Component({
    providers: [ToastBaseModel],
    template: ''
})

@Injectable({
    providedIn: 'root'
})
export class TaskNotification {

    constructor( 
    private toastr: ToastrService, 
    private dataService: DataService, 
    private notifyDataService:NotifyDataService) { }

    handleTaskPushNotification(currentURL: string, currentUserInfo: any, notificationInfo: any, payload: any) {
        const assignedUser = payload.Task_assigned_to;
        const taskOwner = payload.Task_owner;
        if (this.dataService.getTenentId() === notificationInfo.Tenant_id) {
            if (notificationInfo.Action === 'Add') {
                assignedUser.forEach(user => {
                    if (user.id !== notificationInfo.Current_user.id) {
                        if (user.id === currentUserInfo[0].id) {
                            this.toastr.success(notificationInfo.Message);
                            this.notifyDataService.getNotificationCount();
                            this.notifyDataService.getTaskCount();
                        }
                    }
                });
            } else {
                if (taskOwner.id !== notificationInfo.Current_user.id) {
                    if (taskOwner.id === currentUserInfo[0].id) {
                        this.toastr.success(notificationInfo.Message);
                        this.notifyDataService.getNotificationCount();
                    }
                }
                assignedUser.forEach(user => {
                    if (user.id !== notificationInfo.Current_user.id) {
                        if (user.id === currentUserInfo[0].id) {
                            this.toastr.success(notificationInfo.Message);
                            this.notifyDataService.getNotificationCount();
                        }
                    }
                });
            }
        }
    }


    handleFacilityPushNotification(currentURL: string, currentUserInfo: any, notificationInfo: any, payload: any) {
        const assignedUser = notificationInfo.Supporting_fields;
        if (this.dataService.getTenentId() === notificationInfo.Tenant_id) {
            assignedUser.forEach(user => {
                if (user.id !== notificationInfo.Current_user.id) {
                    if (user.id === currentUserInfo[0].id) {
                        this.toastr.success(notificationInfo.Message);
                        this.notifyDataService.getNotificationCount();
                    }
                }
            });
        }
    }
}
