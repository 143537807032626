import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../auth/service/auth.service';
import { DataService } from '../../../shared/service/data-service';
import { Configuration } from '../../../app.configuration';
import { LogoutResponse, LoginInfo } from '../../../auth/model/user-login.interface';
import { StorageConfiguration } from '../storage-setting/storage-configuration';

@Component({
  selector: 'app-error401',
  templateUrl: './error401.component.html',
  styleUrls: ['./error401.component.sass']
})
export class Error401Component implements OnInit {
  userInfo: any;
  constructor(
    private authService: AuthService,
    private router: Router,
    private configuration: Configuration,
    private dataService: DataService,
    private storageConfiguration: StorageConfiguration
  ) { }

  ngOnInit() {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
  }
  logout() {
    if (this.userInfo.length > 0 || this.userInfo === null || this.userInfo === undefined) {
      const loginInfo: LoginInfo = {
        User_id: this.userInfo[0].id,
        Login_id: this.storageConfiguration.sessionGetItem(this.storageConfiguration.currentlogin)
      };
      this.dataService.add(this.configuration.logOut, loginInfo).subscribe(
        (response: LogoutResponse) => {
        });
    }
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.token);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.password);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.user_Info);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.currentlogin);
    this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.id);
    this.authService.logoutUser();
    this.router.navigate([this.authService.getLoginUrl()]);
  }
}
