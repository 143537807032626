import { Component, OnInit, Input } from "@angular/core";
import { yearCalculate } from "../common-helper";
import { DataService } from "../../../shared/service/data-service";
import { Configuration } from "../../../app.configuration";

@Component({
  selector: "app-activity",
  templateUrl: "./activity.component.html",
  styleUrls: ["./activity.component.sass"],
})
export class ActivityComponent implements OnInit {
  @Input() data: any[];
  dataResult: any[];
  mainArray: any;
  newData: [];
  mylimit: any;
  copyActivityArray: any;
  totelArray: any;
  todayDate: any;
  @Input() label: any;
  @Input() id: any;
  @Input() kind: any;

  Fromdate: any;
  dateFrom: any;
  toDate: any;
  yrRange: any;

  reverseArray: any;
  myDate: any;
  constructor(
    private yearRange: yearCalculate,
    private dataService: DataService,
    private configuration: Configuration
  ) {}

  ngOnInit(): void {
    // if (this.kind === 'component_child' || this.kind === 'chemical_child') {
    //   this.sortArray(this.data);
    //   this.copyActivityArray = JSON.parse(JSON.stringify(this.data));
    // } else {
    this.data = [];
    this.activityGet();
    // }

    this.yrRange = this.yearRange.yrRange;
    this.todayDate = new Date();
  }
  activityGet() {
    this.dataService
      .getSingle(
        "",
        this.configuration.audit +
          "/ActivityLog/" +
          this.kind +
          "?refenceId=" +
          this.id
      )
      .subscribe((data: any) => {
        this.data = data.result;
        this.newData = data.result.activityLog;
        this.mylimit = data.result.activityLog[0].Date;
        this.sortArray(this.newData);
        this.copyActivityArray = JSON.parse(JSON.stringify(this.data));
      });
  }


  ApplyFilter(from: any, to: any) {
    let datas = [];

    if (from == '' && to == "") {
      this.activityGet();
    }
    else{
      const Date1: any = new Date(
        `${new Date(from).getFullYear()},${
          new Date(from).getMonth() + 1
        },${new Date(from).getDate()}`
      );
      const Date2: any = new Date(
        `${new Date(to).getFullYear()},${new Date(to).getMonth() + 1},${new Date(
          to
        ).getDate()}`
      );
      this.newData.forEach((item: any) => {
        let formatedDate = new Date(
          `${new Date(item.Date).getFullYear()},${
            new Date(item.Date).getMonth() + 1
          },${new Date(item.Date).getDate()}`
        );
        if (new Date(formatedDate) >= Date1 && new Date(formatedDate) <= Date2) {
          datas.push(item);
        }
      });
  
      this.dataResult = datas;
    }
  

    
  }

  todayFilter() {
    const todayDate = new Date().getDate();
    this.dataResult = this.data.filter((item: any) => {
      return new Date(item.Date).getDate() === todayDate;
    });
    this.sortArray(this.dataResult);
  }

  fromDate(from) {
    this.dateFrom = new Date(from);
  }

  Clear() {
    this.dataResult = this.data;
    this.Fromdate = "";
    this.toDate = "";
  }

  sortArray(dataResult) {

    if (dataResult.length == 0) {
      return;
    } else {
      let dateA: any;
      let dateB: any;
      this.dataResult = dataResult.sort(function (a, b) {
        (dateA = new Date(a.Date)), (dateB = new Date(b.Date));
        return dateB - dateA;
      });
    }
  }
}
