import { Injectable } from '@angular/core';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';


@Injectable({providedIn: 'root'})


export class AuditFiles {
    activityLogArray = [];
    activityArrayCollection = [];
    date: any;
    obj = {};
    activityCollection = [];
    arrayEntry: boolean;
    checkIdValidation: any;
    valueEntry: boolean;
    userInfo: any;
    sourceEmptyCheck: boolean;
    constructor(
        private storageConfiguration: StorageConfiguration

    ) { }


    //  New Create Activity Log
    newCreateActivity(FormsValue, value) {
        this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
        this.activityLogArray = [];
        this.date = new Date().toISOString();
        let fieldName;
        let Added;
        // fieldName = this._validationCheck.activeValue(FormsValue);
        fieldName = this.nameChange(FormsValue);
        Added = value + ' was Created';
        // this.activityObject(fieldName,Added)
        this.obj = {
            Name: {
                id: this.userInfo[0].id,
                Name: this.userInfo[0].name,
            },
            Description: Added,
            Property_name: fieldName,
            IP_address: this.userInfo[0].ipAddress,
            Date: this.date,
            Notes: 'Created'

        };
        this.activityLogArray.push(this.obj);
        return this.activityLogArray;
    }
    //  Update module  Activity Log

    activityModule(changeValue, mainValue) {
        this.obj = {};
        this.date = new Date().toISOString();
        this.activityLogArray = [];
        // var activeLog = [];
        const activeChange = changeValue;
        const activeGetSingle = mainValue[0];
        // tslint:disable-next-line:forin

        // tslint:disable-next-line:forin
        for (const a in activeChange) {
            for (const s in activeGetSingle) {
                if (a === s) {
                    const Source = activeGetSingle[s];
                    const target = activeChange[a];
                    const type = this.typeCheck(Source);
                    if (Source != null && target != null) {
                        switch (type) {
                            case 'object':
                                this.objectActiveLog(a, Source, target);
                                break;
                            case 'Array':
                                this.arrayActiveLog(a, Source, target);
                                break;
                            default:
                                this.stringActiveLog(a, Source, target);
                        }
                    } 
                }
            }
        }
        return this.activityLogArray;
    }

    // check type object or array or string

    typeCheck(Source) {
        if (Source != null) {
            if (Source.constructor === Array) {
                return 'Array';
            } else if (Source.constructor === Object) {
                return 'object';
            } else if (Source.constructor === String) {
                return 'string';
            } else if (Source.constructor === Date) {
                return 'Date';
            }
        }
    }


    // Check  string data type 



    stringActiveLog(a, Source, target) {
        Source = Source === null ? '' : Source;
        target = target === null ? '' : target;

        let srcDate = Source;
        let tarDate = target;
        let type;
        type = this.typeCheck(tarDate);
        if (type === 'Date') {
            srcDate = new Date(srcDate);
            tarDate = new Date(tarDate);
            srcDate = srcDate === 'Invalid Date' ? '' : srcDate;
            tarDate = tarDate === 'Invalid Date' ? '' : tarDate;
            Source = String(srcDate).slice(3, 15);
            target = String(tarDate).slice(3, 15);
        } else {
            Source = String(srcDate);
            target = String(tarDate);
        }
        this.sourceEmptyCheck = true;

        this.sourceCheck(Source, target, a, '');
        if (target !== Source && Source !== undefined && a !== 'Jwt_token' && a !== 'Training_detail_id' && a !== 'Facility_id' && a !== 'Activity_log' && a !== 'File_data' && this.sourceEmptyCheck) {
            // a = this._validationCheck.activeValue(a);
            let modelName;
            modelName = this.nameChange(a);
            var Description = modelName + '  has updated  from ' +  Source + ' to ' + target;
            var notes = modelName + ' - Updated ';
            this.objConstruct(modelName, Description, notes);
        }
    }

    // Check  object  data type 

    objectActiveLog(a, source, target) {
        
        let fieldName = '';
        let subName = '';
        // tslint:disable-next-line:forin
        for (const obj1 in source) {
            for (const obj2 in target) {
                if(obj1 !== 'id' && obj2 !== 'id')
                {
                    if (obj1 === obj2) {
                        //agin check weather inside the object or array
                        const type = this.typeCheck(source[obj1]);
                        const datetype = this.typeCheck(target[obj1]);
                        this.arrayEntry = true;
                        // if (type === 'object') {
                        //     this.objectActiveLog(a, source[obj1], target[obj1]);
                        // } else 
                        if (type === 'Array') {
                            this.arrayActiveLog(a, source[obj1], target[obj1]);
                        } else if (datetype === 'Date') {
                            let srcDate = source[obj1];
                            let tarDate = target[obj1];
                            srcDate = new Date(srcDate);
                            tarDate = new Date(tarDate);
                            srcDate = srcDate === 'Invalid Date' ? '' : srcDate;
                            tarDate = tarDate === 'Invalid Date' ? '' : tarDate;
                            source[obj1] = String(srcDate).slice(3, 15);
                            target[obj1] = String(tarDate).slice(3, 15);
                        }
                        this.sourceEmptyCheck = true;
                        this.sourceCheck(source[obj1], target[obj1], a, obj1);
                          if (target[obj1] !== source[obj1] && type !== 'object' && type !== 'Array' && target != null && source != null && this.arrayEntry && a !== 'Field_settings' && a !== 'Permission_settings' && this.sourceEmptyCheck) {
                            let moduleName = '';
                            moduleName = fieldName = this.nameChange(a);
                            subName = this.nameChange(obj1);
                            fieldName = fieldName === 'Facility address' ? '' : fieldName + ' -';
                                if (a !== 'Updated_info' && a !== 'Created_info' && a !== 'Case_details'  && obj1 !== 'Color_code' && obj1 !== 'Date' && obj1 !== 'Facility_id' && obj1 !== 'Location_id' && obj1 !== 'id' && obj1 !== 'By') {
                                    var Description = fieldName + subName + '  has updated from ' +   source[obj1]  + ' to ' + '' + target[obj1];
                                    var notes = moduleName + ' - Updated ';
                                    this.objConstruct(moduleName, Description, notes);
                                }
                          } else if (a === 'Field_settings' || a === 'ContainerType_label_size_settings' || a === 'Permission_settings') {
                            this.CompanySettingActivityLog(target[obj1], source[obj1], a, obj1, target, source, type);
                        }
                    }
    
                }
                
            }
        }
    }


    // Check  array   data type 

    arrayActiveLog(a, Source, target) {
        Source = Source === null ? [] : Source;
        target = target === null ? [] : target;
        if (Source.length === target.length && Source != null && target != null && a !== 'Activity_log' && a !== 'Release_criteria' && a !== 'Handling_instructions') {
            this.arrayObjectCheck(a, Source, target);
        } else if (Source.length > target.length) {
        }
    }


    //array  to object convert  

    arrayObjectCheck(a, Source, target) {
        if (target.length > 0 && Source.length > 0) {
            Source.forEach((element, index) => {
                let targetValue = target[index];
                let Sourcevalue = element;
                this.objectActiveLog(a, Sourcevalue, targetValue);
            });
        } else {
            this.arrayEntry = false;
        }
    }

    //add array  for activity log


    addArray(key, propertyName) {
        let Added;
        Added = propertyName + ' was Added';
        var notes = 'Added';
        var obj = {};
        this.objConstructarray(key, Added, notes);
        return this.activityArrayCollection;
    }

    updateArray(key, propertyName) {
        let Added;
        let quotationMark = ' " '
        // let stringProperty = propertyName;
        // let Property = stringProperty.bold();
        Added = key + '  ' + quotationMark + propertyName + quotationMark +'  was Updated';
        var notes = 'Updated';
        var obj = {};
        this.objConstructarray(key, Added, notes);
        return this.activityArrayCollection;
    }

    //delete array  for activity log


    deleteArray(key, propertyName) {
        let deleted;
        deleted = propertyName + ' was deleted';
        var notes = 'Deleted';
        this.objConstructarray(key, deleted, notes);
        return this.activityArrayCollection;
    }

    objConstructarray(key, content, notes) {
        this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
        this.activityArrayCollection = [];
        this.date = new Date().toISOString();
        var obj = {};
        obj = {
            Name: {
                id: this.userInfo[0].id,
                Name: this.userInfo[0].name,
            },
            Property_name: key,

            Description: content,
            IP_address: this.userInfo[0].ipAddress,
            Date: this.date,
            Notes: notes
        };
        this.activityArrayCollection.push(obj);
    }

    //object construct for activity log

    objConstruct(propertyName, content, notes) {
        this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));

        this.date = new Date().toISOString();
        var obj = {};
        obj = {
            Name: {
                id: this.userInfo[0].id,
                Name: this.userInfo[0].name,
            },
            Description: content,
            Property_name: propertyName,
            IP_address: this.userInfo[0].ipAddress,
            Date: this.date,
            Notes: notes
        };
        this.activityLogArray.push(obj);
    }

    // module name 

    nameChange(value) {
        let result = '';
        result = value.replace(/_/g, ' ');
        return result;
    }


    sourceCheck(source, target, a, sub) {
        if (source === '' && a !== 'Updated_info' && a !== 'High_level_process') {
            if (source !== target) {
                this.sourceEmptyCheck = false;
                var emptyAdd;
                var mainName = this.nameChange(a);
                var subName = this.nameChange(sub);
                emptyAdd = this.addArray(mainName + ' ' + subName, target);
                emptyAdd.forEach(element => {
                    this.activityLogArray.push(element);
                });
            }
        } else if (target === '' && a !== 'Updated_info' && a !== 'High_level_process') {
            if (source !== target) {
                var mainName = this.nameChange(a);
                var subName = this.nameChange(sub);
                this.sourceEmptyCheck = false;
                var emptyAdd;
                emptyAdd = this.deleteArray(mainName + ' ' + subName, source);
                emptyAdd.forEach(element => {
                    this.activityLogArray.push(element);
                });
            }
        }
    }

    CompanySettingActivityLog(targetValue, Sourcevalue, a, obj1, target, source, type) {
            //unique  for company Setting Field and container Types 

        if (targetValue !== Sourcevalue && type !== 'object' && type !== 'Array' && a !== 'Permission_settings') {
            var subName = this.nameChange(a);
            var Description = subName + '-' + target['Value'] + ' has updated from' + targetValue + ' to ' + Sourcevalue ;
            var notes = subName + ' - Updated ';
            this.objConstruct(subName, Description, notes);
        } else if (targetValue !== Sourcevalue && type !== 'object' && type !== 'Array' && a === 'Permission_settings') {
            var subName = this.nameChange(a);
            var Description = subName + '-' + target['Module_name'] + '-' + obj1 + ' has updated from ' + Sourcevalue  + ' to ' + targetValue;
            var notes = subName + ' - Updated ';
            this.objConstruct(subName, Description, notes);

        }
    }

}
