import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '../../app.configuration';
import { Response } from '../model/change-password.interface';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { LoginInfo, LogoutResponse } from '../model/user-login.interface';
import { AuthService } from '../service/auth.service';
import { ToastrService } from 'ngx-toastr';
import { StorageConfiguration } from '@app/shared/common/storage-setting/storage-configuration';
import { Location } from '@angular/common';


@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.sass']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: FormGroup;
  errorMessage: string;
  localPassword: string;
  password: string;
  oldPassword: string;
  userId: any;
  message: any;
  updatemessage: any;
  userInfo: any;

  constructor(
    private authService: AuthService,
    private fb: FormBuilder,
    private router: Router,
    private dataService: DataService,
    private configuration: Configuration,
    private toastr: ToastrService,
    private spinnerService: Ng4LoadingSpinnerService,
    private storageConfiguration: StorageConfiguration,
    private location: Location
  ) { }

  ngOnInit() {
    this.createForm();
    this.sessionGet();
    if (this.updatemessage === 'Your password has expired. Need to change it') {
       this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.updatemessage);
    } else { this.updatemessage = 'Change PassWord'; }
  }
  createForm() {
    this.changePasswordForm = this.fb.group({
      oldPassword: ['', Validators.required],
      newPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
    });
  }

  sessionGet() {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.updatemessage = this.storageConfiguration.sessionGetItem(this.storageConfiguration.updatemessage);
  }

  change(changePasswordForm) {
    this.sessionGet();

    const invalidValue = this.invalidFinder();
    if (invalidValue.length === 0) {


      this.spinnerService.show();
       const password = this.storageConfiguration.sessionGetItem(this.storageConfiguration.password);
      this.localPassword = password;
      if (changePasswordForm.value.oldPassword === this.localPassword) {
        if (changePasswordForm.value.newPassword === changePasswordForm.value.confirmPassword) {
          this.password = changePasswordForm.value.newPassword;
          this.oldPassword = changePasswordForm.value.oldPassword;
          this.userId = this.userInfo === null ?
            this.storageConfiguration.sessionGetItem(this.storageConfiguration.id) : this.userInfo[0].id;
          const body = { id: this.userId, new_password: this.password, old_password: this.oldPassword, password_update_date: '' };
          this.dataService.add(this.configuration.changePassword, body).subscribe(
            (response: Response) => {
              this.spinnerService.hide();
              this.message = response.result;
              this.storageConfiguration.sessionSetItem(this.storageConfiguration.password, this.password);
              const loginInfo: LoginInfo = {
                User_id: this.userId,
                Login_id: this.storageConfiguration.sessionGetItem(this.storageConfiguration.currentlogin)
              };
              this.dataService.add(this.configuration.logOut, loginInfo).subscribe(
                (response: LogoutResponse) => {
                  this.message = response.message;
                 }, err => {
                  this.message = err.error.message;
                  this.toastr.success(this.message);
                });
              this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.token);
              this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.password);
              this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.user_Info);
              this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.currentlogin);
              this.storageConfiguration.sessionRemoveItem(this.storageConfiguration.id);
              this.authService.logoutUser();
              this.router.navigate([this.authService.getLoginUrl()]);
              this.toastr.success('The password has been changed successfully!!');

            }, err => {
              this.spinnerService.hide();
               if (err.error.message === 'Please Enter Different Password') {
                this.toastr.warning('New Password cannot be same as Old Password');
              }
            });
        } else {
          this.spinnerService.hide();
          this.errorMessage = 'Password and Confirm Password doesnt match';
          this.toastr.warning('Password and Confirm Password doesnt match');
        }
      } else {
        this.spinnerService.hide();
        this.errorMessage = 'Please enter correct old password';
        this.toastr.warning('Please enter correct old password');
      }
    }
  }

  invalidFinder() {
    const invalid = [];
    let ValidationData = '';
    const controls = this.changePasswordForm.controls;
    for (let name in controls) {
      if (controls[name].invalid) {
        switch (name) {
          case 'oldPassword':
            name = 'Old Password';
            break;
          case 'newPassword':
            name = 'New Password must contain minimum of 8 characters.';
            break;
          case 'confirmPassword':
            name = 'Confirm Password must contain minimum of 8 characters.';
            break;
        }
        invalid.push(name);
        ValidationData += '<li>' + name + '</li>';
      }
    }

    if (invalid.length > 0) {
      ValidationData = '<h2>Please Enter The Following</h2><ol class="m-3" >' + ValidationData + '</ol>';
      this.toastr.warning(ValidationData);
    }
    return invalid;
  }
  
  backClicked(){
    this.location.back();
  }

}
