import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { DataService } from '../../../shared/service/data-service';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastBaseModel } from '../../../shared/model/toast-data-model';
import { Configuration } from '../../../app.configuration';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { ValidationCheck } from '../common-validation';
import { Location } from '@angular/common';

@Component({
  selector: 'app-error500',
  templateUrl: './error500.component.html',
  styleUrls: ['./error500.component.sass'],
  providers: [ConfirmationService]
})
export class Error500Component implements OnInit {
  issueDialog: boolean = false;
  issueCreateForm: FormGroup;
  issueTypeSelectItem: SelectItem[];
  issuePrioritySelectItem: SelectItem[];

  constructor(
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private toastr: ToastrService,
    private toastMessage: ToastBaseModel,
    private validationCheck: ValidationCheck,
    private location: Location
  ) { }

  ngOnInit() {
    this.createForm();
  }

  createForm() {
    this.issueCreateForm = this.fb.group({
      Summary: ['', Validators.required],
      Description: ['', Validators.required],
      Project_name: ['', Validators.required],
      Issue_type: ['', Validators.required],
      Priority: ['', Validators.required],
      File_paths: this.fb.array([])
    });
  }

  /**Get Issues Option */
  getIssueOptions() {
    this.spinnerService.show();
    this.dataService.getAll(this.configuration.createJiraIssue).subscribe(
      (data: any) => {
        this.issueCreateForm.patchValue({
          Project_name: data.projectList[0].Key,
        });
        this.issueTypeSelectItem = this.bindDropDown(data.issuesList, 'issues');
        this.issuePrioritySelectItem = this.bindDropDown(data.prioritesList, 'priority');
        this.issueDialog = true;
        this.spinnerService.hide();
      });

  }

  /**Create Issue Submit */
  issueSubmit(data) {
    const invalid = [];
    const controls = this.issueCreateForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    var validatorArrays
    validatorArrays = this.validationCheck.getValidation(invalid);
       
    if (invalid.length == 0) {
      this.spinnerService.show();
      this.dataService.add(this.configuration.createJiraIssue, data.value).subscribe(
        (data: any) => {
          this.toastr.success(this.toastMessage.Issue + this.toastMessage.Create);
          this.cancel();
          this.spinnerService.hide();
        });
    } else {
      this.toastr.warning(validatorArrays);
    }
  }

  /**DropDown Binding Options */
  bindDropDown(array: any[], type: string): any[] {
    var dropDownItem = [];
    array.forEach(a => {
      switch (type) {
        case 'issues':
          dropDownItem.push({ label: a.Name, value: a.Name });
          break;
        case 'priority':
          dropDownItem.push({ label: a.Name, value: a.Name });
          break;
      }
    });
    return dropDownItem;
  }

  /**Cancel Issue */
  cancel() {
    this.issueDialog = false;
    this.issueCreateForm.patchValue({
      Summary: '',
      Description: '',
      Issue_type: '',
      Priority: '',
    })
  }

  backClicked() {
    this.location.back();
  }

}
