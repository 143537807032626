import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LayoutComponent } from './core/layout/layout.component';
import { AuthGuardService } from './auth/service/auth-guard.service';



const routes: Routes = [
  {
    path: '',
    component: LayoutComponent,
    canActivate: [AuthGuardService], 

    children:
      [
        { path: '', redirectTo: 'dashboard', pathMatch: 'full' },


        {
          path: 'dashboard',
          loadChildren: () => import('./module/dashboard/dashboard.module').then(m => m.DashboardModule),
          canActivate: [AuthGuardService],

        },
        {
          path: 'customer',
          loadChildren: () => import('./module/customers/customers.module').then(m => m.CustomersModule),
          canActivate: [AuthGuardService], 
        },
        {
          path: 'facility',
          loadChildren: () => import('./module/facility/facility.module').then(m => m.FacilityModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'compliance',
          loadChildren: () => import('./module/compliance/compliance.module').then(m => m.ComplianceModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'compliance',
          loadChildren: () => import('./module/compliance/compliance.module').then(m => m.ComplianceModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'order',
          loadChildren: () => import('./module/order/order.module').then(m => m.OrderModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'production',
          loadChildren: () => import('./module/production/production.module').then(m => m.ProductionModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'users',
          loadChildren: () => import('./module/user/user.module').then(m => m.UserModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'inventory',
          loadChildren: () => import('./module/inventory/inventory.module').then(m => m.InventoryModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'setting',
          loadChildren: () => import('./module/setting/setting.module').then(m => m.SettingModule),
          canActivate: [AuthGuardService],
        },
        {
          path: 'report',
          loadChildren: () => import('./module/report/report.module').then(m => m.ReportModule),
          canActivate: [AuthGuardService],
        },



      ],

  },
  {
    path: 'error',
    loadChildren: () => import('./shared/shared.module').then(m => m.SharedModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
  }


];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  declarations: [],
  exports: [RouterModule]
})
export class AppRoutingModule { }
