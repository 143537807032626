import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';
import { Observable } from 'rxjs';



@Injectable({
    providedIn: 'root'
})
export class AuthService {
	private redirectUrl: string = '/';
	private loginUrl: string = '/login';
	private isloggedIn: boolean = false;

 
	constructor(
		private http: HttpClient,
		private storageConfiguration: StorageConfiguration
	) {
	}

	public isUserAuthenticated<T>(apiUrl: string, itemName: any): Observable<T> {
		const toAdd = JSON.stringify(itemName);
 		if (toAdd) {
 			this.storageConfiguration.sessionSetItem(this.storageConfiguration.isloggedIn, 'true');

		} else {
 			this.storageConfiguration.sessionSetItem(this.storageConfiguration.isloggedIn, 'false');
		}
		return this.http.post<T>(apiUrl, toAdd);
	}


	getRedirectUrl(): string {
		return this.redirectUrl;
	}
	setRedirectUrl(url: string): void {
		this.redirectUrl = url;
	}
	getLoginUrl(): string {
		return this.loginUrl;
	}
	logoutUser(): void {
 		this.storageConfiguration.sessionSetItem(this.storageConfiguration.isloggedIn, 'false');
	}
}