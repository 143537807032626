
import { Injectable } from '@angular/core';
import { Component, OnInit } from '@angular/core';



@Injectable({providedIn: 'root'})

export class ValidationCheck {
    valueName: any[] = [];
    invalid = [];


    validationChecker(items) {
        this.invalid = [];
        this.validationLoop(items);
        return this.invalid;

    }

    validationLoop(items) {
        const controls = items.controls;
        for (const name in controls) {
            if (controls[name].controls === undefined || name === 'Contact_name' || name === 'Account_name'  || name === 'Projected_loss' ||  name === 'Carrier_type') {
                if (controls[name].invalid) {
                    this.invalid.push(name);
                }
            } else {
                this.validationLoop(controls[name]);

            }
        }
    }


    getValidation(getValidation) {
        var requiredItems
        requiredItems = this.validationList();
        var requiredDate = [];
        getValidation.forEach(element => {
            var validationItems = [];
            requiredDate.push(requiredItems.filter(a => a.id == element));
        });
        var ValidationData = "";
        requiredDate.forEach((a, index) => {
            a.forEach((b) => {
                if (index < 5) {
                    ValidationData += '<li>' + b.value + '</li>';
                }
            });
        });
        ValidationData = '<h2>Please Enter The Following</h2><ol class="m-3" >' + ValidationData + '</ol>';
        ValidationData = ValidationData == "<ol></ol>" ? "" : ValidationData;
        return ValidationData;
    }



    getCommonValidation(requiredDate, header) {
        var ValidationData = "";
        // if(requiredDate.length > 0){
        // requiredDate.forEach((a, index) => {
        //         if (index < 5) {
        //             ValidationData += '<li>' + a + '</li>';
        //         }
        //     });
        ValidationData = '<h2>' + header + '</h2><ol class="m-3" >' + ValidationData + '</ol>';
        ValidationData = ValidationData == "<ol></ol>" ? "" : ValidationData;
        //   }
        return ValidationData;
    }

    activeValue(id) {
        // if(id != undefined){
        var name = [];
        var Label = "";
        var requiredItems = this.validationList();
        name = requiredItems.filter(a => a.id == id);
        if (name.length > 0) {
            Label = name[0].value;
        }
        return Label;
    }
    // }
    validationList() {
        var requiredFields = [];
        requiredFields = [
            { id: 'Role_name', value: 'Role Name' },
            { id: 'Location_id', value: 'Location' },

            { id: 'Name', value: 'Name' },
            { id: 'Facility', value: 'Facility' },
            { id: 'Facility_id', value: 'Facility' },

            { id: 'Iso_classification', value: 'Iso Classification' },
            { id: 'Filters_numbers', value: 'Filters Number' },
            { id: 'Square_footage', value: 'Square Footage' },
            { id: 'Last_certification_date', value: 'Last certificaiton date' },
            { id: 'Pressure_type', value: 'Pressure type' },
            { id: 'Environmental_monitoring_type', value: 'Environmental Monitoring Type' },
            { id: 'Status', value: 'Status' },

            //locaiton detail
            { id: 'Pressure_differential', value: 'Pressure Differential' },
            { id: 'Certification_interval_days', value: 'Certification Interval Days' },
            //moveEquipment
            { id: 'Classification', value: 'Classification' },
            { id: 'Location', value: 'Location' },
            { id: 'Serial_number', value: 'Serial Number' },
            { id: 'Date_installed', value: 'Date Installed' },
            { id: 'Time_required_re_certify', value: 'Time Required Re certify' },
            //moveEquipment
            { id: 'To_location', value: 'To Location' },
            { id: 'To_facility', value: 'To Facility' },
            { id: 'Notes', value: 'Notes' },
            { id: 'Requested_info', value: 'Requested Info' },
            //Request
            { id: 'Request_name', value: 'Request Name' },
            { id: 'Request_priority', value: 'Request Priority' },
            { id: 'Request_status', value: 'Request Status' },
            { id: 'Request_on', value: 'Request on' },
            { id: 'Description', value: 'Description' },
            { id: 'Request_date', value: 'Request Date' },
            { id: 'State', value: 'State' },
            { id: 'Zipcode', value: 'Zipcode' },
            { id: 'Temperature', value: 'Temperature' },
            { id: 'Facility_address', value: 'Facility Address' },
            { id: 'Training_period', value :'Training Period'},

            //Master
            { id: 'Strength', value: 'Strength' },
            { id: 'Bud', value: 'Bud' },
            { id: 'Sku', value: 'Sku' },
            { id: 'Batch_yield', value: 'Batch yield' },
            { id: 'Size_dispensed', value: 'Size dispensed' },
            { id: 'Ndc', value: 'Ndc' },
            { id: 'Barcode', value: 'UPC Barcode' },
            { id: 'Storage_condition', value: 'Storage condition' },
            { id: 'Price_per_unit', value: 'Price per unit' },
            { id: 'Cost_per_unit', value: 'Cost per unit' },
            { id: 'Days_quarantine', value: 'Days quarantine' },
            { id: 'Time_required', value: 'Time required' },
            { id: 'Aseptic_method', value: 'Compounding methodology' },
            { id: 'No_of_operators', value: 'No of operators' },
            { id: 'Compounding_methodology_classification', value: 'Compounding methodology classification' },
            { id: 'Total_time', value: 'Rph and Tech Cost' },
            { id: 'Minimum_bud_for_sale', value: 'Minimum Bud for sale' },
            { id: 'Unit', value: 'Unit' },
            { id: 'Expire_date', value: 'Expire date' },
            { id: 'Lot_number', value: 'Lot number' },
            { id: 'Container_type', value: 'Container Type' },
            { id: 'Initial_quantity', value: 'Initial Quantity' },
            { id: 'Suppliers', value: 'Suppliers' },
            { id: 'Cost_per_container', value: 'Total Cost Per Container' },
            { id: 'Received_date', value: 'Received Date' },
            { id: 'Quantity_per_container', value: 'Quantity Per Container' },
            { id: 'Containers_purchased', value: 'Containers Purchased' },
            { id: 'Bulk_container', value: 'Bulk Container' },
            { id: 'User_profile_id', value: 'Profile Name' },
            { id: 'First_name', value: 'First Name ' },
            { id: 'Last_name', value: 'Last Name ' },
            { id: 'Gender', value: 'Gender ' },
            { id: 'E_mail', value: 'Email ' },
            { id: 'User_address', value: 'User address ' },
            { id: 'Mobile_number', value: 'Mobile Number' },
            { id: 'Professional_license', value: 'Professional License ' },
            { id: 'Expiratin_date', value: 'Expiration Date ' },
            { id: 'License_state', value: 'License State ' },
            { id: 'Order_date', value: 'Order date' },
            { id: 'Order_type', value: 'Order Type' },
            { id: 'Name_of_facility', value: 'Name of Facility' },
            { id: 'Account_name', value: 'Facility Name' },
            { id: 'Contact_name', value: 'Contact Name' },
            { id: 'Email', value: 'Email' },
            { id: 'Phone_no', value: 'Phone number' },
            { id: 'Requested_date', value: 'Requested Date' },
            { id: 'Fax', value: 'Fax' },
            { id: 'Account_address', value: 'Address' },
            { id: 'Customer_type', value: 'Customer type ' },
            { id: 'Phone_number', value: 'Phone Number' },
            { id: 'Address1', value: 'Address' },
            { id: 'Country', value: 'Country' },
            { id: 'Address', value: 'Address' },
            { id: 'TSA_sample_left_hand', value: 'TSA sample left hand' },
            { id: 'TSA_sample_right_hand', value: 'TSA sample right hand' },
            { id: 'SDA_sample_left_hand', value: 'SDA sample left hand' },
            { id: 'SDA_sample_right_hand', value: 'SDA sample right hand' },
            { id: 'SDA_sample_gown_location', value: 'SDA sample gown location' },
            { id: 'TSA_sample_gown_location', value: 'TSA sample gown location' },
            { id: 'Personnel_monitoring_result', value: 'Personnel monitoring result' },
            { id: 'Product_details', value: 'Final Product' },
            { id: 'Quantity', value: 'Quantity' },
            { id: 'Quantity_dispensed_per_container', value: 'Quantity Dispensed per Container' },
            { id: 'Estimated_released_date', value: 'Estimated Release Date' },
            { id: 'Identification_code', value: 'Identification Code' }, 
            { id: 'Length', value: 'Length' },
            { id: 'Weight', value: 'Weight' },
            { id: 'Height', value: 'Height' },
            { id: 'Width', value: 'Width' },
            { id: 'Carrier_type', value: 'Carrier Type' },
            { id: 'Production_type', value: 'Production Type' },
            { id: 'Risk_type', value: 'Risk Type' },
            { id: 'Quantity_per_case', value: 'Quantity Per Case' },
            { id: 'Certification_due_date', value: 'Certification due date' },
            { id: 'Humidity', value: 'Humidity' },
            { id: 'Pressure', value: 'Pressure' },
            { id: 'Quantity_to_make', value: 'Quantity to make' },


            // batch start errors
            { id: 'NeedLocation', value: 'Location' },
            { id: 'NeedEquipment', value: 'Equipment' },
            { id: 'NeedOperator', value: 'Operator' },
            { id: 'Chemical Out of Stock', value: 'Chemical Out of Stock' },
            { id: 'Component Out of Stock', value: 'Component Out of Stock' },
            { id: 'Task_assigned_to', value: 'Assignee' },
            { id: 'Module_info', value: 'Task On' },
            { id: 'Task_date', value: 'Task Date' },
            { id: 'Priority', value: 'Priority' },
            { id: 'Training_detail', value: 'CMS Training' },
            { id: 'File_data', value: 'File Data' },
            { id: 'Nhm', value: 'Nhm' },
            { id: 'Is_active', value: 'Active' },
            { id: 'Is_strength', value: 'Strength Required' },
            { id: 'Controled_substance_type', value: 'Controlled Substance Type' },


            //activity LOG

            { id: 'Shipping_address', value: 'Shipping Address' },
            { id: 'Updated_info', value: 'Updated' },
            { id: 'Contact', value: 'Contact' },
            { id: 'Address2', value: 'Address - 2' },
            { id: 'City', value: 'City' },
            // { id: 'id', value: 'id' },
            { id: 'By', value: 'By' },
            { id: 'Date', value: 'Date' },
            { id: 'Payment_type', value: 'Payment Type' },
            { id: 'Assigned_sku', value: 'Assigned Sku' },
            { id: 'Discount_percentage', value: 'Discount Percentage' },
            { id: 'Interval_days', value: 'Interval Days' },
            { id: 'Retired_date', value: 'Retired Date' },
            { id: 'Move_equipment', value: 'Move Equipment' },

            { id: 'Phone', value: 'Phone number' },
            { id: 'Special_notes', value: 'Notes' },
            { id: 'Hazardous_compounding', value: 'Hazardous Compounding' },
            { id: 'In_house_calibration', value: 'In House Calibration' },
            { id: 'Request_owner', value: 'Request Owner' },

            { id: 'Assigned_user', value: 'Assigned User' },



            { id: 'Note', value: 'Note' },


            { id: 'Is_strength', value: 'Strength Required' },
            { id: 'Compliance_chemical', value: 'Compliance Chemical' },
            { id: 'Easypost_service_level', value: 'Easypost Service Level' },
            { id: 'Transaction_category', value: 'Type' },

            // { id: 'Controled_substance_type', value: 'Controled Substance Type'},




            { id: 'Minimum_expiration_date', value: 'Minimum Expiration Date' },

            { id: 'Company_name', value: 'Company Name' },
            { id: 'Calibration_interval', value: 'Calibration Interval' },
            { id: 'Theme', value: 'Theme' },
            { id: 'RPH_rate', value: 'RPH Rate' },
            { id: 'Qualification_interval', value: 'Qualification Interval' },
            { id: 'Tech_rate', value: 'Tech Rate' },

            { id: 'id', value: 'Master Formulation Record' },

            { id: 'Calibration', value: 'Calibration' },
            { id: 'Validation', value: 'Validation' },
            { id: 'Preventative_maintenance', value: 'Preventative Maintenance' },
            { id: 'Training_classification', value: 'Training Classification' },
            { id: 'Operator', value: 'Operator' },
            { id: 'Training_status', value: 'Training Status' },
            { id: 'Date_of_training', value: 'Date of Training' },
            { id: 'Date_of_recertification', value: 'Date of Recertification' },
            { id: 'Summary', value: 'Issue Title' },
            { id: 'Project_name', value: 'Project Name' },
            { id: 'Issue_type', value: 'Issue Type' },
            { id: 'Calibration', value: 'Calibration' },
            { id: 'Preventative_maintenance', value: 'Validation' },
            { id: 'Preventative_maintenance', value: 'Preventative Maintenance' },
            { id: 'User_role_id', value: 'Role' },
            { id: 'Sample_collection_date', value: 'Sample Result Date' },
            { id: 'Daterange_with', value: 'By Date' },
            { id: 'Start_date', value: 'From Date' },
            { id: 'End_date', value: 'To date' },
            { id: 'Sort_with', value: 'Sort By' },
            { id: 'Range', value: 'Range' },
            { id: 'Sort_by', value: 'Order By' },
            { id: 'Apseptic_method', value: 'Compounding Methodology' },
            { id: 'Cleaning_type', value: 'Cleaning Type' },
            { id: 'Training_detail_id', value: 'Compounding Methodology / Equipment' },
            // { id: 'Current_date', value: 'Current Date' },
            { id: 'Time_required', value: 'Time Required' },
            { id: 'Compounding_methodology', value: 'Compounding Methodology' },
            { id: 'Equipment', value: 'Equipment' },
            { id: 'Npi', value: 'Npi' },
            { id: 'Product_code', value: 'NDC' },
            { id: 'Projected_loss', value: 'Projected Loss' },
            
            // Pharmacy Type check
            { id: 'Pharmacy_type', value: 'Pharmacy Type' },

        ];

        return requiredFields;
    }

}


