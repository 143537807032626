import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import {AuthService} from '../../../auth/service/auth.service';

@Component({
  selector: 'app-error404',
  templateUrl: './error404.component.html',
  styleUrls: ['./error404.component.sass']
})
export class Error404Component implements OnInit {

  
  constructor(private authService: AuthService,
    private router:Router) { }

  ngOnInit() {
  }

  login(){
    this.router.navigate([ this.authService.getLoginUrl() ]);
  }
}


















