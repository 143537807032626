import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-timer-input',
  templateUrl: './timer-input.component.html',
  styleUrls: ['./timer-input.component.sass']
})
export class TimerInputComponent implements OnInit {

 
  timeElapsedValidator: string;
  @Input() index: any;
  @Input() staticTime: number = 0;
  @Input() clearValue: string = '';
  @Input() BatchDetail: any;
  @Output() BatchTimerEmitter = new EventEmitter();


  hour: number = 0;
  minutes: number = 0;
  // @Input() staticTime : number = 0;
  
  constructor(
    private toastr: ToastrService,
  ) { 
    
  }


  ngOnInit(): void {
       
    if (this.staticTime > 0) {
      this.minutes = this.staticTime / 60;
    }
    if (this.clearValue != '') {
      this.minutes = 0;
    }

  }

  onChange(event) {
       
    var value = this.minutes * 60;
    // var  value  = (this.hour * 360) + (this.minutes * 60) + (this.seconds)
    let obj = { "time": value, "index": this.index }

    this.TimeElapsedValidator(obj);
    if (this.timeElapsedValidator == '') {
      this.BatchTimerEmitter.emit(obj);
    }
    else {
      this.toastr.warning(this.timeElapsedValidator);
      this.minutes = 0;
    }
  }

  TimeElapsedValidator(event) {
    var HighLevelProcess = this.BatchDetail.Master_formulation_record.Apseptic_method.High_level_process;
    switch (event.index) {
      case 0:
        if (this.BatchDetail.Start_date_and_time == '' || this.BatchDetail.Start_date_and_time == undefined) {
          this.timeElapsedValidator = 'Please Enter the Start Time';
        }
        else {
          this.timeElapsedValidator = '';
        }
        break;

      default:
        if (HighLevelProcess[event.index - 1].Time_elapsed == '' || HighLevelProcess[event.index - 1].Time_elapsed == 0) {
          this.timeElapsedValidator = 'Please Check the Previous Step Time';
        }
        else {
          this.timeElapsedValidator = '';
        }

        break;
    }

  }

}
