import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class CustomRoutingName {
  // Dashboard
  dashboard = '';
  sales_dashboard: string = this.dashboard + '/dashboard/dashboard-sale';
  production_dashboard: string = this.dashboard + '/dashboard/dashboard-production';
  Monitor_dashboard: string = this.dashboard + '/dashboard/dashboard-monitor';
  // User Module
  user = './users';
  user_create: string = this.user + '/create-user';
  user_list: string = this.user + '/user-list';
  user_detail: string = this.user + '/user-detail/';
  role_list: string = this.user + '/role-list/';
  role_detail: string = this.user + '/role-detail/';
  profile_list: string = this.user + '/profile-list/';
  profile_detail: string = this.user + '/profile-detail/';
  // Compliance Module
  compliance = './compliance';
  aseptic_list: string = this.compliance + '/manage-aseptic-process-list/';
  aseptic_detail: string = this.compliance + '/manage-aseptic-process-detail/';
  aseptic_Process = '/asepticProcess/';
  training_list: string = this.compliance + '/training-list/';
  training_detail: string = this.compliance + '/training-detail/';
  training_result_detail: string = this.compliance + '/training-result-detail/';
  New_record: string = this.training_result_detail + '/NewRecord';
  environmental_monitoring_list: string = this.compliance + '/environmental-monitoring-list/';
  environmental_monitoring_detail: string = this.compliance + '/environmental-monitoring-detail/';
  personnel_monitoring_list: string = this.compliance + '/personnel-monitoring-list/';
  personnel_monitoring_detail: string = this.compliance + '/personnel-monitoring-detail/';
  release_management_list: string = this.compliance + '/release-management-list/';
  release_management_detail: string = this.compliance + '/release-management-detail/';
  calendar_compliance: string = this.compliance + '/compliance-calendar/';
  task_type: string = this.compliance + '/task-type/';
  task_create: string = this.compliance + '/task_create/';
  task_detail: string = this.compliance + '/task_detail/';
  notification_list: string = this.compliance + '/notification-list/';
  notification_detail: string = this.compliance + '/notification-detail/';
  // Production Module
  production = './production';
  master_formulation_list: string = this.production + '/master-formulation-list/';
  master_formulation_detail: string = this.production + '/master-formulation-record-detail/';
  calendar_production: string = this.production + '/production-calendar/';
  batch_list: string = this.production + '/manage-batch-list/';
  batch_detail: string = this.production + '/manage-batch-detail/';
  production_scheduler: string = this.production + '/production-scheduler/';
  batch_start_record: string = this.production + '/manage-batch-start/';

  // Order Module
  order = './order';
  order_list: string = this.order + '/order-list/';
  new_list: string = this.order + '/order-new/';
  order_ready_for_shipping: string = this.order + '/order-ready-for-shipping/';
  order_shipped: string = this.order + '/order-shipped/';
  order_cancelled: string = this.order + '/order-cancelled/';

  order_received: string = this.order + '/order-received/';
  order_returned: string = this.order + '/order-returned/';
  order_dispensed: string = this.order + '/order-dispensed/';

  order_out_of_stock: string = this.order + '/order-out-of-stock/';
  released_list: string = this.order + '/order-released/';
  in_House_detail: string = this.order + '/order-in-house-detail/';
  order_external_detail: string = this.order + '/order-external-detail/';
  order_detail: string = this.order + '/order-detail/';
  // Inventory Module
  inventory = './inventory';
  component_master_list: string = this.inventory + '/component-master-list/';
  component_master_detail: string = this.inventory + '/component-master-detail/';
  component_master_child: string = this.inventory + '/component-child-list/';
  chemical_master_list: string = this.inventory + '/chemical-master-list/';
  chemical_master_detail: string = this.inventory + '/chemical-master-detail/';
  chemical_child_list: string = this.inventory + '/chemical-child-list/';
  final_product_list: string = this.inventory + '/final-product-list/';
  final_product_detail: string = this.inventory + '/final-product-detail/';
  final_product_lot_list: string = this.inventory + '/final-product-lot-list/';
  // Facility Module
  facility = './facility';
  request_detail: string = this.facility + '/request-detail/';
  request_list: string = this.facility + '/request-list/';
  facility_calendar: string = this.facility + '/facility-calendar/';
  equipment_list: string = this.facility + '/equipment-list/';
  equipment_detail: string = this.facility + '/equipment-detail/';
  location_list: string = this.facility + '/location-list/';
  location_detail: string = this.facility + '/location-detail/';
  facility_detail: string = this.facility + '/facility-detail/';
  facility_list: string = this.facility + '/facility-list/';
  // Customer Module
  customer = './customer';
  customer_list: string = this.customer + '/customer-list/';
  customer_detail: string = this.customer + '/customer-detail/';
  // Setting Module
  setting = './setting';
  setting_detail: string = this.setting + '/company-setting';
  application_setting = this.setting + '/application-setting';
  report_an_issue = this.setting + '/report-an-issue';
  // Audit Module
  audit = './report';
  audit_trails: string = this.audit + '/audit-trails';

  other_notification = './other-notification/';
  // report Module

  inventory_report = '/inventory-report';
  order_report = '/order-report';
  customer_report = '/customer-report';
  sales_report = '/sales-report';
  recall_report = '/recall-report';
  em_report = '/em-report';
  user_report = '/user-report';
  equipment_report = '/equipment-report';
  facility_report = '/facility-report';
  production_report = '/production-report';
  out_of_specfication_report = '/out-of-specification';
  pass_report = '/pass-report';
  report = '/report';






}
