import { Component, OnInit } from '@angular/core';
import { Routes, RouterModule, Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-error-site-blocked',
  templateUrl: './error-site-blocked.component.html',
  styleUrls: ['./error-site-blocked.component.sass']
})
export class ErrorSiteBlockedComponent implements OnInit {

  constructor(    private router: Router ) { }

  ngOnInit() {
  }
  login(){
    this.router.navigate(['login'])
  }

}
