import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '@app/app.configuration';
import { Router } from '@angular/router';
import { CustomRoutingName } from '@app/app.custom-routing-name';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { Filedatamodel } from '@app/shared/model/file-process.interface';
import { yearCalculate } from '@app/shared/common/common-helper';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { FilePatchComponent } from '@app/shared/common/file-upload/file-upload.patch.component';
import { ValidationCheck } from '@app/shared/common/common-validation';
import { EMDetail } from '../../../../module/compliance/model/environment-monitoring.interface';
import { GenerateId } from '@app/shared/common/common-helper';
import { AuditFiles } from '@app/shared/common/audit-File';
import { StorageConfiguration } from '../../storage-setting/storage-configuration';
import { SettingHelper } from '@app/shared/common/company-setting-helper';
import { NotificationModel } from '@app/shared/model/notification-model.interface';
import { Equipment, responseResult } from '@app/module/compliance/model/training.interface';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { GetEquipment } from '@app/module/facility/model/equipment.interface';
import { LazyLoadEvent } from 'primeng/primeng';

@Component({
  selector: 'app-environment-monitoring-add',
  templateUrl: './environment-monitoring-add.component.html',
  styleUrls: ['./environment-monitoring-add.component.sass'],
  providers: [ConfirmationService, Filedatamodel]


})
export class EnvironmentMonitoringAddComponent implements OnInit {

  /**Local Variables */
  emDetEdit: boolean;
  EM_details: FormGroup;
  monitorForm: FormGroup;
  locationList: any;
  equipmentName: any;
  locationId: any;
  equipment: any[];
  equipmentList: any[];
  batch = false;
  batchDetails: any;
  particleCountSelectItem: SelectItem[];
  sampleLocationSelectItem: SelectItem[];
  intervalDay: any;
  cancelEm: void;
  batchStart = false;
  @Input() batchId;
  @Input() emResult;
  @Input() emEdit;
  @Input() mode;
  @Input() emEditID;
  @Output() CancelDialog = new EventEmitter<any>();
  locationOptions: any;
  location: any;
  facility: any;
  intervalDate: Date;
  date: Date;
  yrRange: string;
  keyCode: number;
  emForm: FormGroup;
  environmentalMonitoringList: any;
  emDialog: boolean;
  facilitySelectItem: SelectItem[];
  locationSelectItem: SelectItem[];
  statusSelectItem: SelectItem[];
  equipementItems: SelectItem[];
  Attachment = 'Attachment';
  newRecord = 'NewRecord';
  referenceTypeName = 'EM';
  accessuri: string;
  activity = [];
  isEdit = false;
  resultSelectItem: SelectItem[];
  emList = [];
  emListDetails: any;
  EMDetail: EMDetail[] = [];
  emDetIndex: any;
  currentDate: any;
  locationLen: any;
  editEMDet: boolean;
  addEM: boolean;
  sampleLocArray = [];
  viablesampleLocArray = [];
  SelectedValue: any[] = [];
  particleCount: any[] = [];
  componentInventorySelectItem: SelectItem[];
  lotNumberSelectItemAir: any[] = [];
  lotNumberSelectItemSurface: any[] = [];
  disColl: boolean;
  addButton = false;
  editButton = false;
  userInfo: any;
  notificationModel: NotificationModel;
  notificationForm: FormGroup;
  temperature: string;
  isDropdown: boolean = false;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private router: Router,
    private fb: FormBuilder,
    private customRoutingName: CustomRoutingName,
    private filePatch: FilePatchComponent,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private yearRange: yearCalculate,
    private validationCheck: ValidationCheck,
    private generate: GenerateId,
    private confirmationService: ConfirmationService,
    private auditFiles: AuditFiles,
    private storageConfiguration: StorageConfiguration,
    private settingHelper: SettingHelper,
    private ngxService: NgxUiLoaderService,

  ) { }

  ngOnInit() {
    this.createForm();
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.addEM = false;
    this.EM_details.patchValue({
      Status: 'In-Process',
      None_sampling_locations: true
    });
    if (this.mode === 'batch') {
      this.batch = true;
      if (this.emEdit !== undefined) {
        this.getEMBatchId();
        this.isEdit = true;
        this.addEM = true;
      } else {
        this.getBatch();
      }
    } else if (this.mode === 'batchStart') {
      this.batch = true;
      this.batchStart = true;
      this.getBatch();
    } else {
      this.getFacility();
      this.spinnerService.show();
    }
    this.GetAllEquipment();
    this.GetAllComponent();
    this.statusSelectItem = [
      { label: 'In-Process', value: 'In-Process' },
      { label: 'Collected', value: 'Collected' }
    ];
    this.resultSelectItem = [
      { label: 'Pass', value: 'Pass' },
      { label: 'Fail', value: 'Fail' }
    ];
    this.yrRange = this.yearRange.yrRange;
    this.spinnerService.show();
    this.currentDate = new Date();
  }

  /*
  ** ==========================================================
  ** Form Initialization
  ** ==========================================================
  */
  createForm() {
    this.emForm = this.fb.group({
      EM_details: [],
      Batch_id: [''],
      Notes: [''],
      Facility: this.fb.group({
        id: ['', Validators.required],
        Name: ['']
      }),
      Activity_log: this.fb.array([]),
      File_data: Filedatamodel,
      id: [''],
      Kind: [''],
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Lot_number: ['']
    });
    this.EM_details = this.fb.group({
      Location: this.fb.group({
        id: ['', Validators.required],
        Name: ['']
      }),
      Equipment_list: this.fb.group({
        id: [''],
        Name: ['']
      }),
      Component_List_air: this.fb.group({
        id: [''],
        Name: ['']
      }),
      Component_List_surface: this.fb.group({
        id: [''],
        Name: ['']
      }),
      Requested_date: [''],
      Sample_collection_date: [''],
      Status: [''],
      Environmental_monitoring_result: [''],
      Viable_air_sampling_locations: false,
      Viable_surface_sampling_locations: false,
      Nonviable_air_sampling_locations: false,
      None_sampling_locations: false,
      Sampling_location: [],
      Viable_Sampling_Location: [],
      Sampling_location_result: [''],
      Particle_count: [],
      Temperature: ['', Validators.required],
      Pressure: ['', Validators.required],
      Humidity: ['', Validators.required],
      Equipment: this.fb.array([]),
      id: ['']
    });
    this.notificationForm = this.fb.group({
      NotificationInfo: this.fb.group({
        Severity: [''],
        Module_type: [''],
        Tenant_id: [''],
        Message: [''],
        Supporting_fields: [''],
        Action: [''],
        Current_user: this.fb.group({
          id: [''],
          Name: ['']
        })
      }),
      Payload: ['']
    });
  }

  /*
  ** ==========================================================
  ** Edit Patch Value For Batch Edit
  ** ==========================================================
  */
  editPatchValue(id) {
    const emDet = this.EMDetail.filter(a => a.id === id)[0];
    this.emDetIndex = this.EMDetail.findIndex(a => a.id === id);
    this.emDetEdit = true;
    this.EM_details.patchValue({
      Location: {
        id: emDet.Location.id,
        Name: emDet.Location.Name
      },
      Status: emDet.Status,
      Requested_date: new Date(emDet.Requested_date),
      Sample_collection_date: new Date(emDet.Sample_collection_date),
      Equipment_list: {
        id: emDet.Equipment_list.id,
        Name: emDet.Equipment_list.Name
      },
      Component_List_air: {
        id: emDet.Component_List_air.id,
        Name: emDet.Component_List_air.Name
      },
      Component_List_surface: {
        id: emDet.Component_List_surface.id,
        Name: emDet.Component_List_surface.Name
      },
      Environmental_monitoring_result: emDet.Environmental_monitoring_result,
      Viable_air_sampling_locations: emDet.Viable_air_sampling_locations,
      Viable_surface_sampling_locations: emDet.Viable_surface_sampling_locations,
      Nonviable_air_sampling_locations: emDet.Nonviable_air_sampling_locations,
      None_sampling_locations: emDet.None_sampling_locations,
      Sampling_location: emDet.Sampling_location,
      Viable_Sampling_Location: emDet.Viable_Sampling_Location,
      Sampling_location_result: emDet.Sampling_location_result,
      Particle_count: emDet.Particle_count,
      Temperature: emDet.Temperature,
      Pressure: emDet.Pressure,
      Humidity: emDet.Humidity,
      Equipment: emDet.Equipment === null || emDet.Equipment === undefined ? [] : emDet.Equipment,
      id: emDet.id
    });
    this.equipmentList = emDet.Equipment;
    this.sampleLocArray = emDet.Sampling_location;
    this.viablesampleLocArray = emDet.Viable_Sampling_Location;
    this.particleCount = emDet.Particle_count;
    this.addEM = true;
    this.editButton = true;
    this.particleCountBinder(emDet.Particle_count);
    this.disableField();
  }
  /*
  ** ==========================================================
  ** Get EM Values
  ** ==========================================================
  */
  getFacility() {
    this.dataService.getSingle('NewRecord', this.configuration.environmentalMonitoring + '/').subscribe(
      (data: any) => {
        this.location = data.options.Location;
        this.intervalDay = data.options.CompanySettings.Interval_day;
        this.temperature = data.options.CompanySettings.Temperature;
        this.facilitySelectItem = this.bindDropDown(data.options.Facility, 'Facility');
        this.locationSelectItem = this.bindDropDown(data.options.Location, 'Location');
        this.particleCountSelectItem = this.settingHelper.getReleaseCriteria(data.options.CompanySettings.Particle_count.Value, true, '');
        this.particleCountBinder(this.particleCountSelectItem);
        this.date = new Date();
        this.intervalDate = new Date();
        this.intervalDate.setDate(this.date.getDate() + this.intervalDay);
        this.EM_details.patchValue({
          Requested_date: this.date,
          Sample_collection_date: new Date(this.intervalDate)
        });
        this.spinnerService.hide();
        if (this.facilitySelectItem.length > 0) {
          const defaultFacility = this.facilitySelectItem[0];
          this.emForm.patchValue({ Facility: { id: defaultFacility.value, Name: defaultFacility.label } });
          this.facilityOnChange(defaultFacility)
        }
       
      });
  }
  // *** Get all Equipment *** //
  GetAllEquipment() {
    this.dataService.getAll(this.configuration.equipment + `?page=1&pageSize=10000`).subscribe(
      (data: any) => {
        this.equipementItems = this.bindClassification(data.result.length > 0 ? data.result : [], false, false, 'Current', '', false);
        if (this.equipementItems.length > 0) {
          const defaultEquipment = this.equipementItems[0];
          this.EM_details.patchValue({ Equipment_list: { id: defaultEquipment.value, Name: defaultEquipment.label } });
        }
      },     
      (error) => {
        console.error('Error fetching equipment data:', error);
      }
    );   
  }

  // *** Get all Component *** //

  GetAllComponent() {
    this.dataService.getAll(this.configuration.componentInventory + `?page=1&pageSize=10000`).subscribe(
      async (component: any) => {

        this.componentInventorySelectItem = this.bindClassification(component.result.length > 0 ? component.result : [], true, true, '', '', false);
        if (this.componentInventorySelectItem.length > 0) {
          const defaultComponent_air = this.componentInventorySelectItem[this.componentInventorySelectItem.length - 1];
          this.EM_details.patchValue({ 
            Component_List_air: { id: defaultComponent_air.value, Name: defaultComponent_air.label },
            Component_List_surface: { id: defaultComponent_air.value, Name: defaultComponent_air.label }
          });
          this.GetLotNumberById(defaultComponent_air,'air');
          await this.GetLotNumberById(defaultComponent_air,'surface')
        }
      },
      (error) => {
        console.error('Error fetching Component data:', error);
      }
    );
  }


  // *** Get lot number *** //
  GetLotNumber(event, type: string) {
    this.dataService.getAll(this.configuration.componentChildInventory + `?page=1&pageSize=10000&Master_id=${event.value}`)
      .subscribe((component: any) => {
        if (type == "air") {
          this.sampleLocArray.forEach((element: any) => { 
            element.SL_lotA = '', element.SL_lotB = '' })
          this.lotNumberSelectItemAir = this.bindClassification(component.result.length > 0 ? component.result : [], false, false, '', "Released", false);
        } else if (type == "surface") {
          this.viablesampleLocArray.forEach((element: any) => { 
            element.VSS_lot= '' })
          this.lotNumberSelectItemSurface = this.bindClassification(component.result.length > 0 ? component.result : [], false, false, '', "Released", false);
        }
      },
        (error) => {
          console.error('Error fetching lotnumber data:', error);
        }
      );
  }

  async GetLotNumberById(event, type: string) {
      this.dataService.getAll(this.configuration.componentChildInventory + `?page=1&pageSize=10000&Master_id=${event.value ? event.value : event}`)
        .subscribe((component: any) => {
          if (type == "air") {
            this.lotNumberSelectItemAir = this.bindClassification(component.result.length > 0 ? component.result : [], false, false, '', "Released", false);
          } else if (type == "surface") {
            this.lotNumberSelectItemSurface = this.bindClassification(component.result.length > 0 ? component.result : [], false, false, '', "Released", false);
          }
        },
          (error) => {
            console.error('Error fetching lotnumber data:', error);
          }
        );
    }

  bindClassification(array: any[], isActive: boolean, isMedia: boolean, Status: string, status: string, expired: boolean) {
    const dropDownItem = array.reduce((acc, element) => {
      if (isActive && element.Is_active === isActive && element.Is_media === isMedia) {
        acc.push({ value: element.id, label: element.Name });
      } else if (Status && element.Status === Status) {
        acc.push({ value: element.id, label: element.Name });
      } else if (!Status && element.Status === status && element.Is_expired === expired) {
        acc.push({ value: element.id, label: element.Lot_number });
      }
      return acc;
    }, []);

    return dropDownItem;
  }

  /*
  ** ==========================================================
  ** Get EM Values By Batch Id
  ** ==========================================================
  */
  getBatch() {
    this.dataService.getSingle(this.batchId, this.configuration.environmentalMonitoring + '/BatchById/').subscribe(
      (data: any) => {
        this.batchDetails = data.result;
        this.equipment = this.batchDetails.Equipment;
        if (this.emResult !== undefined) {
          this.locationList = this.emResult.map(item => item.Location.id);
          const filteredLocation = data.result.Location.filter(item => this.locationList.indexOf(item.id) === -1);
          this.locationSelectItem = this.bindDropDown(filteredLocation, 'Location');
        } else {
          this.locationSelectItem = this.bindDropDown(data.result.Location, 'Location');
        }
        this.particleCountSelectItem = this.settingHelper.getReleaseCriteria(data.options.Particle_count.Value, true, '');
        this.particleCountBinder(this.particleCountSelectItem);
        this.facilitySelectItem = data.result.Facility;
        this.facilitySelectItem = [
          { label: data.result.Facility.Name, value: data.result.Facility.id }
        ];
        this.date = new Date();
        this.intervalDay = data.options.Interval_day;
        this.intervalDate = new Date();
        this.intervalDate.setDate(this.date.getDate() + this.intervalDay);
        this.locationLen = this.locationSelectItem.length;
        this.emForm.patchValue({
          Batch_id: data.result.id,
          Facility: {
            id: data.result.Facility.id,
            Name: data.result.Facility.Name
          },
          Lot_number: data.result.Lot_number
        });
        this.EMBatchRecord();
        this.spinnerService.hide();
      });
  }

  /*
  ** ==========================================================
  ** bind values for drop down
  ** ==========================================================
  */
  bindDropDown(array: any[], ddType: string): any[] {
    const dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case 'Facility':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'Location':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'Particle Count':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'multiselect':
          dropDownItem.push({ label: a.Value, value: { id: a.Value, Name: a.Value } });
          break;
        case 'sampleLocation':
          dropDownItem.push({ label: a.Name, value: { id: a.id, Name: a.Name } });
          break;
      }
    });
    return dropDownItem;
  }
  /*
  ** ==========================================================
  ** Uploaded File Patch
  ** ==========================================================
  */
  receiveFileUploadResponse($event) {
    const getfileresult = this.filePatch.genericFilePatch($event, this.emForm);
    this.emForm.patchValue({ File_data: getfileresult });
  }

  /*
  ** ==========================================================
  ** Faiclity Dropdown OnChange
  ** ==========================================================
  */
  facilityOnChange(event) {
    this.facility = event.value;
    const facilityName = this.facilitySelectItem.filter(a => a.value === this.facility)[0];
    this.emForm.patchValue({
      Facility: {
        Name: facilityName.label
      }
    });
    if (this.mode !== 'batch') {
      this.locationOptions = this.location.filter(x => x.Facility_id === this.facility);
      this.locationSelectItem = this.bindDropDown(this.locationOptions, 'Location');
    }
    this.EM_details.patchValue({
      Location: {
        id: '',
        Name: ''
      }
    });
  }
  /*
  ** ==========================================================
  ** Location Dropdown OnChange
  ** ==========================================================
  */
  locationOnChange(event) {
    this.spinnerService.show();
    let locId = event.value;
    const locationName = this.locationSelectItem.filter(a => a.value === locId)[0];
    this.EM_details.patchValue({
      Location: {
        Name: locationName.label
      }
    });
    this.dataService.getSingle<any>(locId, this.configuration.location + '/GetSamplingLocation/')
      .subscribe((data: any) => {
        if (data.message.result.Sampling_location !== null && data.message.result.Sampling_location !== undefined) {
          this.sampleLocationBinder(data.message.result.Sampling_location);
        }
       
        if (data.message.result.Viable_Sampling_Location !== null && data.message.result.Viable_Sampling_Location !== undefined) {
          this.viableSampleLocationBinder(data.message.result.Viable_Sampling_Location)
        }else{
          this.viablesampleLocArray = [];
        }

        if(data.message.result.Sampling_location.length == 0){this.isDropdown = true;}
        else{this.isDropdown=false;}
        if(data.message.result.Viable_Sampling_Location.length == 0){this.isDropdown = true;}
        else{this.isDropdown=false;}
       
        this.spinnerService.hide();
      });

    if (this.mode === 'batch') {
      this.locationId = this.equipment.filter(a => a.Location_id === locId);
      let Equipment_id = this.locationId.map(o => {
        return { id: o.id };
      });
      if (this.locationId !== undefined) {
        this.monitorForm = this.fb.group({
          Batch_id: this.batchId,
          Location_id: this.location,
          Equipment_id: this.fb.array(Equipment_id)
        });
        this.dataService.add<any>(this.configuration.environmentalMonitoring + '/EMByLocationId/', this.monitorForm.value)
          .subscribe((data: any) => {
            this.equipmentList = data.options;
            this.emForm.value.Equipment = this.equipmentList;
          });
      }
    }
    
  }
  /*
  ** ==========================================================
  ** Save EM
  ** ==========================================================
  */
  onSubmit() {
    const invalid = [];
    const controls = this.emForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    let validatorArrays;
    validatorArrays = this.validationCheck.getValidation(invalid);

    if (invalid.length === 0) {
      if (this.EMDetail.length > 0) {
        if(this.EM_details.value.Viable_air_sampling_locations && this.EM_details.value.Sampling_location){
          this.toastr.warning('Please Add the Viable Air Sampling Table');
          return;
        }
        if(this.EM_details.value.Viable_surface_sampling_locations && this.EM_details.value.Viable_Sampling_Location){
          this.toastr.warning('Please Add the Viable Surface Sampling Table');
          return;
        }

        this.emForm.value.Kind = 'environmental_monitoring';
        this.emForm.value.id = '';
        this.emForm.value.Created_info.By = this.userInfo[0].id;
        this.emForm.value.Created_info.Name = this.userInfo[0].name;
        this.emForm.value.Created_info.Date = new Date().toISOString();
        this.emForm.value.EM_details = this.EMDetail;
        this.emForm.value.Is_active = true;
        this.emForm.value.Is_deleted = false;
        if (this.mode === 'normal') {
          let activeModel = [];
          activeModel = this.auditFiles.newCreateActivity('Facility', this.emForm.value.Facility.Name);
          this.activity.forEach(data => {
            activeModel.push(data);
          });
          this.emForm.value.Activity_log = activeModel;

          this.dataService.add(this.configuration.environmentalMonitoring, this.emForm.value).subscribe(
            (data: any) => {
              this.router.navigate([this.customRoutingName.environmental_monitoring_detail + data.message.id]);
              this.toastr.success(this.toastMessage.EnvironmentalMonitoring + ' ' + this.toastMessage.Create);
              this.emForm.value.EM_details.forEach(element => {

                if (element.Environmental_monitoring_result == 'Fail') {
                  this.emForm.value.id = data.message.id;
                  this.notificationForm.patchValue({
                    Payload: this.emForm.value
                  });

                  this.notificationForm.patchValue({
                    NotificationInfo: {
                      Severity: 'High',
                      Module_type: 'EM',
                      Tenant_id: this.dataService.getTenentId(),
                      Message: element.Location.Name + ' failed in environmental monitoring',
                      Supporting_fields: '',
                      Action: 'Update',
                      Current_user: {
                        id: this.userInfo[0].id,
                        Name: this.userInfo[0].name
                      }
                    }
                  });

                  this.dataService.add(this.configuration.taskNotification, this.notificationForm.value).subscribe(
                    () => {
                    });
                }
              });
            });
        } else {
          if (this.EMDetail.length >= this.locationLen) {
            this.dataService.add(this.configuration.environmentalMonitoring, this.emForm.value).subscribe(
              (data: any) => {
                if (this.mode === 'normal') {
                  this.router.navigate([this.customRoutingName.environmental_monitoring_detail + data.message.id]);
                } else if (this.mode === 'batchStart') {
                  this.CancelDialog.emit('saveNext');
                } else {
                  this.cancelEm = this.emForm.reset();
                  this.CancelDialog.emit(this.cancelEm);
                }
                this.toastr.success(this.toastMessage.EnvironmentalMonitoring + ' ' + this.toastMessage.Create);
              });
          } else {
            this.toastr.warning('Please complete EM for all locations verified.');
          }
        }
      } else {
        this.toastr.warning('Please Add Atleast One EM Location');
      }
    } else {
      this.toastr.warning(validatorArrays);
    }
  }
  keyDownFunction(event) {
    if (event.keyCode === 13) {
      this.keyCode = 13;
    }
  }
  /*
  ** ==========================================================
  ** Cancel Popup
  ** ==========================================================
  */
  cancel() {
    this.cancelEm = this.emForm.reset();
    this.CancelDialog.emit(this.cancelEm);
  }
  /*
  ** ==========================================================
  ** Check Box OnChange
  ** ==========================================================
  */
  radioOnChange(event, type) {

    switch (type) {
      case 'Viable_air_sampling_locations':
        this.EM_details.patchValue({
          None_sampling_locations: false
        });
        if(this.sampleLocArray.length == 0){this.isDropdown = true;}
       
        break;
      case 'Viable_surface_sampling_locations':
        this.EM_details.patchValue({
          None_sampling_locations: false
        });
        if(this.viablesampleLocArray.length == 0){this.isDropdown = true;}
        break;
      case 'Nonviable_air_sampling_locations':
        this.EM_details.patchValue({
          None_sampling_locations: false
        });
        this.particleCountBinder(this.particleCountSelectItem);
        break;
      case 'None_sampling_locations':
        this.EM_details.patchValue({
          None_sampling_locations: true,
          Viable_air_sampling_locations: false,
          Viable_surface_sampling_locations: false,
          Nonviable_air_sampling_locations: false,
        });
        break;


    }



    if (!this.EM_details.get('Nonviable_air_sampling_locations').value && !this.EM_details.get('None_sampling_locations').value) {
      this.EM_details.patchValue({
        Environmental_monitoring_result: '',

      });
    }


  }
  /*
  ** ==========================================================
  ** Update EM In Batch
  ** ==========================================================
  */
  updateEM(data) {
    const invalid = [];
    const controls = this.emForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
    let validatorArrays;
    validatorArrays = this.validationCheck.getValidation(invalid);
    if (invalid.length === 0) {
      this.spinnerService.show();
      data.value.Updated_info.By = this.userInfo[0].id;
      data.value.Updated_info.Name = this.userInfo[0].name;
      data.value.Updated_info.Date = new Date().toISOString();
      data.value.id = this.emForm.value.id;
      data.value.EM_details = this.EMDetail;
      this.dataService.update(data.value.id, this.configuration.environmentalMonitoring + '/', data.value).subscribe(
        () => {
          this.spinnerService.hide();
          if (this.mode !== 'batch') {
            this.router.navigate([this.customRoutingName.environmental_monitoring_detail + data.value.id]);
          } else {
            this.cancelEm = this.emForm.reset();
            this.CancelDialog.emit(this.cancelEm);
          }
          this.toastr.success(this.toastMessage.EnvironmentalMonitoring + ' ' + this.toastMessage.Update);
        });
    } else {
      this.toastr.warning(validatorArrays);
    }
  }

  saveNext(data) {
    this.onSubmit();
  }
  /*
  ** ==========================================================
  ** Add EM Details
  ** ==========================================================
  */
  addEMDetails(data) {
    data.Sampling_location = this.sampleLocArray;
    data.Viable_Sampling_Location = this.viablesampleLocArray;
    let hasError = false; 
    if(this.EM_details.value.Viable_air_sampling_locations == true){
    data.Sampling_location.forEach((airsample: any) =>{
      const isLotAEmpty = airsample.SL_lotA.length === 0;
      const isLotBEmpty = airsample.SL_lotB.length === 0; 
      const isQuantityAEmpty = airsample.SL_quantityA.length === 0;
      const isQuantityBEmpty = airsample.SL_quantityB.length === 0;
      const isResultEmpty = airsample.SL_result.length === 0;
    if (isLotAEmpty || isLotBEmpty || isQuantityAEmpty || isQuantityBEmpty || isResultEmpty) {
      this.toastr.warning('Enter the Required fields in Viable Air Sampling Table');
      hasError = true;
    }
    if (airsample.SL_result === 'Fail' && airsample.SL_notes.length === 0) {
      this.toastr.warning('Please Enter the Notes');
      hasError = true;
    }
  });
  }
  if (hasError) {
    return; 
  }
  if(this.EM_details.value.Viable_surface_sampling_locations == true){
    data.Viable_Sampling_Location.forEach((surfacesample: any) =>{
      const isLotAEmpty = surfacesample.VSS_lot.length === 0;
      const isQuantityBEmpty = surfacesample.VSS_quantity.length === 0;
      const isResultEmpty = surfacesample.VSS_result.length === 0;

      if (isLotAEmpty || isQuantityBEmpty || isResultEmpty) {
        this.toastr.warning('Enter the Required fields in Viable Surface Sampling Table');
        hasError = true;
      }
      if(surfacesample.VSS_result == 'Fail' && surfacesample.VSS_notes.length == 0 ){
        this.toastr.warning('Please Enter the Notes.');
        hasError = true;
      }
    });
  }
  
  if (hasError) {
    return; 
  }
    data.Particle_count = this.particleCount;
    if (this.emDetEdit) {
      this.EMDetail[this.emDetIndex] = data;
      this.emDetEdit = false;
      this.clearValue();
    } else {
      const invalid = [];
      const controls = this.EM_details.controls;
      for (const name in controls) {
        if (controls[name].invalid) {       
          invalid.push(name);
        }
      }
      let validatorArrays; 
      validatorArrays = this.validationCheck.getValidation(invalid);
      if (invalid.length === 0) {
        data.id = this.generate.getskuId(10);
        data.Equipment = this.equipmentList;
        this.EMDetail.push(data);
        let emLoc = [];
        this.EMDetail.forEach(element => {
          emLoc.push(element.Location);
        });
        emLoc.forEach(a => {
          let remLoc = this.locationSelectItem.findIndex(s => s.value === a.id);
          if (remLoc !== -1) {
            this.locationSelectItem.splice(remLoc, 1);
          }
        });
        this.clearValue();
        this.equipmentList = [];
      } else {
        this.toastr.warning(validatorArrays);
      }
    } 
  }
  /*
  ** ==========================================================
  ** Clear EM Values
  ** ==========================================================
  */
  clearValue() {
    this.EM_details.patchValue({
      Location: {
        id: '',
        Name: ''
      },
      Status: 'In-Process',
      Equipment_list: {
        id: '',
        Name: ''
      },
      Component_List_air: {
        id: '',
        Name: ''
      },
      Component_List_surface: {
        id: '',
        Name: ''
      },
      Environmental_monitoring_result: '',
      Viable_air_sampling_locations: false,
      Viable_surface_sampling_locations: false,
      Nonviable_air_sampling_locations: false,
      None_sampling_locations: true,
      Sampling_location: [],
      Viable_Sampling_Location: [],
      Sampling_location_result: '',
      Particle_count: [],
      Temperature: '',
      Pressure: '',
      Humidity: '',
      Equipment: [],
      id: ''
    });
    this.equipmentList = [];
    this.sampleLocArray = [];
    this.viablesampleLocArray = [];
    this.particleCount = [];
    this.addEM = false;
  }
  /*
  ** ==========================================================
  ** Edit EM Details
  ** ==========================================================
  */
  editEM(id) {
    const emDet = this.EMDetail.filter(a => a.id === id)[0];
    this.emDetIndex = this.EMDetail.findIndex(a => a.id === id);
    this.emDetEdit = true;
    this.sampleLocationSelectItem = [];
    this.EM_details.patchValue({
      Location: {
        id: emDet.Location.id,
        Name: emDet.Location.Name
      },
      Status: emDet.Status,
      Requested_date: new Date(emDet.Requested_date),
      Sample_collection_date: new Date(emDet.Sample_collection_date),
      Equipment_list: {
        id: emDet.Equipment_list.id,
        Name: emDet.Equipment_list.Name
      },
      Component_List_air: {
        id: emDet.Component_List_air.id,
        Name: emDet.Component_List_air.Name
      },
      Component_List_surface: {
        id: emDet.Component_List_surface.id,
        Name: emDet.Component_List_surface.Name
      },
      Environmental_monitoring_result: emDet.Environmental_monitoring_result,
      Viable_air_sampling_locations: emDet.Viable_air_sampling_locations,
      Viable_surface_sampling_locations: emDet.Viable_surface_sampling_locations,
      Nonviable_air_sampling_locations: emDet.Nonviable_air_sampling_locations,
      None_sampling_locations: emDet.None_sampling_locations,
      Sampling_location: emDet.Sampling_location,
      Viable_Sampling_Location: emDet.Viable_Sampling_Location,
      Sampling_location_result: emDet.Sampling_location_result,
      Particle_count: emDet.Particle_count,
      Temperature: emDet.Temperature,
      Pressure: emDet.Pressure,
      Humidity: emDet.Humidity,
      Equipment: emDet.Equipment === null || emDet.Equipment === undefined ? [] : emDet.Equipment,
      id: emDet.id
    });
    this.equipmentList = emDet.Equipment;
    this.sampleLocArray = emDet.Sampling_location;
    this.viablesampleLocArray = emDet.Viable_Sampling_Location;
    this.particleCount = emDet.Particle_count;
    this.disableField();
    this.addEM = true;
    this.disableField();
  }
  /*
  ** ==========================================================
  ** Get EM By Batch Id
  ** ==========================================================
  */
  getEMBatchId() {
    this.dataService.add(this.configuration.environmentalMonitoring + '/GetEMByBatchLocation/' + this.batchId, '')
      .subscribe(
        (data: any) => {
          this.EMDetail = data.result.EM_details;
          this.emForm.patchValue(data.result);
          this.editPatchValue(this.emEditID);
          for (let d of data.result.EM_details) {
          if(d.Component_List_air.id){this.GetLotNumberById(d.Component_List_air.id, 'air')}    
          if(d.Component_List_surface.id){this.GetLotNumberById(d.Component_List_surface.id, 'surface')}
          }
          this.EMBatchRecord()
          this.spinnerService.hide();
        });
  }

  EMBatchRecord(){
    this.dataService.getSingle('NewRecord', this.configuration.environmentalMonitoring + '/').subscribe(
      (data: any) => {
        this.temperature = data.options.CompanySettings.Temperature;
      });
  }
  /*
  ** ==========================================================
  ** Delete EM
  ** ==========================================================
  */
  deleteEMLoc(id) {
    this.confirmationService.confirm({
      message: 'Are you sure want to Delete?',
      header: 'Delete Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        const delIndex = this.EMDetail.findIndex(a => a.id === id);
        let locList = this.EMDetail[delIndex].Location;
        let locSelect = [];
        locSelect.push(locList);
        let locationSelect = [];
        locationSelect = this.bindDropDown(locSelect, 'Location');
        this.locationSelectItem.push(locationSelect[0]);
        this.EMDetail.splice(delIndex, 1);
        this.clearValue();
        this.equipmentList = [];
      },
      reject: () => { }
    });
  }
  /*
  ** ==========================================================
  ** Get Sampling Location
  ** ==========================================================
  */
  sampleLocation(id) {
    this.dataService.getSingle<any>(id, this.configuration.location + '/GetSamplingLocation/')
      .subscribe((data: any) => {
        if (data.message.result.Sampling_location !== null) {
          this.sampleLocationBinder(data.message.result.Sampling_location);
        }
        else if (data.message.result.Viable_Sampling_Location !== null) {
          this.viableSampleLocationBinder(data.message.result.Viable_Sampling_Location)
        }
      });
  }
  /*
  ** ==========================================================
  ** Add EM Popup
  ** ==========================================================
  */
  addEMDialog() {
    this.EM_details.patchValue({
      Requested_date: this.date,
      //Sample_collection_date: new Date(this.intervalDate),
      Sample_collection_date: this.date,
    });
    this.addEM = true;
  }
  /*
  ** ==========================================================
  ** Sampling Location Binder
  ** ==========================================================
  */
  sampleLocationBinder(response: any) {
    this.sampleLocArray = [];
    let sampleLocArray = response;
    sampleLocArray.forEach((element: any) => {
      const obj = {
        Is_checked: false,
        SL_name: element.Name,
        SL_result: '',
        SL_lotA: '',
        SL_lotB: '',
        SL_quantityA: 1,
        SL_quantityB: 1,
        SL_notes:  '',
      };
      this.sampleLocArray.push(obj);
    });
  }
  /*
  ** ==========================================================
  ** Viable Sampling Location Binder
  ** ==========================================================
  */
  viableSampleLocationBinder(response) {
    
    this.viablesampleLocArray = [];
    let viablesampleLocArray = response;
    viablesampleLocArray.forEach(element => {
      const obj = {
        Is_checked: false,
        VSS_name: element.Name,
        VSS_result: '',
        VSS_lot: '',
        VSS_quantity: 1,
        VSS_notes:  '',
      };
      this.viablesampleLocArray.push(obj);
    });
  
  }

  /*
  ** ==========================================================
  ** Particle Count Binder
  ** ==========================================================
  */
  particleCountBinder(response) {
    this.particleCount = [];
    let particleCountArray = response;
    particleCountArray.forEach(element => {
      const obj = { Name: element.Value, Result: '' };
      this.particleCount.push(obj);
    });
  }
  checkChange(i) {
    if (this.sampleLocArray[i].Is_checked === false) {
      this.sampleLocArray[i].SL_lotA = '';
      this.sampleLocArray[i].SL_lotB = '';
      this.sampleLocArray[i].SL_quantityA = '';
      this.sampleLocArray[i].SL_quantityB = '';
      this.sampleLocArray[i].SL_result = '';
      this.sampleLocArray[i].SL_notes = '';
    }
  }

  checkChanges(i) {
    if (this.viablesampleLocArray[i].Is_checked === false) {
      this.viablesampleLocArray[i].VSS_result = '';
      this.viablesampleLocArray[i].VSS_lot = '';
      this.viablesampleLocArray[i].VSS_quantity = '';
      this.viablesampleLocArray[i].VSS_notes = '';
    }

  }
  /*
  ** ==========================================================
  ** Disable the fields based on Sample collection date
  ** ==========================================================
  */
  disableField() {
    let collectDate = new Date(this.EM_details.get('Sample_collection_date').value);
    let date1 = collectDate.toISOString().substring(0, 10);
    let date2 = this.currentDate.toISOString().substring(0, 10);
    if (date2 >= date1) {
      this.disColl = true;
    } else {
      this.disColl = false;
    }
  }
  /*
  ** ==========================================================
  ** Save EM Details
  ** ==========================================================
  */
  saveEMDetails(data) {
    data.Sampling_location = this.sampleLocArray;
    data.Viable_Sampling_Location = this.viablesampleLocArray;
    data.Particle_count = this.particleCount;
    this.EMDetail[this.emDetIndex] = data;
    this.isEdit = false;
    this.clearValue();
    this.updateEM(this.emForm);
  }

  selectChange(event) {
    if (event) {
      this.sampleLocArray.forEach(element => {
        element.Is_checked = true;
      });
    } else {
      this.sampleLocArray.forEach(element => {
        element.Is_checked = false;
      });
    }

  }
  selectChanges(event) {
    if (event) {
      this.viablesampleLocArray.forEach(element => {
        element.Is_checked = true;
      });
    } else {
      this.viablesampleLocArray.forEach(element => {
        element.Is_checked = false;
      });
    }

  }


}
