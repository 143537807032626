import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '../../../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { CustomRoutingName } from '../../../../../app.custom-routing-name';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { Filedatamodel } from '../../../../model/file-process.interface';
import { FilePatchComponent } from '../../../file-upload/file-upload.patch.component';
import { ValidationCheck } from '@app/shared/common/common-validation';
import { Response, ResponseBody } from '@app/shared/model/service-response.interface';
import { ChemicalMasterResponse} from '../../../../../module/inventory/model/chemical-inventory.interface';
import { yearCalculate, NameCapitalize } from '@app/shared/common/common-helper';
import { SelectItem, ConfirmationService } from 'primeng/api';
import { AuditFiles } from '../../../audit-File';
import { StorageConfiguration } from '../../../storage-setting/storage-configuration';
import { SettingHelper } from '../../../company-setting-helper';

@Component({
  selector: 'app-chemical-add-master',
  templateUrl: './chemical-add-master.component.html',
  styleUrls: ['./chemical-add-master.component.sass'],
  providers: [ValidationCheck, Filedatamodel, FilePatchComponent,
    AuditFiles, CustomRoutingName, ToastBaseModel, StorageConfiguration, SettingHelper, NameCapitalize]

})
export class ChemicalAddMasterComponent implements OnInit {

  resetForm: any;
  id: any;
  addChildResult: { childPopup: boolean; id: any; };
  childPopup: boolean;
  @Output() CancelDialog = new EventEmitter<any>();
  @Output() addChild = new EventEmitter<any>();
  @Input() copyInventory;
  result: string;
  controleSubstanceRequired: boolean;
  responseBody: ResponseBody;
  yrRange: string;
  @Input() mode: any;
  releaseCriteriaList: any[];
  keyCode: number;
  ChemicalMasterInventory: ChemicalMasterResponse;
  chemicalFrom: FormGroup;
  StrengthUnitSelectItem: SelectItem[];
  UnitSelectItem: SelectItem[];
  storageSelectItem: SelectItem[];
  ControlSubstanceSelectItem: SelectItem[];
  releaseCriteriaResult: string[] = [];
  referenceTypeName = 'chemical';
  masterSds = 'chemical_sds';
  accessuri: string;
  ipAddress: any;

  newRecord = 'NewRecord';
  CompanySettings: any;
  blockSpecial: RegExp = /^[^<>*!'"]+$/;
  userInfo: any;
  releaseCriteriaResultArray = [];
  editNotesIndex: any;
  editNotesPopup: boolean;
  editedNotes: string;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private fb: FormBuilder,
    private dataService: DataService,
    private router: Router,
    private configuration: Configuration,
    private filePatch: FilePatchComponent,
    private customRoutingName: CustomRoutingName,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private validationCheck: ValidationCheck,
    private auditFiles: AuditFiles,
    private yearRange: yearCalculate,
    private storageConfiguration: StorageConfiguration,
    private settingHelper: SettingHelper,
    private nameCapitalize: NameCapitalize,
    public confirmationService: ConfirmationService,

  ) { }

  ngOnInit() {
       
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.createForm();
    this.initialLoad();
    this.yrRange = this.yearRange.yrRange;
    const publicIp = require('public-ip');
    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });
    if (this.copyInventory != undefined) {
      this.releaseCriteriaList = this.copyInventory.Release_criteria,
        this.chemicalFrom.patchValue({
          Name: this.copyInventory.Name + ' - Copy',
          id: '',
          Is_strength: this.copyInventory.Is_strength,
          Strength: this.copyInventory.Strength,
          Unit: this.copyInventory.Unit,
          Api: this.copyInventory.Api,
          Compliance_chemical: this.copyInventory.Compliance_chemical,
          Controled_substance: this.copyInventory.Controled_substance,
          Controled_substance_type: this.copyInventory.Controled_substance_type,
          Nhm: this.copyInventory.Nhm,
          Release_criteria: this.copyInventory.Release_criteria,
          Storage_condition: this.copyInventory.Storage_condition,
        });
    }
  }

  /**INITIALIZE FORM */
  createForm() {
    this.chemicalFrom = this.fb.group({
      Name: ['', Validators.required],
      Strength: this.fb.group({
        Units: 0,
        Type: ''
      }),
      Api: false,
      Release_criteria: this.fb.array([]),
      Controled_substance: false,
      Controled_substance_type: [''],
      Storage_condition: ['', Validators.required],
      Compliance_chemical: false,
      Is_strength: false,
      Nhm: false,
      File_data: Filedatamodel,
      Unit: ['', Validators.required],
     // Child_inventory: this.fb.array([]),
      Activity_log: this.fb.array([]),
      id: [''],
      Kind: ['chemical'],
      Quantity_onhand: 0, 
      Allocated_quantity: 0,
      In_process_quantity: 0,
      Used_quantity: 0,
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Notes: [],
      Is_active: true,
      Is_deleted: false,
      Is_name_changed: false,
      Is_name_disable: false,
      Master_barcode: ['']
    });
  }

  /**INITIAL LOAD */
  initialLoad() {
       
    this.dataService.getSingle<ChemicalMasterResponse>('NewRecord', this.configuration.chemicalInventory + '/').subscribe(
      (data: ChemicalMasterResponse) => (this.ChemicalMasterInventory = data),
      error => () => {

        this.spinnerService.hide();
      },
      () => {
        var a = this.ChemicalMasterInventory;
        this.bindsupportingOptions(this.ChemicalMasterInventory);
      }
    );
  }

  /**BINDING OPTOINS */
  bindsupportingOptions(ChemicalMasterInventory: any) {
       
    this.releaseCriteriaResultArray = this.settingHelper.getReleaseCriteria(ChemicalMasterInventory.options.CompanySettings.Release_criteria.Value, true, '');
    this.storageSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Storage_condition.Value, true, '');
    this.ControlSubstanceSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Controlled_substance_type.Value, true, '');
    this.StrengthUnitSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Strength.Value, true, '');
    this.UnitSelectItem = this.settingHelper.getStorageCondition(ChemicalMasterInventory.options.CompanySettings.Units.Value, true, '');



    // Add Release Criteria to form Array
    var releseList = [];
    var selectedReleaseC = [];
    this.addReleaseItem();
  }

  bindDropDown(array: any[], ddType: string): any[] {
    var dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case 'default':
          dropDownItem.push({
            label: a.Name,
            value: a.id
          });
          break;
        case 'storage':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'container':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'strength':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'supplier':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
        case 'controlledSubstance':
          dropDownItem.push({
            label: a.Value,
            value: a.Value
          });
          break;
      }
    });
    return dropDownItem;
  }

  /**HELPER FUNCTION */
  addReleaseItem(): void {
    const releaseFGs = this.releaseCriteriaList.map(release =>
      this.fb.group(release)
    );
    const releaseFormArray = this.fb.array(releaseFGs);
    this.chemicalFrom.setControl('Release_criteria', releaseFormArray);

  }


  strengthRequiredChange(event) {
       
    if (event == true) {
      this.chemicalFrom.get('Strength.Value').setValidators([Validators.required]);
      this.chemicalFrom.get('Strength.Value').updateValueAndValidity();
      this.chemicalFrom.get('Strength.Unit').setValidators([Validators.required]);
      this.chemicalFrom.get('Strength.Unit').updateValueAndValidity();
      this.chemicalFrom.patchValue({
        Strength: {
          Value: 0,
          Unit: ''
        },
      });
    } else {
      this.chemicalFrom.get('Strength.Value').setValidators([Validators.nullValidator]);
      this.chemicalFrom.get('Strength.Value').updateValueAndValidity();
      this.chemicalFrom.get('Strength.Unit').setValidators([Validators.nullValidator]);
      this.chemicalFrom.get('Strength.Unit').updateValueAndValidity();
      this.chemicalFrom.patchValue({
        Strength: {
          Value: 0,
          Unit: ''
        },
      });

    }
  }

  ControlledSubstanceChange(event) {
       

    if (!event.value) {
      this.chemicalFrom.patchValue({
        Controled_substance_type: ''
      });
    }
  }

  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.keyCode = 13;
    }
  }
  receiveFileUploadResponse($event) {
       
    var getfileresult = this.filePatch.genericFilePatch($event, this.chemicalFrom);
    this.chemicalFrom.patchValue({ File_data: getfileresult });
  }

  criteriaReleaseEvent($event) {
       
    this.releaseCriteriaResult = $event;



  }

  /**SUMBIT FORM */
  onSubmit(chemicalFrom) {
       
    const invalid = [];
    const controls = this.chemicalFrom.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);

      }
    }
    chemicalFrom = chemicalFrom.value;
    if (chemicalFrom.Controled_substance && chemicalFrom.Controled_substance_type == '') {
      // this.controleSubstanceRequired = true;
      // this.spinnerService.hide();
      // return;
      invalid.push('Controled_substance_type');
    }
    var validatorArrays;
    validatorArrays = this.validationCheck.getValidation(invalid);

       
    if (invalid.length == 0) {
      if (this.keyCode != 13) {
        this.spinnerService.show();
        chemicalFrom.Release_criteria = this.releaseCriteriaResult;
        chemicalFrom.Is_active = true;
        chemicalFrom.Is_deleted = false;
        chemicalFrom.Created_info.By = this.userInfo[0].id;
        chemicalFrom.Created_info.Name = this.userInfo[0].name;
        chemicalFrom.Created_info.Date = new Date().toISOString();
        chemicalFrom.Kind = 'chemical';
        // if (chemicalFrom.Controled_substance && chemicalFrom.Controled_substance_type == '') {
        //   this.controleSubstanceRequired = true;
        //   this.spinnerService.hide();
        //   return;
        // }
        let activeModel = [];
        activeModel = this.auditFiles.newCreateActivity('Name', chemicalFrom.Name);
        chemicalFrom.Activity_log = activeModel;

        this.dataService.add<Response>(this.configuration.chemicalInventory, chemicalFrom).subscribe(
          (data: Response) => (this.responseBody = data.message),
          error => {
            this.spinnerService.hide();
            if (error.error.message.result === 'Document Already Exist') {
              this.toastr.warning('Chemical Inventory Already Exist');
            }
          },
          () => {

            this.spinnerService.hide();
            this.result = this.responseBody.result;
            this.spinnerService.hide();
            if (this.result === 'Document created successfully!!') {
              if (this.mode === 'MasterFormulation') {
                this.childPopup = true;
                this.addChildResult = { childPopup: this.childPopup, id: this.responseBody.id };
                this.chemicalFrom.reset();
                this.addChild.emit(this.addChildResult);
              } else {
                this.router.navigate([this.customRoutingName.chemical_master_detail + this.responseBody.id]);
              }
            }
            this.toastr.success(this.toastMessage.Inventory + this.toastMessage.Create);
          }
        );
      }
    } else {
      this.toastr.warning(validatorArrays);
    }
  }

  /**CLOSE POPUP */
  ChemicalAddDialog() {
    this.resetForm = this.chemicalFrom.reset();
    this.CancelDialog.emit(this.resetForm);
  }

  nameCapitalChange(event) {
    let name = this.nameCapitalize.nameCapitalChange(event);
    this.chemicalFrom.patchValue({
      Name: name
    });
  }

  emitNotesResult(event) {
    this.chemicalFrom.patchValue({
      Notes: event
    });

  }

  editNotesDialog(i) {
       
    const notes = this.chemicalFrom.get('Notes').value;
    this.editNotesIndex = i;
    this.editNotesPopup = true;
    this.editedNotes = notes[i].Note;


  }
  deleteNotesDialog(i) {
       
    this.confirmationService.confirm({
      message: 'Delete Confirmation',
      header: 'Are you sure want to Delete?',
      icon: 'fa fa-exclamation-triangle',
      accept: () => {
        this.chemicalFrom.getRawValue().Notes.splice(i, 1);
      },
      reject: () => { }
    });


  }

  closeNotesEditDialog() {
       
    this.editNotesPopup = false;
    this.editedNotes = '';
    this.editNotesIndex = 0;

  }
  onSubmitNotesEdit() {
       
    if (this.editedNotes === undefined || this.editedNotes === null || this.editedNotes === '') {
      this.toastr.warning('Please Enter Notes');
    } else {
      let notesObject = {};
      const notes = this.chemicalFrom.get('Notes').value;
      const i = this.editNotesIndex;
      notesObject = {
        Note: this.editedNotes,
        Ip_address: this.ipAddress,
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date().toISOString()
      };
      notes[i] = notesObject;
      this.chemicalFrom.patchValue({
        Notes: notes
      });
      // this.onSubmit(this.chemicalFrom);
      this.editedNotes = '';
      this.editNotesIndex = 0;
      this.editNotesPopup = false;
    }
  }

}

