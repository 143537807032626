import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
// import "rxjs/add/operator/debounceTime";
import {FormBuilder,FormGroup,FormArray} from "@angular/forms";
import { InventoryModel , response} from "../../model/grid-editor.interface"

@Component({
  selector: 'app-common-datatable-editable',
  templateUrl: './common-datatable-editable.component.html',
  styleUrls: ['./common-datatable-editable.component.sass']
})
export class CommonDatatableEditableComponent implements OnInit {
  
  form: FormGroup;
  
  @Input() addDisabled : boolean = false;
  @Input() inventoryType : any;
  @Input() option: InventoryModel[];  
  @Input() selectedInventory: InventoryModel[];  
  @Input() label : string;
  showInventoryPopUp : boolean = false;
  @Input() popUpHeader : string;
  @Output() emitChildRecordEvent = new EventEmitter<response>();
  emitResponse : response;
  inventoryEdit: boolean = false;
  iterrateChemicalArray : InventoryModel[]; 
  showInventoryError : boolean = false; 
    
  constructor( private fb: FormBuilder,) { }

  ngOnInit() {  
    this.iterrateChemicalArray = this.option;   
    this.form = this.fb.group({
      inventory : this.fb.array([])
    })        
    this.option.forEach(element => {
       element.Quantity = 0;
    });
   
    if(this.selectedInventory != undefined)
    {
      this.setInventoryFormArray(this.selectedInventory);
    }
    
  }


  setInventoryFormArray(inventoryToSet: any[]): void {    
    const inventoryFGs = inventoryToSet.map(inventory =>
      this.fb.group(inventory)
    );
    const inventoryFormArray = this.fb.array(inventoryFGs);
    this.form.setControl('inventory', inventoryFormArray);   
  }


  showDialog()
  {
    this.showInventoryPopUp = !this.showInventoryPopUp;
    this.setInventoryArray();
  }
  closeDialog(){
    this.showInventoryPopUp = !this.showInventoryPopUp;
    this.showInventoryError = false;

  }

  addInventory()
  {
    this.selectedInventory.forEach(element => {
      this.showInventoryError = false;
      if (element.Quantity == 0) {
        this.showInventoryError = true;
        return;
      }
    });

    if(!this.showInventoryError)
    {
      this.setInventoryFormArray(this.selectedInventory);    
      this.closeDialog();
      this.emitResponse = { type : this.inventoryType, selectedInventory : this.convertStringToInteger(this.form.get("inventory").value) }
      this.setInventoryArray();
      this.emitChildRecordEvent.emit(this.emitResponse);
      
    }
    
  }

  removeInventory(index)
  {  
    const control = <FormArray>this.form.controls['inventory']; 
    control.removeAt(index);    
    this.setInventoryArray();
  }

  setInventoryArray()
  {
    var result = [];
    var selectArray = this.form.get("inventory").value;
    if(selectArray.length > 0)
    {
      this.iterrateChemicalArray.forEach((item, index) => {
          var checkInventorySelected = selectArray.find(x => x.id == item.id);
          if (checkInventorySelected == null) {
            result.push({
              id: item.id,
              Name:item.Name,                 
              Quantity: 0,              
            });
          }
      });  
    }
    else{
        this.option.forEach((item, index) => {
              item.Quantity = 0;
              result.push(item); 
      });  
    }      
    this.option = result;
    this.selectedInventory = selectArray;
  }

  showDialogEdit(type) {
    if (type == "inventory") {
      this.inventoryEdit = true;
    } else if (type == "inventoryClose") {
      this.inventoryEdit = false;
    }  
  }

  updateInventory()
  {
    var inventoryArray = this.form.get("inventory").value;
    this.emitResponse = { type : this.inventoryType, selectedInventory : this.convertStringToInteger(inventoryArray) }
    this.showDialogEdit('inventoryClose')
  }

  convertStringToInteger(inventory : any[]) : any[]
  {
    inventory.forEach(element => {
      element.Quantity = +element.Quantity
    });

    return inventory
  }
}

