import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '../../../shared/service/data-service';
import { Configuration } from '../../../app.configuration';
import { NgxUiLoaderService } from 'ngx-ui-loader';



@Component({

  selector: 'app-common-status-report',
  templateUrl: './common-status-report.component.html',
  styleUrls: ['./common-status-report.component.sass'],
  providers: [ToastrService]

})
export class CommonStatusReportComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private ngxService: NgxUiLoaderService


  ) { }
  @Output() emittValue = new EventEmitter<any>();

  selectTypeDialog = true;
  selectItems = [];
  kindValue: any;
  label: any;
  dropKey: any;
  reportGenerate = false;


  ngOnInit() {
    this.dropKey = '';
  }

  /*
 ** ==========================================================
 ** radio Change
 ** ==========================================================
 */
  radioChange(value) {
    this.kindValue = value;
    this.labelCheck(value);
  }

  /*
  ** ==========================================================
  ** label Generate
  ** ==========================================================
  */


  labelCheck(value) {
    switch (value) {
      case 'finalproduct':
        this.label = 'FINAL PRODUCT';
        this.dropKey = 'MasterFormula';
        this.nextAuditTrails();
        break;
      case 'chemical_child':
        this.label = 'CHEMICAL LOT #';
        this.nextAuditTrails();
        break;
      case 'component_child':
        this.label = 'COMPONENT LOT #';
        this.nextAuditTrails();
        break;

      case 'finalproduct_child':
        this.label = 'FINAL PRODUCT LOT #';
        this.nextAuditTrails();
        break;
      case 'facility':
        this.label = 'FACILITY';
        this.dropKey = 'Facility';
        this.nextAuditTrails();
        break;
      case 'manage_user':
        this.label = 'USER';
        this.dropKey = 'User';
        this.nextAuditTrails();
        break;
      case 'personnel_monitoring':
        this.label = 'PERSONNEL MONITORING';
        this.dropKey = 'User';
        this.nextAuditTrails();
        break;
      case 'location':
        this.label = 'LOCATION';
        this.dropKey = 'Facility';
        this.nextAuditTrails();
        break;
      case 'environmental_monitoring':
        this.label = 'ENVIRONMENTAL MONITORING';
        this.dropKey = 'Facility ';
        this.nextAuditTrails();
        break;
      case 'equipment':
        this.label = 'EQUIPMENT';
        this.dropKey = 'Facility';
        this.nextAuditTrails();
        break;

    }
  }


  /*
  ** ==========================================================
  ** label Generate
  ** ==========================================================
  */


  nextAuditTrails() {
    if (this.kindValue !== undefined) {
      this.reportGenerate = true;
      this.selectTypeDialog = false;
      this.bindDrop();
    } else {
      this.toastr.warning('Select any one of the type');
    }

  }
  bindDrop() {
    if (this.dropKey !== '') {
         
      // this.spinnerService.show();
      this.ngxService.start();
      this.dataService.getSingle(this.dropKey, this.configuration.report + '/Option/').subscribe(
        (data: any) => {
          // this.spinnerService.hide();
          this.ngxService.stopAll();
          if (this.dropKey === 'MasterFormula') {
            this.selectItems = this.bindDropDown(data.message.result.finalproduct, 'default');
          } else if (this.label === 'FACILITY') {
            this.selectItems = this.bindDropDown(data.message.result.facility, 'default');
          } else if (this.label === 'EQUIPMENT') {
            this.selectItems = this.bindDropDown(data.message.result.classification[0].Value, 'equipment');
          } else if (this.label === 'LOCATION') {
            this.selectItems = this.bindDropDown(data.message.result.location, 'default');
          } else if (this.dropKey === 'User') {
            this.selectItems = this.bindDropDown(data.message.result.manage_user, 'default');
          } else if (this.label = 'ENVIRONMENTAL MONITORING') {
            this.selectItems = this.bindDropDown(data.message.result.location, 'default');
          }
          this.dropKey = '';
          this.emitValue();

        });
    } else {
      this.emitValue();

    }

  }

  emitValue() {
    let obj = {};

    obj = {
      'Label': this.label,
      'selectItem': this.selectItems
    };
    this.emittValue.emit(obj);
  }

  /*
  ** ==========================================================
  ** bind Drop Down
  ** ==========================================================
  */
  bindDropDown(array: any[], ddType: string): any[] {
    const dropDownItem = [];
    array.forEach((a) => {
      switch (ddType) {
        case 'operator':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'Location':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'equipment':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'default':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
      }
    });
    return dropDownItem;
  }

}
