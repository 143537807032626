import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SideBarParentModel } from '../model/side-bar.interface';
import { CustomRoutingName } from '../../app.custom-routing-name';
import { StorageConfiguration } from '../../shared/common/storage-setting/storage-configuration';
import { RolesPermissions } from '../../shared/common/roles-permission';
import { TopHeaderComponent } from '../top-header/top-header.component';
import { ConfirmationService } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';

@Component({
  selector: 'app-side-bar',
  templateUrl: './side-bar.component.html',
  styleUrls: ['./side-bar.component.sass'],
  providers: [TopHeaderComponent]
})
export class SideBarComponent implements OnInit {
  path: string;
  items: SideBarParentModel[] = [];
  permissionSetting: any;
  permissoinSetting: any;
  // Boolean
  View: Boolean;
  Create: Boolean;
  Edit: Boolean;
  Delete: Boolean;
  Audit_verify: Boolean;
  isCollapsed = true;
  ViewDetail = false;
  ReportHide = false;
  ProductionSchedulerHide = false;
  ProductionCalendarHide = false;
  ComplianceCalendarHide = false;
  ProductionDashboardHide = false;
  SalesDashboardHide = false;
  MonitorDashboardHide = false;
  userInfo: any;
  menuActive = false;
  name: any;
  role: any;
  constructor(private confirmationService: ConfirmationService,
    private TopHeaderComponent: TopHeaderComponent,
    private router: Router,
    private customRoutingName: CustomRoutingName,
    private storageConfiguration: StorageConfiguration,
    private spinnerService: Ng4LoadingSpinnerService,
    private permission: RolesPermissions) {

  }
  ngOnInit() {
    this.getCurrentpath();
    this.getPermission();
    this.setDynamicSideBar();
    this.isCollapsed = true;
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.name = this.userInfo[0].name;
    this.role = this.userInfo[0].role;
  }

  toggleMenu() {
    this.isCollapsed = !this.isCollapsed;
  }

  getPermission() {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    const localStorage = this.userInfo[0];
    const permission = localStorage.permissionSetting;
    permission.Report === true ? this.ReportHide = false : this.ReportHide = true;
    permission.Production_scheduler === true ? this.ProductionSchedulerHide = false : this.ProductionSchedulerHide = true;
    permission.Production_calendar === true ? this.ProductionCalendarHide = false : this.ProductionCalendarHide = true;
    permission.Compliance_calendar === true ? this.ComplianceCalendarHide = false : this.ComplianceCalendarHide = true;
    if (permission.Dashboard_assign === 'All') {
      this.ProductionDashboardHide = false;
      this.SalesDashboardHide = false;
      this.MonitorDashboardHide = false;
    } else {
      permission.Dashboard_assign === 'Production' ? this.ProductionDashboardHide = false : this.ProductionDashboardHide = true;
      permission.Dashboard_assign === 'Sales' ? this.SalesDashboardHide = false : this.SalesDashboardHide = true;
      permission.Dashboard_assign === 'Compliance' ? this.MonitorDashboardHide = false : this.MonitorDashboardHide = true;
    }

  }

  OnRouterClick(link, type, name) {
    this.spinnerService.show();
    if (type === 'parent') {
      this.items.forEach(parent => {

        if (parent.ParentName == name) {
          parent.IsActive = true;
        } else {
          parent.IsActive = false;
        }

        parent.SubMenu.forEach(submenu => {
          if (name == parent.ParentName && submenu.DisplayOrder == 1) {
            submenu.IsActive = true;
          } else {
            submenu.IsActive = false;
          }
        });
      });
    }
    if (type === 'submenu') {
      this.items.forEach(parent => {
        parent.SubMenu.forEach(submenu => {
          submenu.IsActive = submenu.ChildRouterLink == link ? true : false;
        });
      });
      this.toggleMenu();
    }
    setTimeout(() => {
      this.router.navigate([link]);
    }, 1000);
  }

  setDynamicSideBar() {
    // SET DASHBOARD

    // if (this.userInfo[0].pharmacy_type == 'A PHARMACY' && this.userInfo[0].role != 'Management') {
      if (this.userInfo[0].pharmacy_type == 'A PHARMACY') {
      var dashboard = {
        ParentId: 'dashboard',
        ParentDDLink: '',
        ParentName: 'DASHBOARD',
        ParentRouterLink: this.customRoutingName.production_dashboard,
        HasSubMenu: true,
        DisplayOrder: 2,
        Hide: false,
        IsActive: this.path.includes('dashboard/dashboard-production'),

        SubMenu: [
          { ChildId: '', SubHide: this.ProductionDashboardHide, ChildName: 'Production Dashboard', ChildClass: '', ChildRouterLink: this.customRoutingName.production_dashboard, HasSubMenu: false, IsActive: true, DisplayOrder: 1 },
          { ChildId: '', SubHide: this.MonitorDashboardHide, ChildName: 'Compliance Dashboard', ChildClass: '', ChildRouterLink: this.customRoutingName.Monitor_dashboard, HasSubMenu: false, IsActive: true, DisplayOrder: 1 }
        ]
      };
    }
    else {

      var dashboard = {
        ParentId: 'dashboard',
        ParentDDLink: '',
        ParentName: 'DASHBOARD',
        ParentRouterLink: this.customRoutingName.sales_dashboard,
        HasSubMenu: true,
        DisplayOrder: 1,
        Hide: false,
        IsActive: this.path.includes('dashboard/dashboard-sales'),

        SubMenu: [{ ChildId: '', SubHide: this.SalesDashboardHide, ChildName: 'Sales Dashboard', ChildClass: '', ChildRouterLink: this.customRoutingName.sales_dashboard, HasSubMenu: false, IsActive: true, DisplayOrder: 1 },
        { ChildId: '', SubHide: this.ProductionDashboardHide, ChildName: 'Production Dashboard', ChildClass: '', ChildRouterLink: this.customRoutingName.production_dashboard, HasSubMenu: false, IsActive: true, DisplayOrder: 2 },
        { ChildId: '', SubHide: this.MonitorDashboardHide, ChildName: 'Compliance Dashboard', ChildClass: '', ChildRouterLink: this.customRoutingName.Monitor_dashboard, HasSubMenu: false, IsActive: true, DisplayOrder: 3 }
        ]
      };

    }

    // SET INVENTORY
    const inventory = {
      ParentId: 'inventory',
      ParentDDLink: 'InventoryDown',
      ParentName: 'INVENTORY',
      ParentRouterLink: this.customRoutingName.chemical_master_list,
      HasSubMenu: true,
      DisplayOrder: 2,
      Hide: this.permission.getSidebarPermission('Inventory'),
      IsActive: this.path.includes('inventory'),
      SubMenu: [{ ChildId: '', SubHide: false, ChildName: 'Chemical Inventory', ChildClass: 'Chemical_Inventory', ChildRouterLink: this.customRoutingName.chemical_master_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.chemical_master_list) || this.path.includes(this.customRoutingName.chemical_master_detail) || this.path.includes(this.customRoutingName.chemical_child_list), DisplayOrder: 1 },
      { ChildId: '', SubHide: false, ChildName: 'Component Inventory', ChildClass: 'Component_Inventory', ChildRouterLink: this.customRoutingName.component_master_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.component_master_list) || this.path.includes(this.customRoutingName.component_master_detail) || this.path.includes(this.customRoutingName.component_master_child), DisplayOrder: 2 },
      { ChildId: '', SubHide: false, ChildName: 'Final Product Inventory', ChildClass: 'Final_product', ChildRouterLink: this.customRoutingName.final_product_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.final_product_list) || this.path.includes(this.customRoutingName.final_product_detail), DisplayOrder: 4 },
      ]
    };

    // SET PRODUCTION
    const production = {
      ParentId: 'production',
      ParentDDLink: 'production-master',
      ParentName: 'PRODUCTION',
      ParentRouterLink: this.customRoutingName.calendar_production,
      HasSubMenu: true,
      DisplayOrder: 3,
      Hide: false,
      IsActive: this.path.includes('production'),
      SubMenu: [
        { ChildId: '', SubHide: this.ProductionCalendarHide, ChildName: 'Calendar', ChildClass: 'Calendar', ChildRouterLink: this.customRoutingName.calendar_production, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.calendar_production), DisplayOrder: 1 },
        { ChildId: '', SubHide: this.ProductionSchedulerHide, ChildName: 'Production Scheduler', ChildClass: 'production_scheduler', ChildRouterLink: this.customRoutingName.production_scheduler, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.production_scheduler), DisplayOrder: 2 },
        { ChildId: '', SubHide: this.permission.getSidebarPermission('Master Formulation Record'), ChildName: 'Master Formulation Record', ChildClass: 'Master_Formulation_Record', ChildRouterLink: this.customRoutingName.master_formulation_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.master_formulation_list) || this.path.includes(this.customRoutingName.master_formulation_detail), DisplayOrder: 3 },
        { ChildId: '', SubHide: this.permission.getSidebarPermission('Batch Record'), ChildName: 'Batch Record', ChildClass: 'Batch_Record', ChildRouterLink: this.customRoutingName.batch_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.batch_list) || this.path.includes(this.customRoutingName.batch_detail), DisplayOrder: 4 }
      ]
    };

    // SET FACILITY
    const facility = {
      ParentId: 'facility',
      ParentDDLink: 'facilityDown',
      ParentName: 'FACILITY',
      ParentRouterLink: this.customRoutingName.facility_list,
      HasSubMenu: true,
      DisplayOrder: 4,
      Hide: this.permission.getSidebarPermission('Facility'),
      IsActive: this.path.includes('facility'),
      SubMenu: [
        { ChildId: '', SubHide: false, ChildName: 'Facility', ChildClass: 'Manage_Facility', ChildRouterLink: this.customRoutingName.facility_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.facility_list) || this.path.includes(this.customRoutingName.facility_detail), DisplayOrder: 1 },
        { ChildId: '', SubHide: false, ChildName: 'Location', ChildClass: 'Manage_Location', ChildRouterLink: this.customRoutingName.location_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.location_list) || this.path.includes(this.customRoutingName.location_detail), DisplayOrder: 2 },
        { ChildId: '', SubHide: false, ChildName: 'Equipment', ChildClass: 'Manage_Equipment', ChildRouterLink: this.customRoutingName.equipment_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.equipment_list) || this.path.includes(this.customRoutingName.equipment_detail), DisplayOrder: 3 },
      ]
    };

    // SET COMPLIANCE
    const compliance = {
      ParentId: 'compliance',
      ParentDDLink: 'ComplianceDown',
      ParentName: 'COMPLIANCE',
      ParentRouterLink: this.customRoutingName.calendar_compliance,
      HasSubMenu: true,
      DisplayOrder: 5,
      Hide: this.permission.getSidebarPermission('Compliance'),
      IsActive: this.path.includes('compliance'),
      SubMenu: [
        { ChildId: '', SubHide: this.ComplianceCalendarHide, ChildName: 'Calendar', ChildClass: 'Calendar', ChildRouterLink: this.customRoutingName.calendar_compliance, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.calendar_compliance), DisplayOrder: 1 },
        { ChildId: '', SubHide: false, ChildName: 'Compounding Methodology', ChildClass: 'Manage_Aseptic_Process', ChildRouterLink: this.customRoutingName.aseptic_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.aseptic_list) || this.path.includes(this.customRoutingName.aseptic_detail), DisplayOrder: 2 },
        { ChildId: '', SubHide: false, ChildName: 'Training', ChildClass: 'Training', ChildRouterLink: this.customRoutingName.training_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.training_list) || this.path.includes(this.customRoutingName.training_detail), DisplayOrder: 3 },
        { ChildId: '', SubHide: false, ChildName: 'Release Management', ChildClass: 'Release_Management', ChildRouterLink: this.customRoutingName.release_management_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.release_management_list) || this.path.includes(this.customRoutingName.release_management_list), DisplayOrder: 4 },
        { ChildId: '', SubHide: false, ChildName: 'Environmental Monitoring', ChildClass: 'Environmental_Monitoring', ChildRouterLink: this.customRoutingName.environmental_monitoring_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.environmental_monitoring_list) || this.path.includes(this.customRoutingName.environmental_monitoring_list), DisplayOrder: 5 },
        { ChildId: '', SubHide: false, ChildName: 'Personnel Monitoring', ChildClass: 'Personnel_Monitoring', ChildRouterLink: this.customRoutingName.personnel_monitoring_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.personnel_monitoring_list) || this.path.includes(this.customRoutingName.personnel_monitoring_list), DisplayOrder: 6 },
        { ChildId: '', SubHide: false, ChildName: 'Task Management', ChildClass: 'Task_Management', ChildRouterLink: this.customRoutingName.task_type, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.task_type) || this.path.includes(this.customRoutingName.task_type), DisplayOrder: 7 },
        { ChildId: '', SubHide: false, ChildName: 'Notifications', ChildClass: 'Notification', ChildRouterLink: this.customRoutingName.notification_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.notification_list) || this.path.includes(this.customRoutingName.notification_list), DisplayOrder: 8 }
      ]
    };

    // SET USER
    const user = {
      ParentId: 'user',
      ParentDDLink: 'userDown',
      ParentName: 'USERS',
      ParentRouterLink: this.customRoutingName.user_list,
      HasSubMenu: true,
      DisplayOrder: 6,
      Hide: this.permission.getSidebarPermission('User'),
      IsActive: this.path.includes('users'),
      SubMenu: [{ ChildId: '', SubHide: false, ChildName: 'Users', ChildClass: 'Manage_User', ChildRouterLink: this.customRoutingName.user_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.user_detail) || this.path.includes(this.customRoutingName.user_list), DisplayOrder: 1 },
      { ChildId: '', SubHide: false, ChildName: 'Roles', ChildClass: 'Manage_Roles', ChildRouterLink: this.customRoutingName.role_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.role_list) || this.path.includes(this.customRoutingName.role_detail), DisplayOrder: 2 }
      ]
    };

    // SET SETTINGS
    const settings = {
      ParentId: 'settings',
      ParentDDLink: 'settingsDown',
      ParentName: 'SETTINGS',
      ParentRouterLink: this.customRoutingName.setting_detail,
      HasSubMenu: true,
      DisplayOrder: 7,
      Hide: this.permission.getSidebarPermission('Settings'),
      IsActive: this.path == '/settings-detail',
      SubMenu: [{ ChildId: '', SubHide: false, ChildName: 'Company', ChildClass: 'Company_Settings', ChildRouterLink: this.customRoutingName.setting_detail, HasSubMenu: false, IsActive: this.path == '/settings-detail', DisplayOrder: 1 },
      { ChildId: '', SubHide: false, ChildName: 'Application', ChildClass: 'application_setting', ChildRouterLink: this.customRoutingName.application_setting, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.application_setting) || this.path.includes(this.customRoutingName.application_setting), DisplayOrder: 2 },
      { ChildId: '', SubHide: false, ChildName: 'Report an issue', ChildClass: 'report_an_issue', ChildRouterLink: this.customRoutingName.report_an_issue, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.report_an_issue) || this.path.includes(this.customRoutingName.report_an_issue), DisplayOrder: 3 }
      ]
    };

    const Audit = {
      ParentId: 'audit',
      ParentDDLink: 'auditDown',
      ParentName: 'AUDIT TRAILS',
      ParentRouterLink: this.customRoutingName.audit_trails,
      HasSubMenu: true,
      DisplayOrder: 7,
      Hide: false,
      IsActive: this.path == '/audit-trails',
      SubMenu: []
    };

    // SET CUSTOMER

    const customer = {
      ParentId: 'customer',
      ParentDDLink: 'customerDown',
      ParentName: 'CUSTOMERS',
      ParentRouterLink: this.customRoutingName.customer_list,
      HasSubMenu: true,
      DisplayOrder: 8,
      Hide: this.permission.getSidebarPermission('Customer'),
      IsActive: this.path.includes('customer'),
      SubMenu: []
    };

    // SET ORDER
    const order = {
      ParentId: 'orders',
      ParentDDLink: 'order-master',
      ParentName: 'ORDERS',
      ParentRouterLink: this.customRoutingName.new_list,
      HasSubMenu: true,
      DisplayOrder: 9,
      Hide: this.permission.getSidebarPermission('Order'),
      IsActive: this.path.includes('order'),
      SubMenu: [
        { ChildId: '', SubHide: false, ChildName: 'New', ChildClass: 'new_list', ChildRouterLink: this.customRoutingName.new_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 1 },
        { ChildId: '', SubHide: false, ChildName: 'Released', ChildClass: 'order_list', ChildRouterLink: this.customRoutingName.released_list, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 2 },
        { ChildId: '', SubHide: false, ChildName: 'Ready for Shipping', ChildClass: 'ready_for_list', ChildRouterLink: this.customRoutingName.order_ready_for_shipping, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 3 },
        { ChildId: '', SubHide: false, ChildName: 'Shipped', ChildClass: 'shipped_list', ChildRouterLink: this.customRoutingName.order_shipped, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 4 },
        { ChildId: '', SubHide: false, ChildName: 'Customer Received', ChildClass: 'customer_received_list', ChildRouterLink: this.customRoutingName.order_received, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 5 },
        { ChildId: '', SubHide: false, ChildName: 'Returned', ChildClass: 'returned_list', ChildRouterLink: this.customRoutingName.order_returned, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 6 },
        { ChildId: '', SubHide: false, ChildName: 'Dispensed', ChildClass: 'dispensed_list', ChildRouterLink: this.customRoutingName.order_dispensed, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 7 },
        { ChildId: '', SubHide: false, ChildName: 'Cancelled', ChildClass: 'cancel_list', ChildRouterLink: this.customRoutingName.order_cancelled, HasSubMenu: false, IsActive: this.path.includes(this.customRoutingName.order_list) || this.path.includes(this.customRoutingName.order_detail), DisplayOrder: 8 }

      ]
    };

    // REPORT
    const report = {
      ParentId: 'report',
      ParentDDLink: 'report',
      ParentName: 'REPORT',
      ParentRouterLink: this.customRoutingName.report,
      HasSubMenu: true,
      DisplayOrder: 10,
      Hide: this.ReportHide,
      IsActive: this.path == '/report',
      SubMenu: [
        // { ChildId: '', SubHide: false, ChildName: 'Report', ChildClass: 'Report', ChildRouterLink:  this.customRoutingName.report, HasSubMenu: false, IsActive:  this.path.includes(this.customRoutingName.report) , DisplayOrder: 1 },

        // { ChildId: '', SubHide: false, ChildName: 'Inventory', ChildClass: 'ReportInventory', ChildRouterLink:  this.customRoutingName.inventory_report, HasSubMenu: false, IsActive:  this.path.includes(this.customRoutingName.inventory_report) , DisplayOrder: 1 },
        // { ChildId: '', SubHide: false, ChildName: 'Production', ChildClass: 'ReportProduction', ChildRouterLink: this.customRoutingName.production_report, HasSubMenu: false,  IsActive: this.path.includes(this.customRoutingName.production_report) , DisplayOrder: 2 },
        // { ChildId: '', SubHide: false, ChildName: 'Order', ChildClass: 'ReportOrder', ChildRouterLink:this.customRoutingName.order_report, HasSubMenu: false, IsActive:   this.path.includes(this.customRoutingName.order_report) , DisplayOrder: 3 },
        // { ChildId: '', SubHide: false, ChildName: 'Customer', ChildClass: 'Reportcustomer', ChildRouterLink: this.customRoutingName.customer_report, HasSubMenu: false,   IsActive: this.path.includes(this.customRoutingName.customer_report) , DisplayOrder: 4 },
        // { ChildId: '', SubHide: false, ChildName: 'Sales', ChildClass: 'ReportSales', ChildRouterLink: this.customRoutingName.sales_report, HasSubMenu: false, IsActive:   this.path.includes(this.customRoutingName.sales_report) , DisplayOrder: 5 },
        // { ChildId: '', SubHide: false, ChildName: 'Recall', ChildClass: 'Reportrecall', ChildRouterLink: this.customRoutingName.recall_report, HasSubMenu: false, IsActive:  this.path.includes(this.customRoutingName.recall_report) , DisplayOrder: 6 },
        // { ChildId: '', SubHide: false, ChildName: 'Out of Specification', ChildClass: 'Reportspecification', ChildRouterLink: this.customRoutingName.out_of_specfication_report, HasSubMenu: false,   IsActive: this.path.includes(this.customRoutingName.out_of_specfication_report) , DisplayOrder: 11 },
        // { ChildId: '', SubHide: false, ChildName: 'Pass / Released ', ChildClass: 'Reportpass', ChildRouterLink: this.customRoutingName.pass_report, HasSubMenu: false,   IsActive: this.path.includes(this.customRoutingName.pass_report) , DisplayOrder: 10 },
        // { ChildId: '', SubHide: false, ChildName: 'Em ', ChildClass: 'ReportEm', ChildRouterLink: this.customRoutingName.em_report, HasSubMenu: false, IsActive:  this.path.includes(this.customRoutingName.em_report) , DisplayOrder: 7 },
        // { ChildId: '', SubHide: false, ChildName: 'User ', ChildClass: 'Reportuser', ChildRouterLink: this.customRoutingName.user_report, HasSubMenu: false, IsActive:   this.path.includes(this.customRoutingName.user_report) , DisplayOrder: 8 },
        // { ChildId: '', SubHide: false, ChildName: 'Equipment ', ChildClass: 'Reportequipment', ChildRouterLink:this.customRoutingName.equipment_report, HasSubMenu: false,  IsActive: this.path.includes(this.customRoutingName.equipment_report) , DisplayOrder: 9 },
        // { ChildId: '', SubHide: false, ChildName: 'Facility ', ChildClass: 'Reportfacility', ChildRouterLink: this.customRoutingName.facility_report, HasSubMenu: false,   IsActive: this.path.includes(this.customRoutingName.facility_report) , DisplayOrder: 10 },


      ]
    };
    this.items.push(dashboard, customer, order, inventory, production, facility, compliance, user, settings, report, Audit);
  }

  getCurrentpath() {
    this.path = window.location.hash;
    if (this.path.includes('settings-detail')) {
      this.path = '/settings-detail';
    } else {
      this.path = '.' + this.path.substring(1) + '/';
    }
  }

  myFunction() {
    this.menuActive = !this.menuActive;
  }

  logout() {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to Logout?',
      header: 'Log Out',
      icon: 'fa fa-sign-out',
      accept: () => {
        this.TopHeaderComponent.ngOnInit();
        this.TopHeaderComponent.logOutAccept();
      },
      reject: () => {
      }
    });
  }

  taskDetailDialog() {
    this.TopHeaderComponent.taskDetailDialog();
  }


}


