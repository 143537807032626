import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { yearCalculate } from '../../../shared/common/common-helper';
import { DataService } from '../../../shared/service/data-service';
import { Configuration } from '../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Location } from '@angular/common';

@Component({
  selector: 'app-report-generate',
  templateUrl: './report-generate.component.html',
  styleUrls: ['./report-generate.component.sass']
})

export class ReportGenerateComponent implements OnInit {

  constructor(
    private toastr: ToastrService,
    private yearRange: yearCalculate,
    private configuration: Configuration,
    private dataService: DataService,
    private spinnerService: Ng4LoadingSpinnerService,
    private ngxService: NgxUiLoaderService,
    private location: Location

  ) { }


  @Input() reportGenerate: boolean;
  @Input() status: any;
  @Input() operatorselectItems: any;
  @Input() selectItems: any;
  @Input() label: any;
  @Output() emitChildRecordEvent = new EventEmitter<any>();
  @Output() CancelDialog = new EventEmitter<any>();
  @Output() backToParent = new EventEmitter<any>();

  @Output() backDialog = new EventEmitter<any>();

  yrRange: any;
  toDateExcel: any;
  toDate: any;
  finalProductSelectItem: any = [];
  fromDateExcel: any;
  fieldValueArray = [];
  lotNumberValues: any;
  dataResult: any;

  ngOnInit() {
    this.toDateExcel = '';
    this.fromDateExcel = '';
    this.lotNumberValues = '';
    this.fieldValueArray = [];
    this.yrRange = this.yearRange.yrRange;
    // this.reportGenerateSubmit();
  }

  /*
** ==========================================================
** cancel Report
** ==========================================================
*/
  cancelReport() {
    this.backDialog.emit(false);
  }

  backToMain() {
    this.backToParent.emit(false);
    // this.location.back();

  }
  /*
  ** ==========================================================
  ** From Date get
  ** ==========================================================
  */
  fromDate() {
    this.toDate = new Date(this.fromDateExcel);
  }

  /*
  ** ==========================================================
  ** report Generate Submit
  ** ==========================================================
  */

  reportGenerateSubmit() {
    let dateFilter = {};
    const toDate = this.toDateExcel === '' ? '' : this.toDateExcel.toISOString().slice(0, 10);
    const fromDate = this.fromDateExcel === '' ? '' : this.fromDateExcel.toISOString().slice(0, 10);
    let dateChecker = true;
    if (new Date(this.fromDateExcel).getTime() > new Date(this.toDateExcel).getTime()) {
      dateChecker = false;
    }
    if ((this.fromDateExcel !== '' && this.toDateExcel !== '' ||
      this.fromDateExcel === '' && this.toDateExcel === '' || this.fromDateExcel !== '' && this.toDateExcel === '') && dateChecker) {
      dateFilter = {
        from_date: fromDate,
        to_date: toDate,
        key: ''
      };
      switch (this.status) {
        case 'Out of Specification':
          this.outOfSpecificationService(dateFilter);
          break;
        case 'Recall':
          this.recallService(dateFilter);
          break;

        case 'Pass':
          this.passService(dateFilter);
          break;
      }
    } else {
      this.toastr.warning('Check From Date');
    }
  }



  recallService(dateFilter) {

    if (this.label === 'CHEMICAL LOT #' || this.label === 'COMPONENT LOT #') {
      this.inventorySubmitProduct(dateFilter);
    } else if (this.label === 'FINAL PRODUCT LOT #' || this.label === 'FINAL PRODUCT') {
      this.finalSubmitProduct(dateFilter);
    } else if (this.label === 'ENVIRONMENTAL MONITORING' || this.label === 'PERSONNEL MONITORING') {
      this.emPmSubmit(dateFilter, '/Recall/');
    } else {
      this.recallSubmit(dateFilter);
    }

  }






  outOfSpecificationService(dateFilter) {
    if (this.label === 'CHEMICAL LOT #' || this.label === 'COMPONENT LOT #') {
      dateFilter.key = this.kindCheck();
      this.statusPatch('Status', 'Quarantine');
      this.submitStatus(dateFilter);
    } else if (this.label === 'FINAL PRODUCT LOT #' || this.label === 'FINAL PRODUCT') {
      this.statusPatch('Status', 'Quarantine');
      this.finalSubmitStatus(dateFilter);
    } else if (this.label === 'ENVIRONMENTAL MONITORING' || this.label === 'PERSONNEL MONITORING') {
      this.label === 'ENVIRONMENTAL MONITORING' ?
        this.statusPatch('Environmental_monitoring_result', 'Fail') : this.statusPatch('Personnel_monitoring_result', 'Fail');
      this.emPmSubmit(dateFilter, '/Status/');
    } else {
      dateFilter.key = this.kindCheck();
      this.label === 'USER' ? this.statusPatch('Status', 'Retired') : this.statusPatch('Status', 'Out of Specification');
      this.submitStatus(dateFilter);


    }

  }



  passService(dateFilter) {
    if (this.label === 'CHEMICAL LOT #' || this.label === 'COMPONENT LOT #') {
      dateFilter.key = this.kindCheck();
      this.statusPatch('Status', 'Released');
      this.submitStatus(dateFilter);
    } else if (this.label === 'FINAL PRODUCT LOT #' || this.label === 'FINAL PRODUCT') {
      this.statusPatch('Status', 'Released');
      this.finalSubmitStatus(dateFilter);
    } else if (this.label === 'ENVIRONMENTAL MONITORING' || this.label === 'PERSONNEL MONITORING') {
      this.label === 'ENVIRONMENTAL MONITORING' ?
        this.statusPatch('Environmental_monitoring_result', 'Pass') : this.statusPatch('Personnel_monitoring_result', 'Pass');
      this.emPmSubmit(dateFilter, '/Status/');
    } else {
      dateFilter.key = this.kindCheck();
      this.statusPatch('Status', 'Current');
      this.submitStatus(dateFilter);


    }

  }

  statusPatch(key, value) {
    let obj = {};
    obj = {
      'Field_name': key,
      'Field_value': value,
      'Field_type': 'string'
    };
    this.fieldValueArray.push(obj);

  }

  /*
  ** ==========================================================
  ** Final Submit Product
  ** ==========================================================
  */

  finalSubmitProduct(value) {
    let moduleType;
    let lotCheck = true;
    var model: any;
    if (this.label === 'FINAL PRODUCT') {
      moduleType = 'Product';
      model = this.modelConstruct(value);
      if (model.Fields.length === 0) {
        this.toastr.warning('Select Filter Values');
        this.reportGenerate = true;
        lotCheck = false;
      }
    } else {
      moduleType = 'Lot_number';
      this.lotNumberConstruct();
      model = this.modelConstruct(value);
      if (this.lotNumberValues === '') {
        this.toastr.warning('Enter Lot Value');
        this.reportGenerate = true;
        lotCheck = false;
      }
    }
    if (lotCheck) {
      // this.spinnerService.show();
      this.ngxService.start();
      this.commonService(moduleType, model, '/FinalProduct/', '');
    }
  }

  inventorySubmitProduct(value) {
    if (this.lotNumberValues === '') {
      this.toastr.warning('Enter Lot Value');
      this.reportGenerate = true;
    } else {
      this.fieldValueArray = [];
      this.lotNumberConstruct();
      const model = this.modelConstruct(value);
      const type = this.label === 'CHEMICAL LOT #' ? 'Chemical' : 'Component';
      this.commonService(type, model, '/InventoryReport/', '');
    }
  }


  emPmSubmit(value, moduleType) {
    const type = this.label === 'ENVIRONMENTAL MONITORING' ? 'EM' : 'PM';
    let model: any;
    model = this.modelConstruct(value);
    if (model.Fields.length === 0) {
      this.toastr.warning('Select Filter Values');
      this.reportGenerate = true;
    } else {
      this.commonService(type, model, '/Monitoring/', moduleType);
    }
  }

  recallSubmit(value) {
       
    const type = this.recallTypeCheck();
    let model: any;
    model = this.modelConstruct(value);
    if (model.Fields.length === 0) {
      this.toastr.warning('Select Filter Values');
      this.reportGenerate = true;
    } else {
      // this.spinnerService.show();
      this.ngxService.start();
      this.commonService(type, model, '/Recall/', '');
    }
  }


  finalSubmitStatus(dataFilter) {
    const model = this.modelConstruct(dataFilter);
    this.commonService('', model, '/FinalProduct/', 'Status');


  }
  submitStatus(dataFilter) {
    const modelValue = this.recallTypeCheck();
    const model = this.modelConstruct(dataFilter);
    this.commonService('', model, '/Status/', modelValue);
  }





  commonService(type, model, key, moduleType) {
    // this.spinnerService.show();
    this.ngxService.start();
    this.dataService.add(this.configuration.report + key + moduleType + '/' + type, model).subscribe(
      (data: any) => {
        // this.spinnerService.hide();
        this.ngxService.stopAll();
        this.dataResult = data.result.result;
        this.emitValue(this.dataResult);
      });
  }




  lotNumberConstruct() {
    this.filterArrayCollection('Lot_number', this.lotNumberValues);
  }



  modelConstruct(value) {
    let model = {};
    const dateRange = value.from_date === '' ? '' : 'Created_info.Date';
    model = {
      Kind: value.key,
      Daterange_with: dateRange,
      Start_date: value.from_date,
      End_date: value.to_date,
      Range: '',
      Fields: this.fieldValueArray,
      Sort_with: '',
      Sort_by: '',
    };
    return model;
  }


  finalProductChange(event) {
    let fieldValue = this.selectItems.filter(a => a.value === event.value);
    this.filterArrayCollection('Master_formulation_record.Product.id', fieldValue[0].value);
  }


  locationChange(event, id) {
    const fieldValue = this.selectItems.filter(a => a.value === event.value);
    if (id === 'EM') {
      this.filterArrayCollection('Location.id', fieldValue[0].value);
    } else {
      this.filterArrayCollection('id', fieldValue[0].value);
    }
  }

  operatorChange(event, id) {
    const fieldValue = this.selectItems.filter(a => a.value === event.value);
    this.filterArrayCollection('id', fieldValue[0].value);
  }

  facilityChange(event) {
    const fieldValue = this.selectItems.filter(a => a.value === event.value);
    this.filterArrayCollection('Facility.id', fieldValue[0].value);
  }

  equipmentChange(event) {
    const fieldValue = this.selectItems.filter(a => a.value === event.value);
    this.filterArrayCollection('id', fieldValue[0].value);

  }

  filterArrayCollection(Name, Value) {
    this.fieldValueArray = [];
    let obj = {};
    obj = {
      'Field_name': Name,
      'Field_value': Value,
      'Field_type': 'string'
    };
    this.fieldValueArray.push(obj);
  }


  recallTypeCheck() {

    let name;
    switch (this.label) {
      case 'FACILITY':
        name = 'Facility';
        break;
      case 'USER':
        name = 'User';
        break;
      case 'LOCATION':
        name = 'Location';
        break;
      case 'EQUIPMENT':
        name = 'Equipment';
        break;
      case 'CHEMICAL LOT #':
        name = 'Chemical';
        break;
      case 'COMPONENT LOT #':
        name = 'Chemical';
        break;
    }

    return name;
  }
  kindCheck() {
    let kind;
    switch (this.label) {
      case 'FACILITY':
        kind = 'facility';

        break;
      case 'USER':
        kind = 'manage_user';
        break;
      case 'LOCATION':
        kind = 'location';
        break;
      case 'EQUIPMENT':
        kind = 'equipment';
        break;
      case 'CHEMICAL LOT #':
        kind = 'chemical_child';
        break;
      case 'COMPONENT LOT #':
        kind = 'component_child';
        break;

    }

    return kind;
  }

  emitValue(value) {
    this.emitChildRecordEvent.emit(value);
    this.CancelDialog.emit(false);


  }

}
