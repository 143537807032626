import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { DataService } from '@app/shared/service/data-service';
import { Configuration } from '@app/app.configuration';
import { CustomRoutingName } from '@app/app.custom-routing-name';
import { ValidationCheck } from '../common-validation';
import { ToastBaseModel } from '@app/shared/model/toast-data-model';
import { StorageConfiguration } from '../storage-setting/storage-configuration';
import { AuditFiles } from '../audit-File';
import { NameCapitalize, yearCalculate } from '../common-helper';
import { NotificationModel } from '@app/shared/model/notification-model.interface';
import { Filedatamodel } from '@app/shared/model/file-process.interface';
import { RolesPermissions } from '../roles-permission';
import { GetEquipment, Calibration } from '@app/module/facility/model/equipment.interface';
import { SettingHelper } from '../company-setting-helper';
import { UnitsType } from '@app/module/production/model/manage-batch-record.interface';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-date-transaction',
  templateUrl: './date-transaction.component.html',
  styleUrls: ['./date-transaction.component.sass']
})
export class DateTransactionComponent implements OnInit {

  @Output() CancelDialog = new EventEmitter<any>();
  @Output() addChild = new EventEmitter<any>();
  @Output() updateEquipment = new EventEmitter<any>();
  @Input() mode: any;
  resetForm: void;
  @Input() equipmentTransactionDetails: any;
  equipmentDetails: FormGroup;
  Transactiontype: string;
  Retiredvalue: boolean;
  matchStatus: boolean;
  moveEquipment: FormGroup;
  Filedatamodel: Filedatamodel[];
  showMove: boolean;
  matchEditItems: boolean;
  matchRetiredDate: boolean;
  id: string;
  apiRoute = 'Equipment/';
  equipmentResponse: any;
  typeSelectItem: SelectItem[];
  createdName: string;
  createdId: string;
  date: string;
  classificationSelectItems: SelectItem[];
  ManufacturerSelectItems: SelectItem[];

  locationSelectItems: any[] = [];
  locationSelect: any[] = [];
  locationSelectForMove: any[] = [];
  emptyArray = [];
  getEquipmentArray = [];
  activeGetSingle = [];
  facilitySelectItems: SelectItem[];
  requestSelectItems: SelectItem[];
  moveSelectItem: SelectItem[];
  IntervalTypeSelectItem: SelectItem[];
  dataTableEquipment: any;
  referenceTypeName = 'equipment';
  setuploadfiles: any;
  active = true;
  accessuri: string;
  activity = [];
  activesubModel = [];
  equipmentFormsValue: any;
  calibration_document = 'calibration_document';
  qualification_document = 'qualification_document';
  preventative_maintenance_document = 'preventative_maintenance_document';
  yrRange: any;
  modelStatus: string;
  userInfo: any;
  permissionSetting: any;
  viewDetail = false;
  permissoinSetting: any;
  eqName: string;
  notificationModel: NotificationModel;
  notificationForm: FormGroup;
  statusValue: any;
  responseBody: any;
  pdfview: any;
  display_GenerateLabel: boolean;
  Calibration: any;
  Validation: any;
  Preventative: any;
  viweCalibrationDetails: boolean;
  viwePreventativeDetails: boolean;
  viweValidationDetails: boolean;
  Transactions: FormGroup;
  allowOperatorVerify: boolean;
  UserCodeForm: FormGroup;

  constructor(
    private router: Router,
    private dataService: DataService,
    private configuration: Configuration,
    private fb: FormBuilder,
    private spinnerService: Ng4LoadingSpinnerService,
    private customRoutingName: CustomRoutingName,
    private route: ActivatedRoute,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private validationCheck: ValidationCheck,
    private auditFiles: AuditFiles,
    private permission: RolesPermissions,
    private yearRange: yearCalculate,
    private settingHelper: SettingHelper,
    private storageConfiguration: StorageConfiguration,
    private nameCapitalize: NameCapitalize
  ) {
    this.IntervalTypeSelectItem = [
      // { label: 'Select', value: 'Select' },
      { label: 'Days', value: 'Days' },
      { label: 'Months', value: 'Months' },
    ];
  }


  ngOnInit() {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.permissionSetting = this.permission.getPermission('Facility');
    if (((this.permissionSetting.View) || (this.permissionSetting.Create)) && (!this.permissionSetting.Edit)) {
      this.viewDetail = true;
    }
    this.createForm();
    this.createdId = this.userInfo[0].id;
    this.createdName = this.userInfo[0].name;
    this.spinnerService.show();
    this.route.params.subscribe(params => { this.id = params['id']; });
    this.getEquipment();
    this.yrRange = this.yearRange.yrRange;
  }

  /*
** ==========================================================
** Change Activity Log
** ==========================================================
*/
  createForm() {
    this.equipmentDetails = this.fb.group({
      Name: ['', Validators.required],
      Classification: ['', Validators.required],
      Location: this.fb.group({
        Location_id: ['', Validators.required],
        Location_name: [''],
      }),
      Serial_number: [''],
      Calibration: this.fb.group({
        Current_date: [''],
        Interval: this.fb.group({
          Value: [0],
          Unit: [''],
        }),
        Due_date: [''],
        Status: [''],
        Time_required: [0],
        Is_required: false,
      }),
      Validation: this.fb.group({
        Current_date: [''],
        Interval: this.fb.group({
          Value: [0],
          Unit: [''],
        }),
        Due_date: [''],
        Status: [''],
        Time_required: [0],
        Is_required: false,
      }),
      Preventative_maintenance: this.fb.group({
        Current_date: [''],
        Interval: this.fb.group({
          Value: [0],
          Unit: [''],
        }),
        Due_date: [''],
        Status: [''],
        Time_required: [0],
        Is_required: false,
      }),
      Facility: this.fb.group({
        Facility_id: ['', Validators.required],
        Facility_name: [''],
      }),
      In_house_calibration: false,
      Date_installed: ['', Validators.required],
      Status: ['', Validators.required],
      File_data: Filedatamodel,
      Activity_log: this.fb.array([]),
      Retired_date: this.date,
      Move_equipment: this.fb.array([]),
      Transactions: [],
      id: [''],
      Kind: [''],
      Created_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: [''],
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: [''],
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: [''],
      }),
      Is_active: false,
      Is_deleted: false,
      Is_name_changed: false,
      Is_name_disable: false,
      Manufacturer: [''],
      Barcode: [''],
      Label_url: [''],
      _etag: ['']
    });
    this.date = new Date().toISOString();


    this.moveEquipment = this.fb.group({
      Equipmnet_name: [''],
      From_location: this.fb.group({
        id: [''],
        Name: ['']
      }),
      id: [''],
      To_location: this.fb.group({
        id: ['', Validators.required],
        Name: ['']
      }),
      From_facility: this.fb.group({
        id: [''],
        Name: ['']
      }),
      To_facility: this.fb.group({
        id: ['', Validators.required],
        Name: ['']
      }),

      Equipment_status: [''],
      Serial_number: [''],
      Notes: [''],
      Status: [''],
      Retired_date: [''],
      Requested_info: this.fb.group({
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: [new Date(this.date), Validators.required],
      }),
      Verified_info: this.fb.group({
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date(this.date),
      })
    });

    this.Transactions = this.fb.group({
      Transaction_type: [''],
      Current_date: [''],
      Interval: this.fb.group({
        Units: [0],
        Type: [''],
      }),
      Due_date: [''],
      Time_required: [''],
      Verified_info: this.fb.group({
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date(this.date),
      })
    });
    this.onChangeActive();

    this.notificationForm = this.fb.group({
      NotificationInfo: this.fb.group({
        Severity: [''],
        Module_type: [''],
        Tenant_id: [''],
        Message: [''],
        Supporting_fields: [''],
        Action: [''],
        Current_user: this.fb.group({
          id: [''],
          Name: ['']
        })
      }),
      Payload: ['']

    });

    this.UserCodeForm = this.fb.group({
      UserCode: [''],
      Type: [''],
      ModelType: [''],
    });
  }

  /*
 ** ==========================================================
 ** Change Activity Log
 ** ==========================================================
 */
  onChangeActive() {
    this.equipmentDetails.valueChanges.subscribe(val => {
      this.equipmentFormsValue = val;
    });
  }
  /*
 ** ==========================================================
 ** get Equipment
 ** ==========================================================
 */

  getEquipment() {
    this.dataService.getSingle(this.id, this.configuration.equipment + '/').subscribe(
      (data: GetEquipment) => {
        this.spinnerService.hide();
        this.getEquipmentArray = [];
        this.activeGetSingle = [];
        this.accessuri = data.accessuri;
        this.equipmentResponse = data.result;
        this.eqName = data.result.Name;
        this.dataTableEquipment = data.result.Move_equipment;
        this.active = true;
        if (this.equipmentResponse.Activity_log != null) {
          this.equipmentResponse.Activity_log.forEach(
            element => {
              this.activity.push(element);
            }
          );
        }
        this.pdfview = data.result.Label_url;
        this.activeGetSingle.push(data.result);
        this.getEquipmentArray = JSON.parse(JSON.stringify(this.activeGetSingle));
        this.equipmentResponse.Calibration.Current_date =
          this.equipmentResponse.Calibration.Current_date === '' ? '' : new Date(this.equipmentResponse.Calibration.Current_date);
        this.equipmentResponse.Calibration.Due_date =
          this.equipmentResponse.Calibration.Due_date === '' ? '' : new Date(this.equipmentResponse.Calibration.Due_date);
        this.equipmentResponse.Validation.Current_date =
          this.equipmentResponse.Validation.Current_date === '' ? '' : new Date(this.equipmentResponse.Validation.Current_date);
        this.equipmentResponse.Validation.Due_date =
          this.equipmentResponse.Validation.Due_date === '' ? '' : new Date(this.equipmentResponse.Validation.Due_date);
        this.equipmentResponse.Preventative_maintenance.Current_date =
          this.equipmentResponse.Preventative_maintenance.Current_date === '' ? '' : new Date(this.equipmentResponse.Preventative_maintenance.Current_date);
        this.equipmentResponse.Preventative_maintenance.Due_date =
          this.equipmentResponse.Preventative_maintenance.Due_date === '' ? '' : new Date(this.equipmentResponse.Preventative_maintenance.Due_date);
        this.equipmentResponse.Date_installed =
          this.equipmentResponse.Date_installed === '' ? '' : new Date(this.equipmentResponse.Date_installed);
        this.equipmentResponse.Calibration.Current_date =
          this.equipmentResponse.Calibration.Current_date === '' ? '' : new Date(this.equipmentResponse.Calibration.Current_date);
        this.equipmentResponse.Retired_date =
          this.equipmentResponse.Retired_date === '' ? '' : new Date(this.equipmentResponse.Retired_date);
        this.bindSupportingOptions(data);
        this.statusValue = this.equipmentResponse.Status;
        this.patchLocation(this.equipmentResponse);
        this.patchMoveForm(this.equipmentResponse);
        this.equipmentDetails.patchValue({
          Manufacturer: this.equipmentResponse.Manufacturer
        });
        this.equipmentDetails.patchValue(this.equipmentResponse);
        this.equipmentDetails.patchValue({
          _etag: this.equipmentResponse._etag,
          Transactions: this.equipmentResponse.Transactions
        });
      }
    );
  }
  /*
** ==========================================================
** Bind DropDown
** ==========================================================
*/
  bindSupportingOptions(data: any) {

    this.locationSelectItems = this.bindDropDown(data.options.Location, 'location');
    this.facilitySelectItems = this.bindDropDown(data.options.Facility, 'Facility');
    this.ManufacturerSelectItems = this.bindDropDown(data.options.CompanySettings.Equipment_manufacturer.Value, 'ManufacturerSelectItems');
    this.classificationSelectItems =
      this.settingHelper.getStorageCondition
        (data.options.CompanySettings.Equipment_classification.Value, false, this.equipmentResponse.Classification);
    this.requestSelectItems = this.bindDropDown(data.options.Operators, 'requestlist');
  }
  /*
  ** ==========================================================
  ** bind Drop Down
  ** ==========================================================
  */
  bindDropDown(array: any[], ddType: string): any[] {
    const dropDownItem = [];
    array.forEach((a) => {
      switch (ddType) {
        case 'equipementItems':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'location':
          dropDownItem.push({ label: a.Name, value: a.id, facilityId: a.Facility_id, facilityname: a.Facility_name });
          break;
        case 'Facility':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;

        case 'requestlist':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;

        case 'ManufacturerSelectItems':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
      }
    });
    return dropDownItem;
  }

  /*
  ** ==========================================================
  **patch Location
  ** ==========================================================
  */
  patchLocation(equipmentResponse) {
    this.locationSelect = this.locationSelectItems.filter(a => a.facilityId === equipmentResponse.Facility.Facility_id);
  }
  formrevert() {
    this.equipmentDetails.patchValue(this.equipmentResponse);
  }


  /*
 ** ==========================================================
 **   patch Move Form
 ** ==========================================================
 */


  patchMoveForm(result) {

    this.moveEquipment.patchValue({
      Equipmnet_name: result.Name,
      From_location: {
        id: result.Location.Location_id,
        Name: result.Location.Location_name,
      },
      Equipment_status: result.Status,
      Serial_number: result.Serial_number,
      From_facility: {
        id: result.Facility.Facility_id,
        Name: result.Facility.Facility_name,
      },

    });

  }


  cancel() {
    this.resetForm = this.equipmentDetails.reset();
    this.CancelDialog.emit(this.resetForm);
  }
  callCalibration() {
    this.viweCalibrationDetails = true;
    this.viweValidationDetails = false;
    this.viwePreventativeDetails = false;
    this.Transactiontype = 'Calibration';
    this.equipmentDetails.patchValue({
      Calibration: {
        Current_date: this.equipmentTransactionDetails.Calibration.Current_date,
        Due_date: this.equipmentTransactionDetails.Calibration.Due_date,
        Time_required: this.equipmentTransactionDetails.Calibration.Time_required,
        Interval: {
          Value: this.equipmentTransactionDetails.Calibration.Interval.Value,
          Unit: this.equipmentTransactionDetails.Calibration.Interval.Unit,
        },
      }
    });
  }
  callValidation() {
    this.viweCalibrationDetails = false;
    this.viweValidationDetails = true;
    this.viwePreventativeDetails = false;
    this.Transactiontype = 'Validation / Qualification';
    this.equipmentDetails.patchValue({
      Validation: {
        Current_date: this.equipmentTransactionDetails.Validation.Current_date,
        Due_date: this.equipmentTransactionDetails.Validation.Due_date,
        Time_required: this.equipmentTransactionDetails.Validation.Time_required,
        Interval: {
          Value: this.equipmentTransactionDetails.Validation.Interval.Value,
          Unit: this.equipmentTransactionDetails.Validation.Interval.Unit,
        },
      }
    });
  }
  callPreventative() {
    this.viwePreventativeDetails = true;
    this.viweCalibrationDetails = false;
    this.viweValidationDetails = false;
    this.Transactiontype = 'Preventative Maintenance';
    this.equipmentDetails.patchValue({
      Preventative_maintenance: {
        Current_date: this.equipmentTransactionDetails.Preventative_maintenance.Current_date,
        Due_date: this.equipmentTransactionDetails.Preventative_maintenance.Due_date,
        Time_required: this.equipmentTransactionDetails.Preventative_maintenance.Time_required,
        Interval: {
          Value: this.equipmentTransactionDetails.Preventative_maintenance.Interval.Value,
          Unit: this.equipmentTransactionDetails.Preventative_maintenance.Interval.Unit,
        },
      }
    });
  }

  onSubmit(data) {
    let pipe = new DatePipe('en-US');
    let CalibrationdueDate = "";
    let ValidationdueDate = "";
    let PMdueDate = "";

    if (data.value.Calibration.Due_date != '') {
      CalibrationdueDate = pipe.transform(data.value.Calibration.Due_date.toUTCString(), 'yyyy-MM-dd');
    }
    if (data.value.Validation.Due_date != '') {
      ValidationdueDate = pipe.transform(data.value.Validation.Due_date.toUTCString(), 'yyyy-MM-dd');
    }
    if (data.value.Preventative_maintenance.Due_date != '') {
      PMdueDate = pipe.transform(data.value.Preventative_maintenance.Due_date.toUTCString(), 'yyyy-MM-dd');
    }
    let currentDate = pipe.transform(new Date().toUTCString(), 'yyyy-MM-dd');
    if (CalibrationdueDate != '' && CalibrationdueDate <= currentDate) {
      this.toastr.warning('Calibration due date must be grater then the today\'s Date');
    } else if (ValidationdueDate != '' && ValidationdueDate <= currentDate) {
      this.toastr.warning('Validation/Qualification due date must be grater then the today\'s Date');
    } else if (PMdueDate != '' && PMdueDate <= currentDate) {
      this.toastr.warning('Preventative Maintenance due date must be grater then the today\'s Date');
    } else {
      this.allowOperatorVerify = true;
    }
  }

  cancelOperator() {
    this.allowOperatorVerify = false;
  }
  /*
 ** ==========================================================
 ** User Verify Method
 ** ==========================================================
 */
  UserSubmit(data) {
    data.value.Type = 'Verified';
    data.value.ModelType = '5';
    this.dataService
      .add(this.configuration.manageUser + '/VerifyCode/' + data.value.UserCode + ',' + data.value.Type + ',' + data.value.ModelType, '')
      .subscribe(
        (data: any) => {
          const userId = this.userInfo[0].id;
          if (data.message.result === 'User profile not valid to verify') {
            this.toastr.warning(data.message.result);
          } else {
            const responseId = data.message.result.id;
            if (userId === responseId) {
              let TransactionDetailList = [];
              if (this.Calibration) {
                const calibrationdetail: TransactionDetail = {
                  Transaction_type: this.Transactiontype,
                  Current_date: this.equipmentDetails.value.Calibration.Current_date,
                  Interval: {
                    Units: this.equipmentDetails.value.Calibration.Interval.Value,
                    Type: this.equipmentDetails.value.Calibration.Interval.Unit,
                  },
                  Due_date: this.equipmentDetails.value.Calibration.Due_date,
                  Time_required: this.equipmentDetails.value.Calibration.Time_required,
                  Verified_info: {
                    Name: data.message.result.Name,
                    By: data.message.result.id,
                    Date: new Date().toISOString(),
                  }
                };
                TransactionDetailList.push(calibrationdetail);
                this.equipmentDetails.value.Calibration.Is_required = true;

              } else if (this.Validation) {
                const validationdetail: TransactionDetail = {
                  Transaction_type: this.Transactiontype,
                  Current_date: this.equipmentDetails.value.Validation.Current_date,
                  Interval: {
                    Units: this.equipmentDetails.value.Validation.Interval.Value,
                    Type: this.equipmentDetails.value.Validation.Interval.Unit,
                  },
                  Due_date: this.equipmentDetails.value.Validation.Due_date,
                  Time_required: this.equipmentDetails.value.Validation.Time_required,
                  Verified_info: {
                    Name: data.message.result.Name,
                    By: data.message.result.id,
                    Date: new Date().toISOString(),
                  }
                };

                TransactionDetailList.push(validationdetail);
                this.equipmentDetails.value.Validation.Is_required = true;
              } else if (this.Preventative) {
                const pmdetail: TransactionDetail = {
                  Transaction_type: this.Transactiontype,
                  Current_date: this.equipmentDetails.value.Preventative_maintenance.Current_date,
                  Interval: {
                    Units: this.equipmentDetails.value.Preventative_maintenance.Interval.Value,
                    Type: this.equipmentDetails.value.Preventative_maintenance.Interval.Unit,
                  },
                  Due_date: this.equipmentDetails.value.Preventative_maintenance.Due_date,
                  Time_required: this.equipmentDetails.value.Preventative_maintenance.Time_required,
                  Verified_info: {
                    Name: data.message.result.Name,
                    By: data.message.result.id,
                    Date: new Date().toISOString(),
                  }
                };
                TransactionDetailList.push(pmdetail);
                this.equipmentDetails.value.Preventative_maintenance.Is_required = true;
              }
              TransactionDetailList.forEach(element => {
                this.equipmentDetails.value.Transactions.push(element);
              });
              let activeModel = [];
              activeModel = this.auditFiles.activityModule(this.equipmentDetails, '');
              this.equipmentDetails.value.Activity_log = activeModel;
              // this.equipmentDetails.value.Transactions = TransactionDetailList;  
              this.UpdateEquipment(this.equipmentDetails);
              this.allowOperatorVerify = false;
              this.addChild.emit(this.equipmentDetails);
            } else {
              this.toastr.warning('Invalid secret id');
            }
          }
        }, err => {
        });
  }

  /*
    ** ==========================================================
    ** Update Function
    ** ==========================================================
    */
  UpdateEquipment(data) {
    data.value.Updated_info.Name = this.userInfo[0].name;
    data.value.Updated_info.By = this.userInfo[0].id;
    data.value.Updated_info.Date = new Date().toISOString();
    data.value.id = this.id;
    data.value.Kind = 'equipment';
    this.dataService.update(this.id, this.configuration.equipment + '/', data.value).subscribe(
      (data: any) => {
        this.toastr.success(this.toastMessage.Equipment + ' ' + this.toastMessage.Update);
        window.location.reload();
      });
  }
  /*
   ** ==========================================================
   **validation Interval
   ** ==========================================================
   */

  validationInterval(value) {
    if (this.equipmentDetails.value.Validation.Interval.Unit === 'Days') {
      var date = new Date(this.equipmentDetails.value.Validation.Current_date);
      date.setDate(date.getDate() + parseInt(this.equipmentDetails.value.Validation.Interval.Value, 10));
      this.equipmentDetails.patchValue({ Validation: { Due_date: date } });
    } else if (this.equipmentDetails.value.Validation.Interval.Unit === 'Months') {
      var date = new Date(this.equipmentDetails.value.Validation.Current_date);
      var a = parseInt(this.equipmentDetails.value.Validation.Interval.Value, 10);
      var calDate = new Date(date.getFullYear(), date.getMonth() + a, date.getDate(), 23, 59, 59);
      this.equipmentDetails.patchValue({ Validation: { Due_date: calDate } });
    }
  }

  /*
 ** ==========================================================
 ** calibration Interval
 ** ==========================================================
 */

  calibrationInterval(value) {
    if (this.equipmentDetails.value.Calibration.Interval.Unit === 'Days') {
      var date = new Date(this.equipmentDetails.value.Calibration.Current_date);
      date.setDate(date.getDate() + parseInt(this.equipmentDetails.value.Calibration.Interval.Value, 10));
      this.equipmentDetails.patchValue({ Calibration: { Due_date: date } });
    } else if (this.equipmentDetails.value.Calibration.Interval.Unit === 'Months') {
      var date = new Date(this.equipmentDetails.value.Calibration.Current_date);
      var a = parseInt(this.equipmentDetails.value.Calibration.Interval.Value, 10);
      var calDate = new Date(date.getFullYear(), date.getMonth() + a, date.getDate(), 23, 59, 59);
      this.equipmentDetails.patchValue({ Calibration: { Due_date: calDate } });
    }
  }

  /*
  ** ==========================================================
  ** pm Interval
  ** ==========================================================
  */
  pmInterval(event) {
    if (this.equipmentDetails.value.Preventative_maintenance.Interval.Unit === 'Days') {
      var date = new Date(this.equipmentDetails.value.Preventative_maintenance.Current_date);
      date.setDate(date.getDate() + parseInt(this.equipmentDetails.value.Preventative_maintenance.Interval.Value, 10));
      this.equipmentDetails.patchValue({ Preventative_maintenance: { Due_date: date } });
    } else if (this.equipmentDetails.value.Preventative_maintenance.Interval.Unit === 'Months') {
      var date = new Date(this.equipmentDetails.value.Preventative_maintenance.Current_date);
      var a = parseInt(this.equipmentDetails.value.Preventative_maintenance.Interval.Value, 10);
      var calDate = new Date(date.getFullYear(), date.getMonth() + a, date.getDate(), 23, 59, 59);
      this.equipmentDetails.patchValue({ Preventative_maintenance: { Due_date: calDate } });
    }

  }

}
export interface TransactionDetail {
  Transaction_type: string;
  Current_date: string;
  Interval: UnitsTypes;
  Due_date: string;
  Time_required: string;
  Verified_info: VerifiedInfo;
}
export class UnitsTypes {
  Units: number;
  Type: string;
}
export class VerifiedInfo {
  Name: string;
  By: string;
  Date: string;
}