export class BaseModel {
    id: string;
    Kind: string;
    Created_info: BaseIdentityModel;
    Updated_info: BaseIdentityModel;
    Deleted_info: BaseIdentityModel;
    Is_active: boolean;
    Is_media?: boolean;
    Is_deleted: boolean;
    _etag: string;
}

export class BaseIdentityModel {
    Name: string;
    By: string;
    Date: string;
}

export class BaseSettingsModel {
    Name: string;
    Label: string;
    Value: Value[];
}

export class Value {
    id: string;
    Value: string;
    Name: string;
    Order_by: number;
    Is_active: boolean;
}

