// import 'rxjs/add/operator/map';
import { Injectable } from '@angular/core';


@Injectable({
    providedIn: 'root'
})
export class GenerateId {

    timestamp = +new Date;

    getId() {
        length = 25;

        var ts = this.timestamp.toString();
        var parts = ts.split("").reverse();
        var id = "";

        for (var i = 0; i < length; ++i) {
            var index = generateRandomNumber(0, parts.length - 1);
            id += parts[index];
        }
        return id;
    }

    // getIdentificationcode() {
    //     length = 10;
    //     var ts = this.timestamp.toString();
    //     var parts = ts.split("").reverse();
    //     var id = "";

    //     for (var i = 0; i < length; ++i) {
    //         var index = generateRandomNumber(0, parts.length - 1);
    //         id += parts[index];
    //     }
    //     return id;
    // }

    getskuId(val) {
        length = val;
        var ts = this.timestamp.toString();
        var parts = ts.split("").reverse();
        var id = "";

        for (var i = 0; i < length; ++i) {
            var index = generateRandomNumber(0, parts.length - 1);
            id += parts[index];
        }
        return id;
    }

    getRandomNumber(val) {
        length = val;
        var ts = this.timestamp.toString();
        var parts = ts.split("").reverse();
        var id = "";

        for (var i = 0; i < length; ++i) {
            var index = generateRandomNumber(0, parts.length - 1);
            id += parts[index];
        }
        return id;
    }



}

function generateRandomNumber(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export class sortingArray {

    // sorting the array
    sorting(arrayValue) {
        arrayValue.sort(function (a, b) {
            var valueA = a.label.toLowerCase(), valueB = b.label.toLowerCase()
            if (valueA < valueB) //sort string ascending
                return -1
            if (valueA > valueB)
                return 1
            return 0 //default return value (no sorting)
        })
        return arrayValue;
    }
}




export class sortingSetting {

    // sorting the array
    sorting(arrayValue) {
        arrayValue.sort(function (a, b) {
            var valueA = a.Label.toLowerCase(), valueB = b.Label.toLowerCase()
            if (valueA < valueB) //sort string ascending
                return -1
            if (valueA > valueB)
                return 1
            return 0 //default return value (no sorting)
        })
        return arrayValue;
    }
}
export class yearCalculate {
    dateToday = new Date();
    yrRange = (this.dateToday.getFullYear() - 10) + ":" + (this.dateToday.getFullYear() + 30);
    yearRange = this.yrRange;
}

export class sortBatchOperator {

    sorting(arrayValue) {
        arrayValue.sort(function (a, b) {
            var valueA = a.Name.toLowerCase(), valueB = b.Name.toLowerCase()
            if (valueA < valueB) //sort string ascending
                return -1
            if (valueA > valueB)
                return 1
            return 0 //default return value (no sorting)
        })
        return arrayValue;
    }
}
export class

    NameCapitalize {
    nameCapitalChange(name: string) {
        return name.replace(/\b\w/g, l => l.toUpperCase());
    }
}

@Injectable({
    providedIn: 'root'
})
export class PdfLabelCount {
    getPDFSpiltCount(count, limit) {
        let result = [];
        count = parseInt(count);
        let isThreasholdReached = (limit + 1000) < count ? true : false;
        if (!isThreasholdReached) {
            result.push(1 + ' - ' + count);
        } else {
            let quotient = count / limit;
            let remainder = count % limit;
            quotient = parseInt(quotient.toString());
            for (let i = 1; i <= quotient; i++) {
                let startCounter = i == 1 ? 1 : (limit * (i - 1)) + 1;
                if (i == quotient) {
                    let splittedCount = [];
                    let endCounter = (limit * i) + remainder;
                    splittedCount.push(startCounter + ' - ' + endCounter);
                    result.push(splittedCount);
                } else {
                    let splittedCount = [];
                    let endCounter = (limit * i);
                    splittedCount.push(startCounter + ' - ' + endCounter);
                    result.push(splittedCount);
                }
            }
        }
        return result;

    }
}

