import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { StorageConfiguration } from '../storage-setting/storage-configuration';



@Component({
  selector: 'app-common-notes',
  templateUrl: './common-notes.component.html',
  styleUrls: ['./common-notes.component.sass']
})
export class CommonNotesComponent implements OnInit {

  constructor(
    private fb: FormBuilder,
    private toastr: ToastrService,
    private storageConfiguration: StorageConfiguration,



  ) { }
  notesForm: FormGroup;
  userInfo: any;
  ipAddress: any;
  NotesModel: any;
  @Input() notesTotalData: any;
  @Input() editedNotes: any;
  @Input() editNotesPopup: boolean;
  @Output() notesResult = new EventEmitter<string[]>();
  @Output() restNotes = new EventEmitter<any>();
  ngOnInit() {
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    const publicIp = require('public-ip');
    publicIp.v4().then(ip => {
      this.ipAddress = ip;
    });


    this.notesForm = this.fb.group({
      Note: ['', Validators.required],
      Ip_address: [''],
      Name: [''],
      By: [''],
      Date: [''],
    });
  }

  addNotes(notes) {

    if (notes === undefined || notes === null || notes === '') {
      this.toastr.warning('Please Enter Notes');
    } else {
      this.notesForm.patchValue({
        Note: notes,
        Ip_address: this.ipAddress,
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date()
      });

      let obj = {};
      obj = this.notesForm.value;
      let array = [];
      let value = this.notesTotalData;

      array = value === null ? [] : value === undefined ? [] : value;
      array.push(obj);
      this.NotesModel = '';

      this.notesResult.emit(array);

    }

  }












}
