import { Component, OnInit, Output, Input, EventEmitter, SimpleChanges, SimpleChange } from '@angular/core';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Response, ResponseBody, FileResponse, FileResponseBody } from '../../model/service-response.interface';
import { FormBuilder, FormGroup, FormArray } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationService } from 'primeng/api';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { NgxUiLoaderManualService } from '@app/shared/service/ngx-ui-loader.service';
import FileSaver from "file-saver";

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.sass'],
  providers: [ConfirmationService],
})
export class FileUploadComponent implements OnInit {
  fileFrom: FormGroup;
  fileResponseBody: FileResponseBody;
  responseBody: ResponseBody;
  returnFileResponse: any;
  responsefiles: any;
  childDel: boolean;
  @Input() ViewDetail: Boolean = false;
  @Input() referenceType: string;
  @Input() modelName: string;
  @Input() getFileData: any;
  @Input() recordId: string;
  @Input() accessuri: string;
  @Output() fileUploadEvent = new EventEmitter<any>();
  @Input() childID: string;
  @Input() type: string;
  viewDetail: Boolean;
  displayDeleteDialog: Boolean;
  deleteDetails: { 'index': number; 'fileId': string; docId: string; docRecordId: string; };

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private fb: FormBuilder,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private ngxService: NgxUiLoaderService,
    private loaderService: NgxUiLoaderManualService,
  ) { }

  ngOnInit() {
    // this.createForm();
    // this.spinnerService.show();
    // this.spinnerService.hide();
    // this.loaderService.config.text = 'Please wait';
    this.viewDetail = this.ViewDetail;
    if (this.type === 'child') {
      this.childDel = true;
    } else {
      this.childDel = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.createForm();
    const name: SimpleChange = changes.getFileData;
    if (name != null && name !== undefined) {
      this.getFileData = name.currentValue;
      this.setStabilityFormArray(this.getFileData, this.modelName);
    }

    if (this.accessuri == "") {
      this.dataService.getSingle<any>("companysettings", this.configuration.settingsApi)
        .subscribe(data => {
          this.accessuri = data.accessuri
        })
    }
  }


  createForm() {
    this.fileFrom = this.fb.group({
      Doc_Data: this.fb.array([])
    });
  }

  setStabilityFormArray(fileToSet: any[], fieldName: string) {
    if (fileToSet != null) {
      if (fileToSet.length > 0) {
        const splitfilesfilter = fileToSet.filter(
          file => file.Doc_field_type === fieldName);
        if (splitfilesfilter.length > 0) {
          const splitfiles = splitfilesfilter.map(file => file.Doc_list);
          const splitfileFormArray = this.fb.array(splitfiles[0]);
          this.fileFrom.setControl('Doc_Data', splitfileFormArray);

        }
      }
    }

  }
  onUpload(files: FileList) {
    var id = this.recordId;
    // this.loaderService.config.text = 'Uploading file please wait ';    
    this.ngxService.start();
    let formData: FormData = new FormData();
    for (var i = 0; i < files.length; i++) {
      formData.append("fileDoc", files[i]);
      if (files[i].type == 'video/mp4' || files[i].type == 'video/x-matroska' || files[i].type == 'video/ogg' || files[i].type == 'video/webm' || files[i].type == 'video/3gpp') {
        this.ngxService.stopAll();
        this.toastr.warning('Video Format File Does Not Support');
        return false;
      }
    }
    formData.append("referenceType", this.referenceType);

    this.dataService
      .addFile<FileResponse>(this.configuration.fileUploadServer, formData)
      .subscribe((data: FileResponse) => this.fileResponseBody = data.message,
        error => () => {
          this.ngxService.stopAll();
          // this.loaderService.config.text = 'Please wait';
        },
        () => {

          this.ngxService.stopAll();
          // this.loaderService.config.text = 'Please wait';
          if (this.fileResponseBody.status === true) {
            this.accessuri = this.fileResponseBody.accessUri;
            this.returnFileResponse = { $modelName: this.modelName, $result: this.fileResponseBody.result, $isDelete: false };
            const steps = <FormArray>this.fileFrom.controls['Doc_Data'];
            if (this.fileResponseBody.result.length > 0) {

              this.responsefiles = this.fileResponseBody.result;
            }
            for (var i = 0; i < this.responsefiles.length; i++) {
              steps.push(this.fb.group(this.responsefiles[i]));
            }
            this.fileUploadEvent.emit(this.returnFileResponse);
          } else {

          }
        });
  }

  downloadPdf(url: any, fileName: string) {
    FileSaver.saveAs(url, fileName);
  }


  // File delete process
  deleteDocument(index: number, fileId: string, docId: string, docRecordId: string) {
    this.displayDeleteDialog = true;
    this.deleteDetails = { 'index': index, 'fileId': fileId, 'docId': docId, 'docRecordId': docRecordId };
    // this.deleteDetails.fileId = fileId;
    // this.deleteDetails.docId = docId;
    // this.deleteDetails.docRecordId = docRecordId;

    //     this.confirmationService.confirm({
    //     message: 'Are you sure want to Delete?',
    //     header: 'Delete Confirmation',
    //     icon: 'fa fa-question-circle',
    //     accept: () => {
    //     this.spinnerService.show();
    //      this.dataService.deleteFile<Response>(this.configuration.fileUploadServer + fileId + '/' + docId + '/' + docRecordId)
    //     .subscribe((data: Response) => this.responseBody = data.message,
    //       error => () => {
    //         this.spinnerService.hide();
    //       },
    //       () => {
    //         if (this.responseBody.status === true) {
    //           this.returnFileResponse = { $modelName: this.modelName, $index: index, $isDelete: true };
    //           const steps = <FormArray>this.fileFrom.controls['Doc_Data'];
    //           steps.removeAt(index);
    //           this.fileUploadEvent.emit(this.returnFileResponse);
    //         }
    //         this.spinnerService.hide();
    //       });
    //   },
    //   reject: () => { }
    // });

  }

  deleteConfirmation() {
    let deleteDetails = this.deleteDetails;
    // this.loaderService.config.text = 'Deleting file please wait';    
    this.ngxService.start();
    this.dataService.deleteFile<Response>(this.configuration.fileUploadServer + deleteDetails.fileId + '/' + deleteDetails.docId + '/' + deleteDetails.docRecordId)
      .subscribe((data: Response) => this.responseBody = data.message,
        error => () => {
          this.ngxService.stopAll();
          // this.loaderService.config.text = 'Please wait';
        },
        () => {
          if (this.responseBody.status === true) {
            this.returnFileResponse = { $modelName: this.modelName, $index: deleteDetails.index, $isDelete: true };
            const steps = <FormArray>this.fileFrom.controls['Doc_Data'];
            steps.removeAt(deleteDetails.index);
            this.fileUploadEvent.emit(this.returnFileResponse);
          }
          this.ngxService.stopAll();
          // this.loaderService.config.text = 'Please wait';
        });
    this.displayDeleteDialog = false;
  }

  /**DELETE CHILD FILE */
  deleteChildDocument(index: number, fileId: string, docId: string, docRecordId: string, childId: string) {
    this.confirmationService.confirm({
      message: 'Are you sure want to Delete?',
      header: 'Delete Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        // this.loaderService.config.text = 'Deleting file please wait';        
        this.ngxService.start();
        this.dataService.deleteFile<Response>(this.configuration.fileUploadServer + fileId + '/' + docId + '/' + docRecordId + '/' + childId)
          .subscribe((data: Response) => this.responseBody = data.message,
            error => () => {
              this.ngxService.stopAll();
              // this.loaderService.config.text = 'Please wait';
            },
            () => {
              if (this.responseBody.status === true) {
                this.returnFileResponse = { $modelName: this.modelName, $index: index, $isDelete: true };

                const steps = <FormArray>this.fileFrom.controls['Doc_Data'];
                steps.removeAt(index);
                this.fileUploadEvent.emit(this.returnFileResponse);
              }
              this.ngxService.stopAll();
              // this.loaderService.config.text = 'Please Wait';
            });
      },
      reject: () => { }
    });
  }

}
