import { Component, OnInit, Input, Output, EventEmitter, Pipe } from '@angular/core';
import { SelectItem } from 'primeng/primeng';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { DataService } from '../../service/data-service';
import { Configuration } from '../../../app.configuration';
import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Response, ResponseBody } from '../../model/service-response.interface';
import { KindBaseModel } from '../../model/kind-data-model';
import { FilePatchComponent } from '../file-upload/file-upload.patch.component';
import { CustomRoutingName } from '../../../app.custom-routing-name';
import { BatchDetailResponse, MasterFormulationRecord, Operator } from '../../../module/production/model/manage-batch-record.interface';
import { OptionDataResponseList } from '../../../module/production/model/batch-option.interface';
import { ToastrService } from 'ngx-toastr';
import { ToastBaseModel } from '../../model/toast-data-model';
import { AuditFiles } from '../audit-File';
import { StorageConfiguration } from '../storage-setting/storage-configuration';
import { ValidationCheck } from '../common-validation';
import { NameCapitalize, yearCalculate, sortBatchOperator } from '../common-helper';
// import { element } from '@angular/core/src/render3';
import { ReleaseCriteria } from '@app/module/production/model/master-formulation-record.interface';
import { GenerateId } from '../common-helper';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import * as moment from 'moment';

// tslint:disable-next-line:use-pipe-transform-interface
@Pipe({ name: 'safe' })


@Component({
  selector: 'app-batch-create',
  templateUrl: './batch-create.component.html',
  styleUrls: ['./batch-create.component.sass'],
  providers: [ConfirmationService, sortBatchOperator, NameCapitalize]

})
export class BatchCreateComponent implements OnInit {

  // FormGroups
  BatchDetailForm: FormGroup;
  Master_formulation_record: any;
  OperatorForm: FormGroup;
  OperatorVerifiedDetails: FormGroup;
  MasterPostForm: FormGroup;
  Case_details: FormGroup;
  CaseUnit: FormGroup;
  newRecord: string;
  // Response Variables
  BatchDetail: BatchDetailResponse;
  BatchOptionData: OptionDataResponseList;
  BatchOptionData2: any;
  Master_formulation_record_model: MasterFormulationRecord;
  OperatorModel: Operator;
  responseBody: ResponseBody;
  pdfresponseBody: any;
  display_GenerateLabel: boolean;
  // Select Items Variable
  FacilitySelectItem: SelectItem[];
  LocationSelectItem: SelectItem[];
  MasterFormulation_SelectItem: SelectItem[];
  Product_SelectItem: SelectItem[];
  EquipmentSelectItem = [];
  batchOperatorArray = [];
  // Popup Variables
  display_FinalProductPopup = false;
  display_MasterRecordAddPopup = false;
  display_LabelAddPopup = false;
  display_EquipmentAddPopup = false;
  display_OperatorAddPopup = false;


  // Selected List value
  selectedOperatorValue: any[];
  selectedEquipmentValue: any[];
  selectedOperator: any[];


  keyCode: number;
  pdfview: string;
  minDate: Date = new Date();

  // FILE UPLOAD DATAS
  referenceTypeName = 'batch';
  attachment = 'pdf';
  accessuri: string;
  referenceType = 'batch';
  yrRange: any;
  checkTrue = true;
  masterReleaseCriteriaValue = [];
  chemicalReleaseCriteriaValue = [];

  // PASSING INPUT
  @Input() MasterId: any;
  @Input() PsFiledForBatch: any;
  @Input() IsFromPs: boolean;
  @Input() PsQuantity: number;
  @Output() BatchCreateEmitter = new EventEmitter();
  @Input() IsFrom: any;
  @Input() IsMasterDetail: boolean;
  @Input() IsCopyBatch: boolean;
  @Input() copyBatchForm: any;
  @Input() IsMasterBatch: any;
  userInfo: any;
  SelectedMFR: any;
  display_MFRAddPopup: boolean;
  display_MasterBatch: boolean;
  MasterBatchForm: FormGroup;
  commentForm: FormGroup;
  cols: any[];
  masterBatchLabel: any[];
  selectedCars3: any[];
  masterData = [];
  FinalProduct: any;
  ChildBatchs: any;
  display_child_Batch: boolean;
  masterSave: boolean;
  masterMfrresult: any;
  ipAddress: any;
  isDisabled: boolean = false;
  Estimated_realeased_date: any;

  constructor(
    private spinnerService: Ng4LoadingSpinnerService,
    private dataService: DataService,
    private configuration: Configuration,
    private router: Router,
    private fb: FormBuilder,
    private filePatch: FilePatchComponent,
    private kind: KindBaseModel,
    private customRoutingName: CustomRoutingName,
    private toastMessage: ToastBaseModel,
    private toastr: ToastrService,
    private auditFiles: AuditFiles,
    private storageConfiguration: StorageConfiguration,
    private validationCheck: ValidationCheck,
    private nameCapitalize: NameCapitalize,
    private yearRange: yearCalculate,
    private sortBatchOperator: sortBatchOperator,
    private generateId: GenerateId,
    private ngxService: NgxUiLoaderService


  ) {

    this.masterBatchLabel = [
      { field: 'Lot_number', header: 'LOT NUMBER' },
      { field: 'Quantity', header: 'QUANTITY' },
      { field: 'Batch_date', header: 'BATCH DATE' },
    ];

    // this.masterData = [
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf961", Name: "Master 23 Jan 2019 - 21" }, Quantity: 21 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf962", Name: "Master 23 Jan 2019 - 22" }, Quantity: 24 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf963", Name: "Master 23 Jan 2019 - 23" }, Quantity: 26 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf964", Name: "Master 23 Jan 2019 - 24" }, Quantity: 28 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf965", Name: "Master 23 Jan 2019 - 25" }, Quantity: 45 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf966", Name: "Master 23 Jan 2019 - 26" }, Quantity: 65 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf967", Name: "Master 23 Jan 2019 - 27" }, Quantity: 35 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf968", Name: "Master 23 Jan 2019 - 28" }, Quantity: 85 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf969", Name: "Master 23 Jan 2019 - 29" }, Quantity: 15 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf970", Name: "Master 23 Jan 2019 - 30" }, Quantity: 5 },
    //   { Master_formulation_record: { id: "8dd9a01b-7ba0-4ee1-b839-37ddfe6abf971", Name: "Master 23 Jan 2019 - 31" }, Quantity: 55 }
    // ]
  }
  /*
  ** ==========================================================
  **  ngOnInit Method
  ** ==========================================================
  */
  ngOnInit(): void {
    this.spinnerService.hide();
    this.yrRange = this.yearRange.yrRange;
    this.userInfo = JSON.parse(this.storageConfiguration.sessionGetItem(this.storageConfiguration.user_Info));
    this.CreateForm();
    if (this.IsMasterBatch) {
      this.CreateMasterBatch_Add_Dialog();
      const publicIp = require('public-ip');

      publicIp.v4().then(ip => {
        this.ipAddress = ip;
      });
    } else {
      this.CreateBatch_Add_Dialog();
    }



  }
  /*
  ** ==========================================================
  **  CREATING FORM Method
  ** ==========================================================
  */
  CreateForm() {
    this.Master_formulation_record = this.fb.group({
      id: ['', Validators.required],
      Sku: [''],
      Name: [''],
      Strength: this.fb.group({
        Value: [''],
        Unit: ['']
      }),
      Bud: this.fb.group({
        Days: [0],
        Hours: [0],
      }),
      Size_dispensed: this.fb.group({
        Units: [0, Validators.compose([Validators.required, Validators.minLength(1)])],
        Type: [''],
      }),      
      Container_type: this.fb.group({
        Container: [''],
        Label_size: ['']
      }),
      Ndc: [''],
      Storage_condition: [''],
      Estimated_realeased_date: [''],
      Time_required: [''],
      No_of_operators: [],
      Price_per_unit: [],
      Cost_per_unit: [],
      Chemical_inventory: [],
      Component_inventory: [],
      Apseptic_method: this.fb.group({
        id: [''],
        Name: [''],
        High_level_process: [],
      }),
      Location: this.fb.group({
        id: [''],
        Name: [''],
      }),
      Release_criteria: [],
      Notes: [],
      Equipment_classification: [],
      Handling_instructions: [],
      Days_quarantine: [],
      Quantity_per_case: [0],
      Total_time: this.fb.group({
        Tech_time: [0],
        Tech_cost: [0],
        Rph_time: [0],
        Rph_cost: [0],
        Inventory_cost: [0],
      }),
      Total_cost: [0],
      Quantity_to_make: [0],
      Finalized_detail: this.fb.group({}),
      Status: [''],
      Compounding_methodology_classification: [''],
      Risk_type: [''],
      Production_type: [''],
      Product: this.fb.group({
        id: [''],
        Name: [''],
      }),
      Is_cms: false,
      Training_detail: this.fb.group({
        id: [''],
        Name: [''],
      }),
      // Quantity_dispensed_per_container: this.fb.group({
      //   Units: [0, Validators.compose([Validators.required, Validators.minLength(1)])],
      //   Type: ['']
      // }),
      Projected_loss: this.fb.group({
        Units: [0, Validators.compose([Validators.required])],
        Type: ['']
      }),
      Theoretical_yield: this.fb.group({
        Units: [0, Validators.compose([Validators.required, Validators.minLength(1)])],
        Type: ['']
      }),
      Units_container: [0],
      Sample_Units: [0],
      Sample_labels: [0]
    });

    this.BatchDetailForm = this.fb.group({
      Requestor: this.fb.group({
        id: this.userInfo[0].id,
        Name: this.userInfo[0].name,
      }),
      Starter_info: this.fb.group({
        id: [''],
        Name: [''],
      }),
      Lot_number: [''],
      Estimated_realeased_date: [''],
      Bar_code: [''],
      Batch_name: [''],
      Batch_date: ['', Validators.required],
      Expiry_date: ['', Validators.required],
      Potency: [0],
      Time_required: [0],
      Total_time_elapsed: [0],
      Reason: [''],
      Facility: this.fb.group({
        id: [''],
        Name: ['']
      }),
      Label_details: this.fb.group({
        Labels_size: [''],
        Labels_generated: [''],
        Labels_destory: [''],
        Label_data: [],
      }),
      Quantity: [0, Validators.min(1)],
      Master_formulation_record: this.Master_formulation_record,
      Location: [],
      Equipment: [],
      Operator: [],
      Activity_log: this.fb.array([]),
      File_data: [],
      Notes: [''],
      Batch_comments: [],
      // Batch_case: this.fb.group({
      //   Is_completed: false,
      //   Is_moved: false,
      //   Tab_index: 0,
      //   Case_details: []
      // }),
      id: [''],
      Kind: this.kind.batch_record,
      Created_info: this.fb.group({
        Name: this.userInfo[0].name,
        By: this.userInfo[0].id,
        Date: new Date().toISOString()
      }),
      Updated_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Deleted_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Is_active: true,
      Is_deleted: false,
      Is_em_required: false,
      Is_pm_required: false,
      Batch_order: [],
      Is_batchfrom_ps: false,
      Projected_loss: this.fb.group({
        Units: [0, Validators.compose([Validators.required])],
        Type: ['']
      }),
      Theoretical_yield: this.fb.group({
        Units: [0, Validators.compose([Validators.required, Validators.minLength(1)])],
        Type: ['']
      }),
      Percentage_yield: this.fb.group({
        Units: [0],
        Type: ['%']
      }),
      Order_quantity: [0],
      Units_container: [0],
      Sample_Units: [0],
      Sample_labels: [0],
      Is_master_batch: false,
      Child_batches: [],
      Is_custom_label: true
    });


    this.OperatorForm = this.fb.group({
      id: [''],
      Name: [''],
      Status: [''],
      OperatorVerifiedDetails: [],
      Is_email: false

    });

    this.OperatorVerifiedDetails = this.fb.group({
      Verified_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Rejected_info: this.fb.group({
        Name: [''],
        By: [''],
        Date: ['']
      }),
      Comments: [''],
      Is_active: false,
      Start_time: [''],
      End_time: [''],
    });

    this.MasterPostForm = this.fb.group({
      Type: [''],
      Master_record_id: [''],
      Batch_date: [''],
      Location_id: [''],
      Equipment_classification: [],
      Is_cms: false
    });

    this.Case_details = this.fb.group({
      Case_no: ['', Validators.required],
      Used_quantity: 0,
      Total_quantity: 0,
      Available_quantity: 0,
      Units: this.CaseUnit,
    });

    this.CaseUnit = this.fb.group({
      id: [''],
      Barcode: ['', Validators.required],
      Is_used: false,
      Status: ''
    });

    this.MasterBatchForm = this.fb.group({
      finalProduct: ['', Validators.required],
      quantity: [0, Validators.compose([Validators.required, Validators.min(1)])],
      selectedQuantity: [0, Validators.compose([Validators.required, Validators.min(1)])]
    });

    this.commentForm = this.fb.group({
      id: [''],
      Comment_type: [''],
      Comments: ['', Validators.required],
      Comments_info: this.fb.group({
        ip_address: [''],
        Name: [''],
        By: [''],
        Date: [''],
      }),
      BatchStep: ['']
    })

  }
  /*
  ** ==========================================================
  **  create batch  Method
  ** ==========================================================
  */
  CreateBatch_Add_Dialog() {
    this.spinnerService.show();
    this.dataService.getSingle<BatchDetailResponse>('NewRecord', this.configuration.batchRecord + '/').subscribe(
      (data: BatchDetailResponse) => (this.BatchDetail = data),
      error => () => {
        this.spinnerService.hide();
      },
      () => {

        if (this.IsFrom === 'Batch') {
          this.spinnerService.hide();
          this.display_MasterRecordAddPopup = true;
        }
        const date = new Date();
        this.bindsupportingOptions(this.BatchDetail);
        this.BatchDetailForm.patchValue({
          Batch_date: date
        });
        if (this.IsCopyBatch) {
          this.MasterFormulationOnchange(this.copyBatchForm.Master_formulation_record_Id);
        }
        if (this.BatchDetail.options.Environmental_monitoring_frequency === 'With-every-batch') {
          this.BatchDetailForm.patchValue({
            Is_em_required: true
          });
        }
        if (this.BatchDetail.options.Personnel_monitoring_frequency === 'With-every-batch') {
          this.BatchDetailForm.patchValue({
            Is_pm_required: true
          });
        }
        this.expireDateUpdate(date, 'cda');
        if (this.IsFrom === 'Ps') {
          this.BatchDetailForm.patchValue({
            Batch_order: this.PsFiledForBatch,
            Is_batchfrom_ps: true
          });
          const MasterFormulationName = this.MasterFormulation_SelectItem.filter(x => x.value === this.MasterId);
          if (MasterFormulationName.length > 0) {
            this.MasterFormulationOnchange(this.MasterId);
            this.display_MasterRecordAddPopup = true;
          } else {
            this.ngxService.stopAll();
            this.toastr.warning('This Master Formulation is In-Active State.');
          }
        }
        if (this.IsFrom === 'MasterFormulation') {
          this.BatchDetailForm.patchValue({
            // Batch_order: this.PsFiledForBatch,
            Is_batchfrom_ps: this.IsFromPs
          });
          this.MasterFormulationOnchange(this.MasterId);
          this.display_MasterRecordAddPopup = true;

        }
      }
    );
  }
  /*
  ** ==========================================================
  **  Binding the supporting Options  Method
  ** ==========================================================
  */
  bindsupportingOptions(response: any) {
    // Dependent Drop Down Values
    this.MasterFormulation_SelectItem = this.bindDropDown(
      response.options.Master_formula,
      'default'
    );
    this.BatchDetailForm.patchValue({
      Requestor: {
        Name: this.userInfo[0].name,
      },
      Lot_number: response.options.Lot_number
    });
  }

  // Binding the Drop Down  Value
  bindDropDown(array: any[], ddType: string): any[] {
    const dropDownItem = [];
    array.forEach(a => {
      switch (ddType) {
        case 'default':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;
        case 'multiselect':
          dropDownItem.push({ label: a.Name, value: { id: a.id, Name: a.Name } });
          break;
        case 'location':
          dropDownItem.push({ label: a.Name, value: { id: a.id, Name: a.Name, Checked_info: { Name: '', By: '', Date: '' }, Status: a.Status, Barcode: a.Barcode, Is_verified: false, Scan_type: false } });
          break;
        case 'storage':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'quantity':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'container':
          dropDownItem.push({ label: a.Value, value: a.Value });
          break;
        case 'release':
          dropDownItem.push({ Name: a.Value, Checked: false });
          break;
        case 'equipment':
          dropDownItem.push({ Name: a.Value, Checked: false });
          break;
        case 'finalProduct':
          dropDownItem.push({ label: a.Name, value: a.id });
          break;

      }
    });
    return dropDownItem;
  }

  /*
  ** ==========================================================
  **  Batch Expire date Updater  Method
  ** ==========================================================
  */
  expireDateUpdate(event, type) {
    const date = new Date(event);
    let Batch_date;
    if (type == 'ms') {
      Batch_date = new Date(this.BatchDetailForm.get('Batch_date').value);
    } else {
      Batch_date = date;
    }
    const Bud = this.BatchDetailForm.get('Master_formulation_record.Bud').value;
    Batch_date.setDate(Batch_date.getDate() + Bud.Days); // number  of days to add, e.x. 15 days
    Batch_date.setHours(Batch_date.getHours() + Bud.Hours); // number  of days to add, e.x. 15 days
    const Expiry_date = new Date(Batch_date);
    this.BatchDetailForm.patchValue({
      Expiry_date: Expiry_date
    });
  }
  /*
   ** ==========================================================
   **  MasterFormulation Change   Method
   ** ==========================================================
   */
  MasterFormulationOnchange(event) {
    this.selectedEquipmentValue = [];
    this.selectedOperator = [];
    this.spinnerService.show();
    let MasterFormulationId;
    if (this.IsFrom !== 'Batch') {
      MasterFormulationId = this.MasterId;
    } else {
      if (event.value) {
        MasterFormulationId = event.value;
      } else {
        MasterFormulationId = event;
      }

    }
    const MasterFormulationName = this.MasterFormulation_SelectItem.filter(x => x.value === MasterFormulationId);
    this.SelectedMFR = MasterFormulationName[0];
    this.MasterPostForm.patchValue({
      Type: 'Master',
      Master_record_id: MasterFormulationId,
      Equipment_classification: [],
      Batch_date: this.BatchDetailForm.get('Batch_date').value,
      Is_cms: false

    });
    this.BatchDetailForm.patchValue({
      // Batch_name: MasterFormulationName[0].label,
      Master_formulation_record: {
        id: MasterFormulationId,
        Name: MasterFormulationName[0].label,
        Estimated_realeased_date: "new Date()"

      }
    });
    this.dataService.add<OptionDataResponseList>(this.configuration.batchRecord + '/GetMasterRecord',
      this.MasterPostForm.value).subscribe(
        (data: OptionDataResponseList) => (this.BatchOptionData = data),
        error => () => {
          this.spinnerService.hide();
        },
        () => {
          this.batchPatchValue(this.BatchOptionData);
          this.FacilityDropdown(this.BatchOptionData.options.Location);
          this.expireDateUpdate(event, 'ms');

          this.batchOperatorArray = this.sortBatchOperator.sorting(this.BatchOptionData.options.Operator);
          this.display_MFRAddPopup = false;
          // this.spinnerService.hide();
          this.ngxService.stopAll();
          if (this.IsCopyBatch) {
            this.batchCopyPatchvalue();
          }
          if (this.BatchOptionData.options.Location.length == 0) {
            this.toastr.warning('Location(s) are in Out of Specification/Compliance because of Certification Due Date is reached!! Kindly Check Location(s).');
          }
        }
      );
  }


  // sorting(arrayValue) {
  //      
  //   arrayValue.sort(function (a, b) {
  //       var valueA = a.Name.toLowerCase(), valueB = b.Name.toLowerCase()
  //       if (valueA < valueB) //sort string ascending
  //           return -1
  //       if (valueA > valueB)
  //           return 1
  //       return 0 //default return value (no sorting)
  //   })
  //   return arrayValue;
  // }
  /*
   ** ==========================================================
   **  PATCHING THE BATCH VALUE TO THE FORM  Method
   ** ==========================================================
   */
  batchPatchValue(dataValue) {
    // this.Estimated_released_date = new Date (dataValue.result.Estimated_released_date)
    // console.log("Estimated_released_dateEstimated_released_date", this.Estimated_released_date);

    this.BatchDetail = dataValue.result;
    dataValue.result = this.BatchDetail;
    const data = dataValue.result;
    const array = data['Apseptic_method']['High_level_process'];
    array.forEach(function (item) {
      item.Verified_info = { Name: '', By: '', Date: '' };
      item.Checked_info = { Name: '', By: '', Date: '' };
    });
    data['Apseptic_method']['High_level_process'] = array;
    // if (this.IsFrom === 'Ps') {
    //   data['Quantity_to_make'] = this.PsQuantity;
    // }
    this.Master_formulation_record = data;
    this.Master_formulation_record_model = data;
    this.BatchDetailForm.patchValue({
      Location: [],
      Equipment: [],
      Operator: [],
      Batch_comments: [],
      Batch_case: {
        Case_details: [],
      },
      Estimated_realeased_date: dataValue.result.Estimated_released_date,
      Quantity: this.Master_formulation_record_model.Quantity_to_make,
      Master_formulation_record: this.Master_formulation_record_model,
      Projected_loss: this.Master_formulation_record_model.Projected_loss,
      //Theoretical_yield: this.Master_formulation_record_model.Theoretical_yield,
      Order_quantity: this.IsFrom === 'Ps' ? this.PsQuantity : 0,
      Units_container: this.Master_formulation_record_model.Units_container,
      Sample_Units: this.Master_formulation_record_model.Sample_Units,
      Sample_labels: this.Master_formulation_record_model.Sample_labels,
     
      Theoretical_yield: this.Master_formulation_record_model.Quantity_to_make - this.Master_formulation_record.Projected_loss,
      Batch_order: []
    });
    let cmClassification = this.Master_formulation_record_model.Compounding_methodology_classification;
    if (cmClassification == 'Sterile Compound') {
      this.BatchDetailForm.patchValue({
        Is_pm_required: true
      });
    }

    if (this.IsMasterBatch) {
      this.BatchDetailForm.patchValue({
        Is_master_batch: true,
        Quantity: this.MasterBatchForm.get('selectedQuantity').value,
        Batch_comments: [this.commentForm.value]
      });

      if (dataValue.batchOption.Environmental_monitoring_frequency === 'With-every-batch') {
        this.BatchDetailForm.patchValue({
          Is_em_required: true
        });
      }
      let cmClassification = this.Master_formulation_record_model.Compounding_methodology_classification;
      if (dataValue.batchOption.Personnel_monitoring_frequency === 'With-every-batch' || cmClassification == 'Sterile Compound') {
        this.BatchDetailForm.patchValue({
          Is_pm_required: true
        });
      }
    }
    this.releaseCriteriaChanges();
  }

  releaseCriteriaChanges() {
    const MFR = this.BatchDetailForm.get('Master_formulation_record').value
    const releaseCriteria = [];

    MFR.Release_criteria.forEach(element => {
      releaseCriteria.push({ Name: element, Is_active: false })
    });

    MFR.Chemical_inventory.forEach(element => {
      const chemicalReleaseCriteria = [];
      if (!element.Release_criteria) {
        element.Release_criteria = [];
      } else {
        element.Release_criteria.forEach(releaseValue => {
          chemicalReleaseCriteria.push({ Name: releaseValue, Is_active: false });
        });
        element.Release_criteria = chemicalReleaseCriteria;
      }
    });

    this.BatchDetailForm.patchValue({
      Master_formulation_record: {
        Release_criteria: releaseCriteria,
        Chemical_inventory: MFR.Chemical_inventory,
        Estimated_realeased_date: moment(MFR.Estimated_released_date).format('MM/DD/yyy')
      }
    });
  }
  /*
   ** ==========================================================
   **  FACILITY DROP DOWN BINDER Method
   ** ==========================================================
   */
  FacilityDropdown(Dropdown) {
    let dropDownItem = [];
    Dropdown.forEach(element => {
      const obj = { label: '', value: '' };
      obj.value = element.Facility_id;
      obj.label = element.Facility_name;
      if (this.FacilitySelectItem != undefined) {
        dropDownItem = this.FacilitySelectItem;
      }
      if (dropDownItem.length > 0) {
        const filterArray = dropDownItem.filter(x => x.value == obj.value);
        if (filterArray.length == 0) {
          dropDownItem.push(obj);
        }
      } else {
        dropDownItem.push(obj);
      }
    });
    this.FacilitySelectItem = dropDownItem;
  }
  /*
  ** ==========================================================
  **  LOCATION BINDER ON FACILITY CHANGE Method
  ** ==========================================================
  */
  FacilityOnChange(event) {
    this.BatchDetailForm.patchValue({
      Location: [],
      Equipment: [],
    });
    this.EquipmentSelectItem = [];
    let val = '';
    if (event.value) {
      val = event.value;
    } else {
      val = event;
    }
    const Dropdown = this.BatchOptionData.options.Location.filter(x => x.Facility_id == val);
    this.BatchDetailForm.patchValue({
      Facility: {
        Name: Dropdown[0].Facility_name
      },
    });
    this.LocationSelectItem = this.bindDropDown(Dropdown, 'location');
  }
  /*
  ** ==========================================================
  **  EQUIPEMNT BINDER ON FACILITY CHANGE Method
  ** ==========================================================
  */
  LocationOnchange() {
    const BatchDetailFormModel = this.BatchDetailForm.value;
    // Clearing the Equipments
    this.BatchDetailForm.patchValue({
      Equipment: [],
    });
    this.EquipmentSelectItem = [];
    this.selectedEquipmentValue = [];
    this.spinnerService.show();
    const LocationArray = [];
    if (BatchDetailFormModel.Location.length > 0) {
      BatchDetailFormModel.Location.forEach(element => {
        LocationArray.push(element.id);
      });
      this.MasterPostForm.patchValue({
        Type: 'Equipment',
        Location_id: LocationArray,
        Batch_date: BatchDetailFormModel.Batch_date,
        Equipment_classification: this.BatchOptionData.result.Equipment_classification,
      });

      this.dataService.add<any>(this.configuration.batchRecord + '/GetMasterRecord', this.MasterPostForm.value).subscribe(
        (data: any) => (this.BatchOptionData2 = data),
        error => () => {
          this.spinnerService.hide();
        },
        () => {
          this.EquipmentSelectItem = this.BatchOptionData2.options;
          this.spinnerService.hide();

        }
      );
    } else {
      this.spinnerService.hide();
    }
  }
  /*
  ** ==========================================================
  **  ADD MASTER FORMULATION METHOD
  ** ==========================================================
  */
  CloseDialog() {
    this.display_MasterBatch = false;
    this.display_MasterRecordAddPopup = false;
    this.BatchCreateEmitter.emit(false);
    if (!this.display_LabelAddPopup) {
      this.BatchDetailForm.reset();
    }
    if (this.IsFrom === 'Ps') {

      this.router.navigate([this.customRoutingName.production_scheduler]);
    }
    if (this.IsFrom === 'MasterFormulation' && !this.IsMasterDetail) {
      this.router.navigate([this.customRoutingName.master_formulation_list]);
    }
    if (this.IsFrom === 'MasterFormulation' && this.IsMasterDetail) {
      this.router.navigate([this.customRoutingName.master_formulation_detail + this.MasterId]);
    }
    this.selectedEquipmentValue = [];
    this.selectedOperator = [];
  }
  /*
  ** ==========================================================
  **  ADD LABEL DIALOG METHOD
  ** ==========================================================
  */

  showDialog_Label() {
    let validatorArrays;
    const batchValidation = this.validationCheck.validationChecker(this.Master_formulation_record);
    validatorArrays = this.validationCheck.getValidation(batchValidation);
    if (batchValidation.length === 0) {
      this.display_MasterRecordAddPopup = false;
      this.display_LabelAddPopup = true;
      const batchDetailForm = this.BatchDetailForm.value;
      this.BatchDetailForm.patchValue({
        Label_details: {
          Labels_generated: batchDetailForm.Master_formulation_record.Quantity_to_make + 3
        }
      });
    } else {
      this.toastr.warning(validatorArrays);
    }
  }
  /*
  ** ==========================================================
  **  ADD EQUIPMENT DIALOG METHOD
  ** ==========================================================
  */
  showDialog_Equipment(type) {
    this.display_EquipmentAddPopup = true;
  }
  /*
   ** ==========================================================
   **  SAVE EQUIPMENT DIALOG METHOD
   ** ==========================================================
   */
  SaveDialog_Equipment() {

    this.display_EquipmentAddPopup = false;
    const pushingEquipment = [];
    for (let i = 0; i < this.selectedEquipmentValue.length; i++) {
      const obj = {};
      obj['id'] = this.selectedEquipmentValue[i].id;
      obj['Name'] = this.selectedEquipmentValue[i].Name;
      obj['Location_id'] = this.selectedEquipmentValue[i].Location_id;
      obj['Status'] = this.selectedEquipmentValue[i].Status;
      obj['Barcode'] = this.selectedEquipmentValue[i].Barcode;
      obj['Checked_info'] = this.selectedEquipmentValue[i].Checked_info;
      obj['Is_verified'] = this.selectedEquipmentValue[i].Is_verified;

      pushingEquipment.push(obj);
    }
    this.BatchDetailForm.patchValue({
      Equipment: pushingEquipment
    });
  }
  /*
 ** ==========================================================
 **  CANCEL EQUIPMENT DIALOG METHOD
 ** ==========================================================
 */
  CancelDialog_Equipment() {
    this.selectedEquipmentValue = [];
    this.display_EquipmentAddPopup = false;
  }

  /*
  ** ==========================================================
  **   ADD OPERATOR DIALOG
  ** ==========================================================
  */
  showDialog_Operator(type) {
    this.display_OperatorAddPopup = true;
  }

  /*
  ** ==========================================================
  **   SAVE OPERATOR DIALOG
  ** ==========================================================
  */
  SaveDialog_Operator() {
    const No_of_operators = this.BatchDetailForm.get('Master_formulation_record.No_of_operators').value;
    const pushingOperator = [];
    if (this.selectedOperatorValue != null && this.selectedOperatorValue.length != 0) {
      for (let i = 0; i < this.selectedOperatorValue.length; i++) {
        this.OperatorForm.reset();
        const OperatorVerifiedDetails = [];
        const obj = {} = this.OperatorVerifiedDetails.value;
        OperatorVerifiedDetails.push(obj);
        this.OperatorForm.patchValue({
          Name: this.selectedOperatorValue[i].Name,
          id: this.selectedOperatorValue[i].id,
          Status: 'InActive',
          OperatorVerifiedDetails: OperatorVerifiedDetails,
          Is_email: false
        });
        this.OperatorModel = this.OperatorForm.value;
        pushingOperator.push(this.OperatorModel);
      }
      this.display_OperatorAddPopup = false;
      this.BatchDetailForm.patchValue({
        Operator: pushingOperator
      });
      this.selectedOperator = this.selectedOperatorValue;
    } else {
      this.toastr.warning('Please select the operator');
    }
  }

  /*
  ** ==========================================================
  **    HIDE OPERATOR DIALOG
  ** ==========================================================
  */
  hideDialog_OperatorAdd() {
    this.selectedOperatorValue = this.BatchDetailForm.get('Operator').value;
    this.display_OperatorAddPopup = false;
  }

  /*
  ** ==========================================================
  **    UPLOAD RECEIVING
  ** ==========================================================
  */
  receiveFileUploadResponse($event) {
    const getfileresult = this.filePatch.genericFilePatch($event, this.BatchDetailForm);
    this.BatchDetailForm.patchValue({ File_data: getfileresult });

  }

  /*
  ** ==========================================================
  **     BATCH SAVE FORM
  ** ==========================================================
  */
  onSubmit(BatchDetailForm) {
    this.isDisabled = true;
    let validatorArrays;
    const batchValidation = this.validationCheck.validationChecker(this.Master_formulation_record);
    validatorArrays = this.validationCheck.getValidation(batchValidation);
    if (batchValidation.length === 0) {

      if (this.keyCode != 13) {
        this.invalidFinder();
        const addBatchDetailFormValue = BatchDetailForm.value;
        if (addBatchDetailFormValue.Quantity <= addBatchDetailFormValue.Projected_loss.Units) {
          this.toastr.warning('Actual Yield(' + addBatchDetailFormValue.Quantity + ') must be greater than Projected loss(' + addBatchDetailFormValue.Projected_loss.Units + ')');
          return false;
        }
        if (addBatchDetailFormValue.File_data == null) {
          addBatchDetailFormValue.File_data = [];
        }
        this.spinnerService.show();
        addBatchDetailFormValue.Time_required = addBatchDetailFormValue.Master_formulation_record.Time_required;
        // addBatchDetailFormValue.Theoretical_yield.Units = addBatchDetailFormValue.Quantity - addBatchDetailFormValue.Master_formulation_record.Projected_loss.Units;
        
        // addBatchDetailFormValue.Theoretical_yield = {
        //   Units: addBatchDetailFormValue.Quantity - addBatchDetailFormValue.Master_formulation_record.Projected_loss.Units,
        //   Type: addBatchDetailFormValue.Master_formulation_record.Projected_loss.Type
        // }
        // addBatchDetailFormValue.Units_container = Math.round(addBatchDetailFormValue.Theoretical_yield.Units / addBatchDetailFormValue.Master_formulation_record.Size_dispensed.Units);
         
         addBatchDetailFormValue.Theoretical_yield = {
           Units: addBatchDetailFormValue.Master_formulation_record.Theoretical_yield.Units,
           Type: addBatchDetailFormValue.Master_formulation_record.Theoretical_yield.Type
         }
         addBatchDetailFormValue.Units_container = Math.round(addBatchDetailFormValue.Master_formulation_record.Units_container);


        addBatchDetailFormValue.Expiry_date = new Date(addBatchDetailFormValue.Expiry_date).toISOString();
        addBatchDetailFormValue.Batch_date = new Date(addBatchDetailFormValue.Batch_date).toISOString();
        let activeModel;
        activeModel = this.auditFiles.newCreateActivity('Batch Name', addBatchDetailFormValue.Batch_name);
        addBatchDetailFormValue.Activity_log = activeModel;
        if (!addBatchDetailFormValue.Is_master_batch) {
          addBatchDetailFormValue.Child_batches = [];
        }
        this.dataService.add<Response>(this.configuration.batchRecord, addBatchDetailFormValue).subscribe(
          (data: Response) => (this.responseBody = data.message),
          error => () => {
            this.spinnerService.hide();
            this.toastr.warning(this.toastMessage.Wrong);
          },
          () => {

            if (!this.display_LabelAddPopup) {
              if (this.responseBody.result === 'Quantity Updation failed') {
                this.toastr.warning(this.responseBody.result);
              } else if (!this.responseBody.status) {
                this.toastr.warning(this.responseBody.result);
              } else {
                this.display_MasterRecordAddPopup = false;

                this.router.navigate([this.customRoutingName.batch_detail + this.responseBody.id]);
                this.toastr.success(this.toastMessage.Batch + this.toastMessage.Create);
              }
            } else {
              this.toastr.success(this.toastMessage.Batch + ' & ' + this.toastMessage.Label + this.toastMessage.Create);
            }
            this.spinnerService.hide();
          }
        );
      }
    } else {
      this.toastr.warning(validatorArrays);

    }
  }



  /*
  ** ==========================================================
  **     GENERATE LABLE METHOD
  ** ==========================================================
  */
  onSubmitLabel(BatchDetailForm) {

    this.spinnerService.show();

    const addBatchDetailFormValue = this.BatchDetailForm.value;
    if (addBatchDetailFormValue.Quantity != '') {
      this.dataService.add<any>(this.configuration.batchRecord + '/GenerateLabel', addBatchDetailFormValue).subscribe(
        (data: any) => (this.pdfresponseBody = data),
        error => () => {
          this.spinnerService.hide();
          this.toastr.warning(this.toastMessage.Wrong);
        },
        () => {
          this.pdfview = this.pdfresponseBody.result;
          this.BatchDetailForm.patchValue({
            Label_details: {
              Label_data: this.pdfresponseBody.result
            }
          });
          this.onSubmit(BatchDetailForm);
          this.display_LabelAddPopup = false;
          // this.router.navigate([this.customRoutingName.batch_detail + this.responseBody.id]);
          this.spinnerService.hide();
        }
      );
    } else {
      this.spinnerService.hide();
      this.toastr.warning('Actual Yield is Empty');
    }

  }
  /*
  ** ==========================================================
  **      AVOID FORM SUBMIT ON ENTER
  ** ==========================================================
  */
  keyDownFunction(event) {
    if (event.keyCode == 13) {
      this.keyCode = 13;
    }
  }

  /*
  ** ==========================================================
  **      DISABLE HIDER IN POPUP
  ** ==========================================================
  */
  disabledCheckHide(event) {

    return event.Is_disabled ? 'disabledCheckHide' : '';
  }

  /*
  ** ==========================================================
  **      INVALID FORM FIELD FINDER IN POPUP
  ** ==========================================================
  */
  invalidFinder() {

    const invalid = [];
    const controls = this.BatchDetailForm.controls;
    for (const name in controls) {
      if (controls[name].invalid) {
        invalid.push(name);
      }
    }
  }

  nameCapitalChange(event) {
    let name = this.nameCapitalize.nameCapitalChange(event);
    this.BatchDetailForm.patchValue({
      Batch_name: name
    });

  }
  cancelGenerate() {
    this.display_LabelAddPopup = false;
    this.display_MasterRecordAddPopup = true;

  }

  batchCopyPatchvalue() {

    let data = this.copyBatchForm;
    this.FacilityOnChange(data.Facility.id);
    this.BatchDetailForm.patchValue({
      Facility: {
        id: data.Facility.id,
        Name: data.Facility.Name
      },
      Location: data.Location,
      Equipment: data.Equipment,
      Quantity_to_make: data.Quantity_to_make
    });
    this.LocationOnchange();
    this.selectedEquipmentValue = data.Equipment;
    data.Location.forEach(element => {
      element.Checked_info.Name = '';
      element.Checked_info.By = '';
      element.Checked_info.Date = '';
      element.Is_verified = false;
    });



    this.BatchDetailForm.patchValue({
      Location: data.Location,
      Equipment: data.Equipment,

    });


    // this.onSubmit(this.BatchDetailForm);
  }

  showDialog_MFR(type) {
    this.display_MFRAddPopup = true;
  }

  hideMFRAdd() {
    this.display_MFRAddPopup = false;
  }

  qtyChange(event) {
    let mfrVal = this.BatchDetailForm.value.Master_formulation_record;
    mfrVal.Theoretical_yield.Units = event - mfrVal.Projected_loss.Units;
    if (mfrVal.Theoretical_yield.Units <= 0) {
      this.toastr.warning('Quantity to make(' + event + ') must be greater than Projected loss(' + mfrVal.Projected_loss.Units + ')');
    } else {
      this.BatchDetailForm.patchValue({
        Master_formulation_record: {
          Theoretical_yield: {
            Units: mfrVal.Theoretical_yield.Units
          }
        }
      });
    }
  }

  //  Method
  get mb() {
    return this.MasterBatchForm.controls;
  }
  // FinalProduct Change Method
  FinalProductOnchange(event) {
    const val = this.FinalProduct.result.find(x => x.id == event.value);
    this.selectedCars3 = [];
    this.MasterBatchForm.patchValue({
      quantity: val.Quantity_to_make,
      selectedQuantity: 0
    });


    this.dataService.getAll(this.configuration.getChildBatch + '/' + event.value + '/' + val.Quantity_to_make).subscribe(
      (data: any) => (this.ChildBatchs = data),
      error => () => {
        this.spinnerService.hide();
      },
      () => {
        if (this.ChildBatchs.result.length > 0) {
          this.masterData = this.ChildBatchs.result;
          this.display_child_Batch = true;
        } else {
          this.toastr.warning('No Records Found');
          this.display_child_Batch = false;
        }

      });

  }

  // Master Batch Create Method
  CreateMasterBatch_Add_Dialog() {
    this.dataService.getAll(this.configuration.getFinalProduct).subscribe(
      (data: any) => (this.FinalProduct = data),
      error => () => {
        this.spinnerService.hide();
      },
      () => {
        this.Product_SelectItem = this.bindDropDown(this.FinalProduct.result, 'finalProduct');
        this.display_MasterBatch = true;
      });

  }

  onRowSelect(event) {
    let data = event.data;
    let currentIndex = this.selectedCars3.findIndex(x => x.id == data.id)
    let MasterBatchDetail = this.MasterBatchForm.value;
    let MasterFormulation = this.selectedCars3;
    let quantity = 0;
    MasterFormulation.forEach(element => {
      quantity += element.Quantity;
      if (quantity > MasterBatchDetail.quantity) {
        this.toastr.warning('Selected Quantity Exceeds');
        this.masterSave = true;
        // MasterFormulation.splice(currentIndex, 1);
        // quantity = quantity - element.Quantity;
      }
      else {
        this.masterSave = false;
      }
    });
    //  this.selectedCars3 = MasterFormulation;
    this.MasterBatchForm.patchValue({
      selectedQuantity: quantity
    });
  }

  onRowUnselect(event) {
    let MasterBatchDetail = this.MasterBatchForm.value;
    let data = event.data;
    let quantity = this.MasterBatchForm.get('selectedQuantity').value;

    this.MasterBatchForm.patchValue({
      selectedQuantity: quantity - data.Quantity
    });
    quantity = this.MasterBatchForm.get('selectedQuantity').value;
    if (quantity > MasterBatchDetail.quantity) {
      this.masterSave = true;
    }
    else {
      this.masterSave = false;
    }

  }
  onMasterSubmit() {
    let formData = this.MasterBatchForm.getRawValue();
    let submitData = this.selectedCars3;
    if (submitData.length < 2) {
      this.toastr.warning('Minimum 2 Batch Required to Proceed');
    } else {

      let commentdetail = 'Merged Batch Child Details\n';
      submitData.forEach(element => {
        commentdetail += ' - Lot No (' + element.Lot_number + ') - Quantity (' + element.Quantity + ')\n';
      });
      // commentdetail = '<pre class="pretag">' + commentdetail + '</pre>';
      this.commentForm.patchValue({
        id: this.generateId.getId(),
        Comment_type: 'Comment',
        Comments: commentdetail,
        Comments_info: {
          ip_address: this.ipAddress,
          Name: this.userInfo[0].name,
          By: this.userInfo[0].id,
          Date: new Date()
        },
        BatchStep: ''
      });

      this.dataService.add<any>(this.configuration.getMasterMFR + formData.finalProduct, submitData).subscribe(
        (data: any) => (this.BatchOptionData = data),
        error => () => {
          this.spinnerService.hide();
          this.toastr.warning(this.toastMessage.Wrong);
        },
        () => {
          const date = new Date();
          const mfr = this.Product_SelectItem.find(x => x.value == formData.finalProduct);
          this.SelectedMFR = mfr;
          this.BatchDetailForm.patchValue({
            Batch_date: date,
            Lot_number: this.BatchOptionData.lotNumber,
            Master_formulation_record: {
              id: mfr.value,
              Name: mfr.label
            },
            Quantity: formData.selectedQuantity,
            Child_batches: submitData,
            Batch_comments: this.commentForm.value
          });


          this.batchPatchValue(this.BatchOptionData);
          this.expireDateUpdate(date, 'cda');
          this.FacilityDropdown(this.BatchOptionData.options.Location);
          this.display_MasterBatch = false;
          this.display_MasterRecordAddPopup = true
          this.batchOperatorArray = this.sortBatchOperator.sorting(this.BatchOptionData.options.Operator);
          this.spinnerService.hide();

          // this.router.navigate([this.customRoutingName.batch_detail + this.responseBody.id]);
          this.spinnerService.hide();
        }
      );

    }

  }

}





